import React, { FC, useState, useEffect } from 'react'
import VendorSearchBar from './SearchBar'
import Grid from '@mui/material/Grid'
import DataTable from '../common/DataTable'
import VendorService, {Vendor} from '../vendor/VendorService'
import PackageAssignmentService from '../package/PackageAssignmentService'
import useVendors from './useVendors'
import ReviewVendorHistoryModal from './ReviewVendorHistoryModal'
import AuditService from '../audit/AuditService'
import VendorPackageCopyModal from './VendorPackageCopyModal'
import { useSessionAuth } from '../auth/AuthContext'
import useAdminVendorReport from './useAdminVendorReport'
import ViewVendorPDFModal from './ViewVendorPDFModal'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Loading from '../common/Loading'
const columns = [
  { id: 'Name', label: 'Title', isSort: false, width:'100%' },
  { id: 'menu', label: '', isSort: false, width: '10%' }
]

const rowsPerPageOptions = [10, 20, 50]

export interface PackageProps
{
  vendorService: VendorService,
  auditService: AuditService
  packageAssignmentService: PackageAssignmentService
}

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255,0.7)',
  zIndex: 2
})

const Spinner = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <Loading size="3em" />
  </Box>
)

const VendorSection: FC<PackageProps> = ({ vendorService, auditService, packageAssignmentService }) =>
{
  const [searchVal, setSearchVal] = useState('')
  const [offset, setOffset] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
  const { vendors, hasNextPage, isLoadingVendors } = useVendors(vendorService, searchVal, offset, rowsPerPage )
  const { getVendorPackagesReportId, downloadVendorPackagesReport, isLoading } = useAdminVendorReport(vendorService)
  const [isReviewVendorHistoryModalOpen, setIsReviewVendorHistoryModalOpen] = useState(false)
  const [isVendorPackageCopyModalOpen, setIsVendorPackageCopyModalOpen] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState<Vendor | undefined>(undefined)
  const [vendorPackagesReportId, setVendorPackagesReportId] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);
  const { authSessionInfo } = useSessionAuth()
  const [preSignedURL, setPreSignedURL] = useState<string | null>(null)
  const [hasFetchedDownloadReport, setHasFetchedDownloadReport] = useState(false) 
  const [isViewVendorPDFModalOpen, setIsViewVendorPDFModalOpen] = useState(false)
  let intervalTime = 3000;
  const intervalTimeIncrementBy = 1000;
  const maxIntervalTime = 10000;
  const [reportLoaderMessage, setReportLoaderMessage] = useState('');
  useEffect(()=>{
    setIsAdmin(authSessionInfo?.userRole === 'Admin');
    setIsAuditor(authSessionInfo?.userRole === 'Auditor')
  },[authSessionInfo])

  const handleCopyPackages = (data:Vendor) => {
    setSelectedRowData(data);
    setIsVendorPackageCopyModalOpen(true);
  }
  const handleVendorRevisionHistory = (data:Vendor) => {
    setIsReviewVendorHistoryModalOpen(true);
    setSelectedRowData(data);
  }

  useEffect(()=>{
    let intervalId:ReturnType<typeof setInterval>;
    const downloadReport = async () => {
        const respObj = await downloadVendorPackagesReport(vendorPackagesReportId);
        if(respObj?.PresignedURL) {
          setPreSignedURL(respObj?.PresignedURL)
          setHasFetchedDownloadReport(true)
          clearInterval(intervalId)
          setIsViewVendorPDFModalOpen(true)
        }
        
    }
    if( vendorPackagesReportId && !hasFetchedDownloadReport) {
      intervalId = setInterval(()=>{
        if(intervalTime<maxIntervalTime) {
          intervalTime += intervalTimeIncrementBy;
        }
        if(intervalTime>=maxIntervalTime) {
          setReportLoaderMessage('Report generation is taking longer than expected, please remain patient...')
        }
        downloadReport();
      }, intervalTime);
    }
  return () => {
    clearInterval(intervalId);
  }    

  },[vendorPackagesReportId, hasFetchedDownloadReport])


  const handleDownloadReportClick = async (data:Vendor) => {
    const vendorId = data?.Id;
    setSelectedRowData(data);
    setPreSignedURL(null);
    setReportLoaderMessage('Report is generating, please wait...');
    setHasFetchedDownloadReport(false);
    if((isAdmin || isAuditor) && vendorId) {
        const resObj = await getVendorPackagesReportId(vendorId);
        setVendorPackagesReportId(resObj?.ReportId)
      }
  }

  let menuItems = [
    { label: 'Revision History', onClick: (data: Vendor) => handleVendorRevisionHistory(data) },
    { label: 'Assigned Documents Report', onClick: (data: Vendor) => handleDownloadReportClick(data) }
  ]

  if(authSessionInfo && authSessionInfo?.userRole?.toLocaleLowerCase()==='admin') {
    menuItems = [
      { label: 'Copy all package assignments to other vendor', onClick: (data: Vendor) => handleCopyPackages(data) },
      { label: 'Revision History', onClick: (data: Vendor) => handleVendorRevisionHistory(data) },
      { label: 'Assigned Documents Report', onClick: (data: Vendor) => handleDownloadReportClick(data) }  
    ]
  }

  const handleVendorRevisionHistoryClose = () => {
    setIsReviewVendorHistoryModalOpen(false);
  }

  const handleVendorPackageCopyModalClose = () => {
    setIsVendorPackageCopyModalOpen(false);
  }

  const handleViewVendorPDFModalClose = () => {
    setIsViewVendorPDFModalOpen(false);
  }

  return (
    <>
      <Grid item>
        <VendorSearchBar handleSearch={setSearchVal} />
        { isLoading && !preSignedURL && <Overlay>
          <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
          <div>{reportLoaderMessage}</div>
          <Spinner />
          </Box>
          
        </Overlay> }
        <DataTable
          data={vendors}
          columns={columns}
          rowsPerPageOptions={rowsPerPageOptions}
          hasNextPage={hasNextPage}
          offset={offset}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          setOffset={setOffset}
          isLoading={isLoadingVendors}
          menuItems={menuItems}
        />
      </Grid>
      {isReviewVendorHistoryModalOpen && selectedRowData && (
        <ReviewVendorHistoryModal
          auditService={auditService}
          vendorId={selectedRowData.Id}
          vendorName={selectedRowData.Name}
          isOpen={isReviewVendorHistoryModalOpen}
          onClose={handleVendorRevisionHistoryClose}
        />
      )}
      {isVendorPackageCopyModalOpen && selectedRowData && (
        <VendorPackageCopyModal
          vendorService={vendorService}
          vendorId={selectedRowData.Id}
          vendorName={selectedRowData.Name}
          open={isVendorPackageCopyModalOpen}
          onClose={handleVendorPackageCopyModalClose}
          packageAssignmentService={packageAssignmentService}
        />
      )}

      {isViewVendorPDFModalOpen && preSignedURL && (
        <ViewVendorPDFModal
          title={selectedRowData?.Name}
          isOpen={isViewVendorPDFModalOpen}
          onClose={handleViewVendorPDFModalClose}
          preSignedURL={preSignedURL}
        />
      )}
      
    </>
  )

}

export default VendorSection
