import logger from '../common/logger'
import { useCallback, useEffect, useState } from 'react'
import AuditService, { AuditEvent, AuditResponse } from './AuditService'
import useServiceAuth from '../common/useServiceAuth'
import isEmpty from 'lodash/isEmpty'
import useServiceMessaging from '../common/useServiceMessaging'

const useAudits = (
  auditService: AuditService,
  packageId?: string,
  vendorId?: string,
  offset?:number,
  limit: number = 20,
  previousPageId?: string
) =>
{
  const log = logger.child({ hook: 'useAudits' })
  const [audits, setAudits] = useState<AuditEvent[]>([])
  const [isLoadingAudits, setIsLoadingAudits] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [pageId, setPageId] = useState<string | undefined>()
  const { waitingOnAuth } = useServiceAuth<AuditResponse>(auditService)

  useServiceMessaging<AuditResponse>(auditService, 'Revision History Error')

  const handleError = useCallback(() => {
    setIsLoadingAudits(false)
  }, [setIsLoadingAudits])

  useEffect(() =>
  {
    auditService.addErrorHandler(handleError)
    return () => {
      auditService.removeErrorHandler(handleError)
    }
  }, [])

  useEffect(() =>
  {
    if (waitingOnAuth) return
    if (isEmpty(packageId) && isEmpty(vendorId)) return
    (async () =>
    {
      log.trace({ packageId, vendorId, limit, offset, previousPageId }, 'fetching audits')
      setIsLoadingAudits(true)
      const apiAudits = await auditService.list(limit, previousPageId, packageId, vendorId, offset)
      if (!apiAudits || !apiAudits.Events) {
        log.trace({ apiAudits }, 'no events found')
        return
      }
      setAudits(apiAudits.Events)
      setHasNextPage(apiAudits.HasNextPage)
      setPageId(apiAudits.PageId)
      setIsLoadingAudits(false)
    })()

  }, [packageId, vendorId, limit, previousPageId, offset])

  return { audits, isLoadingAudits, hasNextPage, pageId }
}

export default useAudits
