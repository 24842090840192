import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReactDOM from "react-dom";

const BootstrapDialog = styled(Dialog)<CustomizedDialogProps>(({ theme, customMaxWidth, maxWidth }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper" : {
    maxWidth:customMaxWidth?customMaxWidth:undefined,
    width:'100%',
    ...(maxWidth && !customMaxWidth?{maxWidth:theme.breakpoints.values[maxWidth]}:{})
  }
}));

interface CustomizedDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  maxWidth?:"xs"|"sm"|"md"|"lg"|"xl"|false;
  customMaxWidth?:string;
}

const CustomDialog: React.FC<CustomizedDialogProps> = ({
  open,
  onClose,
  title,
  content,
  actions,
  maxWidth,
  customMaxWidth
}) => {
  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      maxWidth={maxWidth} customMaxWidth={customMaxWidth}  >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={
        {
            backgroundColor: "var(--orion-color-background-surface-secondary, #024)",
            height: "50px",
            color: "var(--orion-color-text-inverted, #fff)",
            fontSize: "24px",
            padding: "8px 0 0 20px"}}>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>{content}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </BootstrapDialog>
  );
};

const dialogRoot = document.getElementById("dialog-root") || document.body;

// @TODO:  remove as Dialog from MUI handles this for you
const PortalDialog: React.FC<CustomizedDialogProps> = (props) => {
  return ReactDOM.createPortal(<CustomDialog {...props} />, dialogRoot);
};

export default PortalDialog;
