import { useCallback, useEffect, useState } from 'react'
import logger from '../common/logger'
import IFMService, { IFMResponse, TitleParts,Sections } from './IFMService'
import useServiceAuth from '../common/useServiceAuth'
import useMessaging from '../messaging/useMessaging'

const useIfm=(
    ifmService:IFMService,
)=>{
    const log = logger.child({ hook: 'useIfm' })
    const [titlepart, setTitlepart]=useState<TitleParts[]>([])
    const [section, setSection]=useState<Sections[]>([])
    const [isLodingData,setIsLoadingData]=useState(false)
    const { sendP1ErrorMessage } = useMessaging()
    const { waitingOnAuth } = useServiceAuth<IFMResponse[]>(ifmService)


    const handleError = useCallback(() =>
      {
        setIsLoadingData(false)
      }, [setIsLoadingData])
    
      useEffect(() =>
      {
        ifmService.addErrorHandler(handleError)
        return () =>
        {
          ifmService.removeErrorHandler(handleError)
        }
      }, [])
    const handleSearchIFM = useCallback(async(searchString:string)=>{
        if (waitingOnAuth) return
        log.trace({ searchString }, 'fetching IFMData')
        setIsLoadingData(true)
        const response=await ifmService.ifmDataList(searchString)
        if (!response) {
            sendP1ErrorMessage("Please enter a valid A/c number for results")
            return
          }
    log.trace({ response }, 'IFM Data fetched')
    setTitlepart(response.TitleParts)
    setSection(response.Sections)
    setIsLoadingData(false)
    return response
    },[waitingOnAuth,setIsLoadingData,ifmService])
    return{isLodingData,handleSearchIFM,titlepart,section}
}
export default useIfm