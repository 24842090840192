import { Logger } from "../common/logger";
import { ApiConfig } from '../config/config'
import HttpClient from '../common/HttpClient'
import Service from '../common/Service'



export interface TitleParts
{
    Label:string,
    Value:string
}
export interface Document
{
    Id:string,
    Text:string,
    Description:string
}
export interface BuildStandard
{
    Id:string,
    Text:string,
    Description:string
}
export interface Items
{
    BuildStandard:BuildStandard
    Name:string,
    Document:Document  
   
}
export interface Sections
{
    Name:string,
    Items:Items[]
}

export interface TRMResponse
{
    TitleParts:TitleParts[],
    Sections:Sections[],
    error_message?:string

}

export interface TRMFleet {
    ManualCode: string,
    FleetCode: string,
    FleetDisplayName: string
}

export interface ChapterDocument {
    Title: string,
    ECRA: string,
    DocumentId: string
}

export interface Chapter {
    Title:string,
    Documents:ChapterDocument[]
}

export interface FleetDetailsResp {
    Chapters:Chapter[]
}


export default class TRMService extends Service<TRMResponse>
{
    constructor(
        loger:Logger,
        httpClient: HttpClient,
        private readonly config: ApiConfig,
    )
    {
        super(loger,httpClient,'TRMService')
    }
    async getTRMFleetDetails(fleetCode:string){
        return   super.httpGet<FleetDetailsResp>(`${this.config.trmFleetURL}/${encodeURIComponent(fleetCode)}`)
    }
    async getTRMFleetList(){
        return   super.httpGet<TRMFleet[]>(`${this.config.trmFleetURL}`)
    }
}