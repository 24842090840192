import { useCallback, useEffect, useState } from 'react'
import SessionInfoService from './SessionInfoService'
import useServiceAuth from '../common/useServiceAuth'
import logger from '../common/logger'

const useSessionInfo = (sessionInfoService: SessionInfoService) =>
{
  const log = logger.child({ hook: 'usePackageAssignment' })
  const [isLoading, setIsLoading] = useState(false)
  const { waitingOnAuth } = useServiceAuth(sessionInfoService)

  const handleError = useCallback(() =>
  {
    setIsLoading(false)
  }, [setIsLoading])

  useEffect(() =>
  {
    sessionInfoService.addErrorHandler(handleError)
    return () =>
    {
        sessionInfoService.removeErrorHandler(handleError)
    }
  }, [])

  const sessionDetails = useCallback(async () =>
  {
    if (waitingOnAuth) throw new Error('Authentication not ready')
    setIsLoading(true)
    const sessionDetails =  await sessionInfoService.getSessionInfo()
    log.trace('fetched session details successful')
    setIsLoading(false)
    return sessionDetails
  }, [sessionInfoService, setIsLoading, waitingOnAuth])

  return { sessionDetails, isLoading }
}

export default useSessionInfo
