import {makeAutoObservable} from 'mobx'
import {PackageItemTypes} from './PackageService'
import CustomError from '../common/CustomError'

export class DocumentLinkChildError extends CustomError
{
  constructor()
  {
    super('DocumentLink Items cannot have child items', 'DocumentLinkChildError')
  }
}

export class SectionNestError extends CustomError
{
  constructor()
  {
    super('Items cannot be nested greater than 3 levels deep', 'SectionNestError')
  }
}

export class ItemNotFoundError extends CustomError
{
  constructor(itemId: string)
  {
    super(`No Item found with id ${itemId}`, 'ItemNotFoundError')
  }
}

export class PackageTreeItem
{
  constructor(
    readonly id: string,
    public title: string,
    readonly type: PackageItemTypes,
    readonly documentId?: string,
    public items?: PackageTreeItem[],
    public parentId?: string
  )
  {
    if (type === PackageItemTypes.documentLink && Array.isArray(items)) {
      throw new DocumentLinkChildError()
    }
    if (!parentId && Array.isArray(this.items)) {
      this.items = this.items.map(item =>
      {
        item.parentId = this.id
        return item
      })
    }
    makeAutoObservable(this)
  }

  [Symbol.hasInstance] = (instance: object) =>
  {
    if (!instance?.constructor?.name) return false
    return instance.constructor.name === 'PackageTreeItem'
  }

  addItem(item: PackageTreeItem)
  {
    if (this.isDocumentLink()) throw new DocumentLinkChildError()
    if (!this.items) this.items = []
    item.parentId = this.id
    this.items.push(item)
  }

  removeItem(item: PackageTreeItem)
  {
    if (this.isDocumentLink()) throw new DocumentLinkChildError()
    if (!this.items) throw new ItemNotFoundError(item.id)
    const index = this.items.findIndex(i => i.id === item.id)
    if (index === -1) throw new ItemNotFoundError(item.id)
    const items = [...this.items]
    items.splice(index, 1)
    this.items = items
  }

  isSection()
  {
    return this.type === PackageItemTypes.section
  }

  isDocumentLink()
  {
    return this.type === PackageItemTypes.documentLink
  }

  hasItems()
  {
    return Array.isArray(this.items) && this.items.length > 0
  }
}

export default class PackageTree
{
  constructor(
    public id: string,
    public title: string,
    public items: PackageTreeItem[],
    public revisionTimestamp?: string
  )
  {
    makeAutoObservable(this)
  }

  [Symbol.hasInstance] = (instance: object) =>
  {
    if (!instance?.constructor?.name) return false
    return instance.constructor.name === 'PackageTree'
  }

  getItem(id: string)
  {
    return this.walk(item => ({match: item.id === id}))
  }
  getItemByDocId(docId: string)
  {
    return this.walk(item => ({match: item.documentId === docId}))
  }

  getItemParent(id: string)
  {
    return this.walk(item =>
    {
      if (item.id === id && item.parentId) {
        const parent = this.getItem(item.parentId)
        return {
          match: true,
          value: parent
        }
      }
      return {match: false}
    })
  }

  getItemLevel(id: string)
  {
    let level = 1
    return this.walk<number>(item =>
    {
      return {
        match: item.id === id,
        value: level
      }
    }, () =>
    {
      level += 1
    })
  }

  addItem(item: PackageTreeItem)
  {
    item.parentId = this.id
    this.items.push(item)
    this.updateTS()
  }

  addItemInto(sectionId: string, item: PackageTreeItem)
  {
    if (sectionId === this.id) {
      this.addItem(item)
      return
    }
    const section = this.getItem(sectionId)
    if (!section) throw new ItemNotFoundError(sectionId)
    if (section.isDocumentLink()) throw new DocumentLinkChildError()
    const itemLevel = this.getItemLevel(sectionId)
    if (itemLevel && itemLevel > 3) throw new SectionNestError()
    section.addItem(item)
    this.updateTS()
  }

  removeItem(itemId: string)
  {
    const item = this.getItem(itemId)
    if (!item) throw new ItemNotFoundError(itemId)
    if (item.parentId === this.id) {
      this.items = this.items.filter(i => i.id !== itemId)
      this.updateTS()
      return
    }
    const parent = this.getItemParent(itemId)
    if (!parent) throw new ItemNotFoundError(item.parentId || '')
    parent.removeItem(item)
    this.updateTS()
  }

  private updateTS()
  {
    this.revisionTimestamp = new Date().toISOString()
  }

  private walk<ReturnResult = PackageTreeItem>(
    matchFn: (item: PackageTreeItem) => { match: boolean, value?: ReturnResult },
    onSection?: (item: PackageTreeItem) => void
  )
  {
    const stack = [...this.items]
    const visited = new Set()

    while (stack.length) {
      const item = stack.pop()
      if (!visited.has(item)) {
        const {match, value} = matchFn(item as PackageTreeItem)
        if (match) return value ?? item
        visited.add(item)
        if (item?.isSection() && item?.hasItems()) {
          if (typeof onSection === 'function') onSection(item)
          for (const i of (item.items as PackageTreeItem[])) {
            stack.push(i)
          }
        }
      }
    }
    return undefined
  }
}
