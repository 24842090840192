import React, { FC, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PortalDialog from '../common/CustomDialog'
import DataTable from '../common/DataTable'
import useAudits from '../audit/useAudits'
import AuditService from '../audit/AuditService'
import moment from 'moment'
export interface ReviewHistoryModalProps
{
  auditService: AuditService
  isOpen: boolean
  onClose: () => void
  pkgId: string
  pkgTitle: string
  pkgLastUpdated: string
}

const columns = [
  { id: 'EventType', label: 'Item changed', isSort: false, width: '74%' },
  { id: 'UserId', label: 'User', isSort: false, width: '8%' },
  { id: 'EventTimestamp', label: 'Date of event', isSort: false, width: '18%' }
]
const rowsPerPageOptions = [10, 20, 50];

const ReviewHistoryModal: FC<ReviewHistoryModalProps> = ({
  auditService,
  pkgId,
  pkgTitle,
  pkgLastUpdated,
  isOpen,
  onClose
}) =>
{

  const [offset, setOffset] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
  const [auditPageId, setAuditPageId] = useState<string | undefined>(undefined);

  const { audits, isLoadingAudits, hasNextPage, pageId } = useAudits(auditService, pkgId, '', offset, rowsPerPage, auditPageId)
  
  useEffect(()=>{
    if(auditPageId!==pageId) {
      setAuditPageId(pageId);
    }
  },[offset])

  const modifiedData = audits?.map(obj=>(
    {...obj,
     EventTimestamp:moment(obj?.EventTimestamp).format('YYYY-MM-DD HH:mm'),
     EventType:`${obj?.EventType}${obj?.VendorName? `, ${obj?.VendorName}`:''}`
    }
    )) 

    return (
    <PortalDialog
      open={isOpen}
      onClose={onClose}
      title="Revision history"
      content={
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <span><Typography component="span" sx={{ fontWeight: 'bold' }}>Package:</Typography> {pkgTitle}</span>
            <span><Typography component="span"
                              sx={{ fontWeight: 'bold' }}>Last Updated:</Typography> {pkgLastUpdated}</span>
          </Box>
          {
            audits ? <DataTable
              data={modifiedData}
              columns={columns}
            isLoading={isLoadingAudits}
            hasNextPage={hasNextPage}
            setOffset={setOffset}
            offset={offset}
            rowsPerPageOptions={rowsPerPageOptions}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            disablePrevBtn={true}
            /> : 'No events found for this package'
          }
        </>
      }
      maxWidth={false}
      customMaxWidth={'900px'}
    />
  )
}

export default ReviewHistoryModal
