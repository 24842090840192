import React, { FC, Suspense, useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { OrionButton, OrionMessage, OrionTextInput } from '@orion/orion-design-system-react'
import moment from 'moment-timezone'
import PackageService, { sectionFactory } from './PackageService'
import DocumentService from '../document/DocumentService'
import logger from '../common/logger'
import PackageStructure from './PackageStructure'
import PackageStructureDND from './PackageStructureDND'
import Loading from '../common/Loading'
import usePackage from './usePackage'
import DocumentSearch from '../document/DocumentSearch'
import { styled } from '@mui/material/styles'
import { fromPackage, fromPackageItem, fromPackageTree } from './PackageSerializers'
import PackageTree, { PackageTreeItem } from './PackageTree'
import VendorAssignmentModal from '../vendor/VendorAssignmentModal'
import VendorService from '../vendor/VendorService'
import PackageAssignmentService from './PackageAssignmentService'
import useMessaging from '../messaging/useMessaging'
import { useSessionAuth } from '../auth/AuthContext'

const Button = styled(OrionButton)(({ theme }) => ({
  paddingLeft: theme.spacing(1)
}))

export interface PackageProps
{
  documentService: DocumentService
  packageService: PackageService
  packageAssignmentService: PackageAssignmentService
  vendorService: VendorService
}

const PackageManager: FC<PackageProps> = ({
  documentService,
  packageService,
  packageAssignmentService,
  vendorService
}) =>
{
  const log = logger.child({ component: 'Package' })
  const { packageId } = useParams()
  const navigate = useNavigate()
  const { authSessionInfo } = useSessionAuth()


  useEffect(()=>{
    if(authSessionInfo && authSessionInfo?.userRole?.toLocaleLowerCase()!=='admin'){
      navigate('/unauthorized', {state:{userId: `${authSessionInfo?.userId}`}});
    }
  },[authSessionInfo?.userRole])

  const {
    pkg,
    isLoadingPkg,
    isSavingPkg,
    isSavingErrorPkg, 
    createOrUpdatePkg
  } = usePackage(packageService, packageId)
  const { sendSuccessMessage, sendP3ErrorMessage } = useMessaging()

  const [packageTree, setPackageTree] = useState<PackageTree>()

  useEffect(() =>
  {
    if (!isLoadingPkg && pkg && !isSavingErrorPkg) {
      setPackageTree(fromPackage(pkg))
    }
  }, [pkg])

  // Editing
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const toggleIsEditingTitle = useCallback(() =>
  {
    setIsEditingTitle(!isEditingTitle)
  }, [isEditingTitle])
  const handleUpdateTitle = useCallback(
    (event: CustomEvent<{ value: string }>) =>
    {
      if (packageTree) {
        packageTree.title = event.detail.value
      }
    },
    [packageTree]
  )

  const [isVendorAssignmentModalOpen, setIsVendorAssignmentModalOpen] = useState(false)
  const handleEditVendorAssignments = useCallback(() =>
  {
    log.trace('handleEditVendorAssignments()')
    setIsVendorAssignmentModalOpen(true)
  }, [])



  const validatePackagesForSave = (packageArr:PackageTreeItem[]):boolean => {
    if(!packageArr || packageArr.length===0) {
      return false;
    }
    const validateObject = (item:PackageTreeItem) => {
      if (item.isDocumentLink() && !item.title) {
        return false;
      }
      if (item.isSection()) {
        if (!item.title || (item?.items && item?.items?.length === 0)) {
          return false;
        }
        if (item.items) {
          for (const nestedItem of item.items) {
            if (!validateObject(nestedItem)) {
              return false;
            }
          }
        }
      }
      return true;
    };
  
    for (const item of packageArr) {
      if (!validateObject(item)) {
        return false;
      }
    }
    return true;
  };
  

  const handleUpdateOrSave = useCallback(() =>
  {
    if (!packageTree) return
    (async () =>
    {
      if(!validatePackagesForSave(packageTree?.items)) {
        sendP3ErrorMessage(
          'Part of the package is missing content. (Please check any root section/sections/subsections to ensure they are not empty)',
          'Error: Cannot save'
        )
        return
      }
      const updatedPkg = fromPackageTree(packageTree)

      log.trace({ pkg: updatedPkg, tree: packageTree }, 'handleUpdateOrSave()')
      let savedPkg
      try {
        savedPkg = await createOrUpdatePkg(updatedPkg)
      } catch {
        sendP3ErrorMessage(
          'Package may not be visible in UDocs, please check the package and retry.',
          'Error: edits failed to save'
        )
        return
      }
      if (savedPkg) {
        if(packageId === 'new') {
          sendSuccessMessage('Package is now visible to vendors in UDocs', 'Package created')
          navigate(`/admin/package/${savedPkg.Id}`)
        }
        else {
          sendSuccessMessage('Package is now visible to vendors in UDocs', 'Edits saved')
        }
      } 
    })()
  }, [createOrUpdatePkg, packageTree, packageId, navigate])

  const handleAddNewSection = useCallback(() =>
  {
    if (!packageTree) return
    const item = fromPackageItem(sectionFactory(), packageTree.id)
    packageTree.addItem(item)
  }, [packageTree])

  // noinspection TypeScriptValidateTypes
  return (
    <Suspense fallback={<Loading />}>
      <Box sx={{ ml: 2, mr: 3, my: 3 }}>
        <Grid container>
          <Grid item xs>
            {/* pseudo breadcrumb */}
            <Link to="/admin">Vendor Support Data Admin</Link> {'>'} Manage Package
          </Grid>
          {packageId === 'new' && (
            <Grid item xs>
              <OrionMessage variant="high" heading="New package" is-open>
              </OrionMessage>
            </Grid>
          )}
          <Grid item xs></Grid>
        </Grid>
        <Typography variant="h1">Manage Package</Typography>
      </Box>
      <Grid sx={{ bgcolor: '#e6e6e6', width: '100%', p: 2 }}>
        <Grid container justifyContent="space-between">
          <Grid item sm={4}>
            <Typography>Title</Typography>
            {isEditingTitle ? (
              <OrionTextInput
                id="document-title"
                type="text"
                value={packageTree?.title}
                onValueChanged={handleUpdateTitle}
              />
            ) : (
              <Typography variant="h2">{packageTree?.title}</Typography>
            )}
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item sx={{ pr: 1 }}>
                <Typography>Date modified</Typography>
                <Typography variant="h2">
                  {moment(packageTree?.revisionTimestamp).format('MM/DD/YYYY')}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Button icon-name="edit" variant="secondary" onClick={toggleIsEditingTitle}>
                      {isEditingTitle ? 'Update' : 'Edit'} title
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      icon-name="edit"
                      {...(packageId === 'new'?{disabled:true}:{})}
                      variant="secondary"
                      onClick={handleEditVendorAssignments}
                    >
                      Edit vendor assignments
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={handleUpdateOrSave} {...(isSavingPkg?{disabled:true}:{})}>Save</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {
        packageTree && (
          <PackageStructureDND packageTree={packageTree}>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs md={6}>
                <Typography variant="h2">Document search</Typography>
                <DocumentSearch documentService={documentService} />
              </Grid>
              <Grid item xs md={6}>
                <Grid container justifyContent="space-between">
                  <Typography variant="h2">Package structure</Typography>
                  <OrionButton variant="secondary" icon-name="plus" onClick={handleAddNewSection}>
                    Add Section
                  </OrionButton>
                </Grid>
                <PackageStructure packageTree={packageTree} />
              </Grid>
            </Grid>
          </PackageStructureDND>
        )
      }
      {
        typeof packageId === 'string' && packageId !== '' && packageId !== 'new' && (
          <VendorAssignmentModal
            open={isVendorAssignmentModalOpen}
            onClose={() => setIsVendorAssignmentModalOpen(false)}
            vendorService={vendorService}
            packageAssignmentService={packageAssignmentService}
            packageId={packageId}
          />
        )
      }
    </Suspense>
  )
}

export default PackageManager
