import { useCallback, useEffect } from 'react'
import Service from './Service'
import useMessaging from '../messaging/useMessaging'

/**
 * Hook to add a service error handler to emit P1 error messages when an error in the service occurs
 *
 * @param {Service} service
 * @param {string} heading
 */
export default function useServiceMessaging<DomainModel = unknown>(service: Service<DomainModel>, heading: string)
{
  const { sendP1ErrorMessage } = useMessaging()

/* eslint-disable @typescript-eslint/no-explicit-any */

  const handleError = useCallback((err:any) =>
  {
    const errmsg = err?.response?.data?.error_message || err?.response?.data?.Message || err?.response?.message || err?.response?.data?.message;
    const errorMessage =  `${err?.response?.status} - ${errmsg}` || 'A Server Error occurred' ;
    sendP1ErrorMessage(errorMessage, heading)
  }, [heading, sendP1ErrorMessage])

  useEffect(() =>
  {
    service.addErrorHandler(handleError)
    return () =>
    {
      service.removeErrorHandler(handleError)
    }
  }, [])
}
