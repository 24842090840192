import { Box, Container } from '@mui/system';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSessionAuth } from '../auth/AuthContext';

const UnAuthorized = () => {
    const email = 'technicaldatamanagement@united.com';
    const location = useLocation();
    const { authSessionInfo } = useSessionAuth()
    const displayText = location.state?.supplierName? `${location.state?.userId} - ${location.state?.supplierName}` : authSessionInfo?.userId;
    return (
        <Container>
            <Box>
                <p>Access denied for {`${displayText}`}! Please contact <a href={`mailto:${email}`}>{email}</a> for further assistance.</p>
            </Box>
        </Container>
    );
};

export default UnAuthorized;