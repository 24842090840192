import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

const DEFAULT_TTL = 5000

// @NOTE:  copied from Orion OrionMessageVariantOptions, which is not exported
// @TODO:  just use OrionMessageVariantOptions when/if it's eventually exported
export type MessagingVariants = 'success' | 'success-overlay' | 'high' | 'medium' | 'low' | 'info' | 'custom'

export interface MessagingState
{
  message?: string
  variant?: MessagingVariants
  heading?: string
  ttl?: number
}

export interface MessagingActions
{
  sendMessage: (
    message: MessagingState['message'],
    variant: MessagingState['variant'],
    heading?: MessagingState['heading'],
    ttl?: MessagingState['ttl']
  ) => void
  clearMessage: () => void
}

const useMessagingStore = create<MessagingState & MessagingActions>()(
  devtools(
    immer(set => ({
      message: undefined,
      variant: undefined,
      heading: undefined,
      ttl: DEFAULT_TTL,
      sendMessage: (message, variant, heading, ttl) =>
        set(state =>
        {
          state.message = message
          state.variant = variant
          state.heading = heading
          if (ttl) state.ttl = ttl
        }),
      clearMessage: () =>
        set(state =>
        {
          state.message = undefined
          state.variant = undefined
          state.heading = undefined
          state.ttl = DEFAULT_TTL
        })
    }))
  )
)

export default useMessagingStore
