import React, { FC,useEffect,MutableRefObject } from 'react'
import {
  OrionAccordion,
  OrionTable,
  OrionTableRow,
  OrionTableHeader,
  OrionTableCell,
} from '@orion/orion-design-system-react'
import Box from '@mui/material/Box'
import styles from "./IFM.module.scss"


export interface Documents
{
    Id:string,
    Text:string,
    Description:string
}
export interface BuildStandard
{
    Id:string,
    Text:string,
    Description:string
}
export interface Items
{
    BuildStandard:BuildStandard
    Name:string,
    Document:Documents 
   
}
export interface IFMPROPS{
  title?:string
  document?: Items[]|undefined
  selectedValue?:boolean
  key:number
  noseNumber:string
}

const IFMSection: FC<IFMPROPS> = props => {
   const scrollValue=React.useRef<HTMLInputElement>(null) as MutableRefObject<HTMLDivElement>
   useEffect(() => {
    if (props.selectedValue) {
      scrollValue.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.selectedValue]);

  const opendocumentHandler=(docID:string,docTitle:string)=>{  
    window.open(`/pdfViewer?documentId=${docID}&title=${docTitle}&component=IFM&noseNumber=${props?.noseNumber}&userRole=user`, '_blank', 'noopener noreferrer')
  }

  return (
    <>
      <Box my={1} mx={2} ref={scrollValue} key={props.key}>
        <OrionAccordion chevron-left open>
          <span slot='orion-accordion-header' style={{ color: props.selectedValue ? '#6244BB' : '#000' }}>
            {props.title}
          </span>
          <div slot='orion-accordion-panel'>
            <Box my={1} mx={2}>
              <OrionTable>
                <OrionTableRow>
                  <OrionTableHeader align='left'>{props.title}</OrionTableHeader>
                  <OrionTableHeader align='center'>Linked Document</OrionTableHeader>
                  <OrionTableHeader align='center'>Build Standards </OrionTableHeader>
                </OrionTableRow>
                {props.document&&props.document.map((docType:Items, index: number) => (
                  <OrionTableRow key={index}>
                    <OrionTableCell>{docType.Name}</OrionTableCell>
                    {docType.BuildStandard !== undefined ? (
                      <>
                        <OrionTableCell>
                            {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                            {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                        <div role="button" tabIndex={0}  onClick={() =>opendocumentHandler(docType.Document.Id,docType.Document.Text)}className={styles.sectionLinks}>{docType.Document.Text}</div>
                        </OrionTableCell>{' '}
                        <OrionTableCell>
                          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                        <div role="button" tabIndex={0} onClick={() =>opendocumentHandler(docType.BuildStandard.Id,docType.BuildStandard.Text)}className={styles.sectionLinks}>Build Standard: {docType.BuildStandard.Text}</div>  
                        </OrionTableCell>
                      </>
                    ) : (
                      <>
                        {' '}
                        <OrionTableCell>
                          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                        <div  role="button" tabIndex={0} onClick={() =>opendocumentHandler(docType.Document.Id,docType.Document.Text)}className={styles.sectionLinks}>{docType.Document.Text}</div>
                        </OrionTableCell>
                        <OrionTableCell></OrionTableCell>
                      </>
                    )}
                  </OrionTableRow>
                ))}
              </OrionTable>
            </Box>
          </div>
        </OrionAccordion>
      </Box>
    </>
  )
}
export default IFMSection
