import pino, { Logger as PinoLogger } from 'pino'

// noinspection TypeScriptUnresolvedVariable
const logger = pino({
  browser: {
    asObject: true,
    serialize: false,
    disabled: false
  },
  level: process.env.REACT_APP_LOG_LEVEL || 'info'
})

export type Logger = PinoLogger

export default logger as Logger
