import React, { FC, useCallback, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useDraggable, useDroppable } from '@dnd-kit/core'
import { styled } from '@mui/material/styles'
import { OrionButton, OrionLabel, OrionTextInput } from '@orion/orion-design-system-react'
import { observer } from 'mobx-react-lite'
import PackageTree, { PackageTreeItem } from './PackageTree'
import Menu from '../common/Menu'
import logger from '../common/logger'
import { sectionFactory } from './PackageService'
import { fromPackageItem } from './PackageSerializers'
import { SortableContext } from '@dnd-kit/sortable'
import ViewPDFModal from './ViewPDFModal'

export interface PackageStructureProps
{
  packageTree: PackageTree
}

export interface PDFIconProps
{
  documentId: string|undefined;
  title:string|undefined;
}
/* eslint-disable @typescript-eslint/no-explicit-any */

const ItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: '100%'
}))

const DragIcon = () => (
  <Box sx={{ cursor: 'grab' }}>
    <i className="orion-icon orion-icon-drag"></i>
  </Box>
)



const PDFIcon:FC<PDFIconProps> = ({documentId, title}) => {
  // match orion padding and size for buttons
  const [isViewPDFModalOpen, setIsViewPDFModalOpen ] = useState(false);
  const packageStructureViewPDF = () => {
    if(!isViewPDFModalOpen) {
      setIsViewPDFModalOpen(true);
    }
  }
  const handleClose = () => {
    setIsViewPDFModalOpen(false);
  }

  return <div> 
  <Box sx={{ display: 'flex', fontSize: '20px' }} style={{ padding: 'var(--orion-space-md, 8px)', cursor:'pointer' }} onClick={packageStructureViewPDF}>
    <i className="orion-icon orion-icon-download_pdf"></i>
  </Box>
  {
    isViewPDFModalOpen && <ViewPDFModal documentId={documentId} title={title} isOpen={isViewPDFModalOpen} onClose={handleClose}/>
  }
  </div>
}

const dropTargetSX = {
  borderBottom: '5px solid darkgrey',
  width: '100%'
}

const documentLinkItemSX = {
  backgroundColor: 'white',
  border: '1px solid lightgrey',
  py: 1,
  px: 2
}

const sectionItemSX = {
  p: 2
}

const PackageStructureItem: FC<{
  item: PackageTreeItem,
  tree: PackageTree,
}> = observer(({ item, tree }) =>
{
  const log = logger.child({ component: 'PackageStructureItem' })
  const { setNodeRef, isOver } = useDroppable({
    id: `package-structure-item-${item.type}-${item.id}`,
    data: item
  })
  const { attributes, listeners, setNodeRef: setDragNodeRef, transform } = useDraggable(({
    id: item.id,
    data: item
  }))

  const style = useMemo(() =>
      transform
        ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`
        }
        : undefined,
    [transform])

  const isLessThanLevel3 = useMemo(() => (tree.getItemLevel(item.id) || 0) < 3, [item, tree])

  const handleUpdateTitle = useCallback((event: CustomEvent<{ value: string }>) =>
  {
    log.trace({ title: event.detail.value, item }, 'updating item title')
    item.title = event.detail.value
  }, [])

  const handleAddNewSection = useCallback(() =>
  {
    log.trace({ item }, 'adding item section')
    if (!item.parentId) {
      log.warn({ item }, 'no parentId on Item cannot add')
      return
    }
    tree.addItemInto(item.parentId, fromPackageItem(sectionFactory(), item.parentId))
  }, [])

  const handleAddNewSubsection = useCallback(() =>
  {
    log.trace({ item }, 'adding item subsection')
    item.addItem(fromPackageItem(sectionFactory(), item.id))
  }, [])

  const handleRemoveItem = useCallback(() =>
  {
    log.trace({ item, tree }, 'removing item from tree')
    tree.removeItem(item.id)
  }, [item, tree])

  const menuItems = useMemo(() =>
  {
    const items = [
      { label: 'Add new section', onClick: handleAddNewSection }
    ]
    if ((tree.getItemLevel(item.id) || 0) < 2) {
      items.push({ label: 'Add new subsection', onClick: handleAddNewSubsection })
    }

    return items
  }, [handleAddNewSection, handleAddNewSubsection, tree, item])

  const itemContainerStyles = useMemo(() => ({
    ...(item.isSection() && sectionItemSX),
    ...(item.isDocumentLink() && documentLinkItemSX),
    ...(isOver && dropTargetSX)
  }), [item, isOver])

  return (
    <Box ref={setDragNodeRef} style={style} {...listeners} {...attributes}>
      <ItemContainer
        ref={setNodeRef}
        sx={itemContainerStyles}
      >
        {
          (item.isSection() && (
            <>
              <DragIcon />
              <Box sx={{ display: 'flex', pl: 2, flexGrow: 3 }}>
                <OrionLabel >Section title</OrionLabel>
                <Box sx={{ width: '100%' }}>
                  <OrionTextInput
                    id="section-title"
                    type="text"
                    placeholder="Enter section title"
                    value={item.title}
                    onValueChanged={handleUpdateTitle}
                  />
                </Box>
              </Box>
            </>
          ))
        }
        {
          item.isDocumentLink() && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DragIcon />
                <Typography sx={{ pl: 2 }}>{item.title}</Typography>
              </Box>
            </>
          )
        }
        <Box sx={{ display: 'flex' }}>
          <OrionButton variant="tertiary" icon-name="minus" onClick={handleRemoveItem} />
          {
            item.isSection() && isLessThanLevel3 && <Menu icon="plus" items={menuItems} />
          }
          {
            item.isDocumentLink() && <PDFIcon documentId={item?.documentId} title={item?.title} />
          }
        </Box>
      </ItemContainer>
      {item.isSection() && item.hasItems() && (
        <Box sx={{ width: '95%', ml: '5%' }}>
          {
            (item.items as PackageTreeItem[]).map(i => (
              <PackageStructureItem key={`package-structure-item-${i.id}`} item={i} tree={tree} />
            ))
          }
        </Box>
      )}
    </Box>
  )
})

const PackageStructure: FC<PackageStructureProps> = observer(({ packageTree }) =>
{
  const { setNodeRef } = useDroppable({
    id: 'package-structure'
  })
  return (
    <Box ref={setNodeRef} sx={{ background: '#f1f1f0', height: '100%', mt: 2 }}>
      {packageTree && Array.isArray(packageTree.items) && (
        <SortableContext items={packageTree.items.map(item => item.id)}>
          {packageTree.items.map(item => (
            <PackageStructureItem key={`package-structure-item-${item.id}`} item={item} tree={packageTree} />
          ))}
        </SortableContext>
      )}
    </Box>
  )
})
export default PackageStructure
