import {useCallback, useEffect, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';
import Service from './Service';
import useAuthStore from '../auth/useAuthStore';
import {AxiosError} from 'axios';
import useMessaging from '../messaging/useMessaging';
// import { tokenByRefreshToken } from '../auth/runAuthFlow';
import { useNavigate } from 'react-router-dom';
import { useError } from '../error/ErrorContext';
/**
 * Hook that adds authentication headers for services when the tokens are ready and set in the store.
 * NOTE:  this hook also clears tokens on any error.
 *
 * @param {Service} service
 */
export default function useServiceAuth<DomainModel = unknown>(service: Service<DomainModel>) {
  const tokens = useAuthStore(state => state.tokens);
  const clearTokens = useAuthStore(state => state.clear);
  const isAuthorizing = useAuthStore(state => state.isAuthorizing);
  const waitingOnAuth = useMemo(() => isEmpty(tokens) && isEmpty(tokens.accessToken), [tokens]);
  const { sendP1ErrorMessage } = useMessaging();
  const navigate = useNavigate();
  const {showError} = useError();

  const handleError = useCallback((err: unknown) => {
    const error = err as AxiosError;
    if ((error?.response?.status === 401 || error?.response?.status === 403) && !('x-udocs-authtoken-ok' in error.response.headers)) {
        useAuthStore.getState().clear();
        navigate("/sessionExpired");
    }
    else {
      const errorMsg =  (error?.response?.data as {error_message?:string})?.error_message || 'Something went wrong!';
      const errorData = error?.response?.data as {error_message?:string, is_critical:boolean};
      if(error?.response?.status === 400 && errorData?.is_critical && errorData?.error_message) {
        showError(errorData?.error_message)
      }
      else {
        sendP1ErrorMessage(errorMsg);
      }
    }  
  }, [clearTokens]);
  
  useEffect(() => {
    if (isAuthorizing || waitingOnAuth) return;
    service.setAuthentication(tokens.accessToken as string);
    service.addErrorHandler(handleError);
    return () => {
      service.removeErrorHandler(handleError);
    };
  }, [tokens, clearTokens, isAuthorizing, waitingOnAuth]);
  
  return {waitingOnAuth};
}
