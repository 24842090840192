import { useCallback, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import logger from '../common/logger'
import PackageService, { Package } from './PackageService'
import useServiceAuth from '../common/useServiceAuth'
import useServiceMessaging from '../common/useServiceMessaging'

export const INITIAL_PACKAGE = {
  Id: '0',
  Title: 'Untitled',
  Items: [],
  RevisionTimestamp: moment().format('MM/DD/YYYY')
}

const usePackage = (packageService: PackageService, packageId?: string) =>
{
  const log = logger.child({ hook: 'usePackage' })
  const [pkg, setPkg] = useState<Package>()
  const [isLoadingPkg, setIsLoadingPkg] = useState(false)
  const [isSavingPkg, setIsSavingPkg] = useState(false)
  const [isSavingErrorPkg, setIsSavingErrorPkg] = useState(false)
  const { waitingOnAuth } = useServiceAuth<Package>(packageService)

  useServiceMessaging<Package>(packageService, 'Package Error')

  const handleError = useCallback(() =>
  {
    setIsLoadingPkg(false)
    setIsSavingPkg(false)
  }, [setIsLoadingPkg, setIsSavingPkg])

  useEffect(() =>
  {
    packageService.addErrorHandler(handleError)
    return () =>
    {
      packageService.removeErrorHandler(handleError)
    }
  }, [])

  const resetPkg = useCallback(() =>
  {
    setPkg(INITIAL_PACKAGE)
  }, [setPkg])

  // handle fetching a package if packageId is provided
  useEffect(() =>
  {
    if (!isEmpty(pkg)) return
    if (waitingOnAuth) return
    if (isEmpty(packageId)) {
      log.trace('no packageId provided.')
      return
    }
    if (packageId === 'new') {
      log.trace('resetting package')
      resetPkg()
      return
    }
    if (isLoadingPkg) return
    (async () =>
    {
      setIsLoadingPkg(true)
      const apiPkg = await getPkg(packageId as string)
      setPkg(apiPkg)
      setIsLoadingPkg(false)
    })()
  }, [packageId, waitingOnAuth])

  const getPkg = useCallback(async (pkgId: string) =>
  {
    log.trace({ packageId }, 'fetching package')
    const apiPkg = await packageService.get(pkgId)
    log.trace({ package: apiPkg }, 'package fetched')
    return apiPkg
  }, [packageService])

  const createPkg = useCallback(async (title: string) =>
  {
    log.trace({ title: pkg?.Title }, 'creating package')
    setIsLoadingPkg(true)
    const apiPkg = await packageService.create({ Title: title })
    log.trace({ package: apiPkg }, 'package created')
    setPkg(apiPkg)
    setIsLoadingPkg(false)
    setIsSavingPkg(false)
    return apiPkg
    
  }, [pkg, setPkg, setIsSavingPkg, setIsLoadingPkg])

  const updatePkg = useCallback(async (updatedPkg: Package) =>
  {
    log.trace({ pkg, updatedPkg }, 'updating package')
    setIsSavingPkg(true)
    await packageService
      // explicit about what's saved on the API
      .update({
        Id: updatedPkg.Id,
        Title: updatedPkg.Title,
        // @TODO:  serialize
        Items: updatedPkg.Items
      })
    log.trace('package updated')
    setTimeout(()=>{setIsSavingPkg(false)}, 1000)
    return updatedPkg
  }, [pkg, setPkg, setIsSavingPkg, setIsLoadingPkg])

  const createOrUpdatePkg = useCallback(async (newPkg: Package) =>
  {
    setIsSavingPkg(true);
    setIsSavingErrorPkg(false);
    setPkg(newPkg)
    if (packageId === 'new') {
      const apiPkg = await createPkg(newPkg.Title)
      setTimeout(()=>{setIsSavingPkg(false)}, 1000)
      if (!apiPkg) {
        log.debug('No response from API. Cannot update.')
        setIsSavingErrorPkg(true);
        return
      }
      const updatedPkg = {
        // last in destructuring to ensure that it's properties are overwritten (sans Id)
        ...apiPkg,
        ...newPkg,
        // set to ensure that the pkg has the correct UUID
        Id: apiPkg.Id
      }
      console.log('\n\n\n\n ======================================= updatedPkg ====================================\n', updatedPkg, '\n\n\n\n\n');
      
      // make sure client-side navigation gets the updated package
      setPkg(updatedPkg)
      const updateExistingPackage =  await updatePkg(updatedPkg)
      if(!updateExistingPackage) {
        setIsSavingErrorPkg(true);
      }
      setTimeout(()=>{setIsSavingPkg(false)}, 1000)
      return updatedPkg
    }
    return updatePkg(newPkg)
  }, [createPkg, updatePkg, packageId, setIsLoadingPkg])

  const deletePkg = useCallback(async (packageId: string) => {
    if (isEmpty(packageId)) return
    await packageService.delete(packageId)
  }, [])

  return { pkg, isLoadingPkg, isSavingPkg, isSavingErrorPkg,  getPkg, createPkg, updatePkg, createOrUpdatePkg, deletePkg }
}

export default usePackage
