import randomstring from 'randomstring';
import {jwtDecode} from 'jwt-decode';
import mergeConfigWithEnv from './authConfig';
import {Logger} from '../common/logger';
import {AuthConfig} from '../config/config';
import Service from '../common/Service';
import HttpClient, {AuthType} from '../common/HttpClient';
import CustomError from '../common/CustomError';
import useAuthStore from './useAuthStore';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

export class AuthTokenError extends CustomError
{
  constructor(err: unknown)
  {
    super('Error getting token', 'AuthTokenError', err);
  }
}

export interface TokenResponse
{
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: number;
}

export interface PKCE
{
  verifier?: string;
  challenge?: string;
}

export interface Tokens
{
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  expiresAt?: number;
  expiresIn?:number;
}

export interface User
{
  id?: string;
  firstName?: string;
  lastName?: string;
}

interface JwtPayload
{
  firstname: string;
  lastname: string;
  UID: string;
}

export default class AuthService extends Service<unknown>
{
  redirectUrls: Set<string> = new Set<string>()
  
  private timeout?:number;
  autoRefresh:boolean;
  private refreshSlack:number;

  static readonly State = randomstring.generate(21);
  static readonly CodeVerifier = randomstring.generate(128);
  
  constructor(logger: Logger, httpClient: HttpClient, readonly config: AuthConfig, autoRefresh:boolean=true, refreshSlack:number = 90)
  {
    super(logger, httpClient, 'Auth');
    this.config = mergeConfigWithEnv(config);
    this.autoRefresh = autoRefresh;
    const storedRefreshSlack = window.localStorage.getItem('refresh_slack_time');
    this.refreshSlack = storedRefreshSlack?parseInt(storedRefreshSlack, 10):refreshSlack;
    if(this.isAuthenticated()) {
      this.startTimer();
    }
    this.setAuthentication(this.config.authorization, this.config.authorizationType as AuthType);
    this.client.instance.interceptors.request.use(requestConfig => {
      requestConfig.headers.set('X-OAUTH-IDENTITY-DOMAIN-NAME', this.config.domain);
      return requestConfig;
    });

  }
  
  static async Encode(value: string)
  {
    const hashArray = await window.crypto.subtle.digest(
      'SHA-256', new TextEncoder().encode(value)
    );
    const uIntArray = new Uint8Array(hashArray);
    const numberArray = Array.from(uIntArray);
    const hashString = String.fromCharCode.apply(null, numberArray);
    return btoa(hashString)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }
  
  static ValidateState(localState: string, remoteState: string)
  {
    return localState.localeCompare(remoteState, 'en', {sensitivity: 'variant'}) === 0;
  }
  
  getExpiresAt = (expiresIn: number) => moment().add(expiresIn, 'seconds').valueOf();


  async generatePKCE()
  {
    this.log.trace('generatePKCE()');
    const codeChallenge = await AuthService.Encode(AuthService.CodeVerifier);
    this.log.debug({codeChallenge}, 'generatePKCE');
    return {
      verifier: AuthService.CodeVerifier, challenge: codeChallenge
    } as PKCE;
  }
  
  async getAuthorizationCode(pkce: PKCE, state: string)
  {
    this.log.trace({pkceVariables: pkce, state}, 'getAuthorizationCode()');
    this.log.debug({config: this.config}, 'getAuthorizationCode()');
    if(window.location.pathname!=='/') {
      sessionStorage.setItem('exist_path', window.location.pathname);
    }
    const url = new URL(this.config.authorizeUrl, this.config.baseUrl);
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('client_id', this.config.clientId);
    url.searchParams.append('domain', this.config.domain);
    url.searchParams.append('redirect_uri', this.config.redirectUri);
    url.searchParams.append('code_challenge', pkce.challenge as string);
    url.searchParams.append('code_challenge_method', this.config.codeChallengeMethod);
    url.searchParams.append('state', state);
    url.searchParams.append('scope', this.config.scopes);
    this.log.debug({url}, 'getAuthorizationCode()');
    
    this.redirectUrls.add(window.location.toString())
    
    const newWindow = window.open(url, '_self', 'popup,noreferrer');
    if (newWindow) newWindow.opener = null;
  }
  
  async getTokens(pkce: PKCE, code: string)
  {
    this.log.trace({pkce, code}, 'getTokens()')
    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('redirect_uri', this.config.redirectUri);
    params.append('code', code);
    params.append('code_verifier', pkce.verifier as string);
    this.log.debug({params}, 'getTokens()')
    let response;
    try {
      response = await this.client.instance?.post<TokenResponse>(this.config.tokenUrl, params);
    } catch (err: unknown) {
      this.log.error({err}, 'error getting token');
      throw new AuthTokenError(err);
    }
    return response as unknown as TokenResponse;
  }
  
  async refreshTokens(pkce: PKCE, refreshToken: string)
  {
    this.log.trace({pkce, refreshToken}, 'refreshTokens()')
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', refreshToken);
    params.append('redirect_uri', this.config.redirectUri);
    params.append('code_verifier', pkce.verifier as string);
    this.log.debug({params}, 'refreshTokens()')
    let response;
    try {
      response = await this.client.instance?.post<TokenResponse>(this.config.tokenUrl, params);
      console.log("response:::", response);

    } catch (err: unknown) {
      this.log.error({err}, 'error refreshing token');

      throw new AuthTokenError(err);
    }
    return response as unknown as TokenResponse;
  }
  
  getUserDetails(): User
  {
    const idToken = useAuthStore.getState()?.tokens?.idToken;
    this.log.trace({idToken}, 'getUserDetails()')
    if(idToken) {
    const decoded = jwtDecode<JwtPayload>(idToken);
    this.log.debug({decoded}, 'getUserDetails')
    const {
      firstname: firstName,
      lastname: lastName,
      UID: id
    } = decoded;
    return {
      id,
      firstName,
      lastName
    };
  }
  return{}
  
  }

  logout(shouldEndSession = false): Promise<boolean> {
    try {
      if (shouldEndSession) {
        useAuthStore?.getState()?.clear();
        window.location.replace(this.config.logoutUri);
        return Promise.resolve(true);
      } else {
        window.location.reload();
        return Promise.resolve(true);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  startTimer(): void {
    const authTokens = useAuthStore?.getState()?.tokens;

    if (!authTokens || !authTokens.refreshToken || !authTokens.expiresAt) {
      return;
    }

    const now = new Date().getTime();
    const timeout = new Date(authTokens.expiresAt).getTime() - now - this.refreshSlack*1000;

    if (timeout > 0) {
      this.armRefreshTimer(authTokens.refreshToken, timeout);
    } else {
      useAuthStore?.getState()?.clear();
    }
  }

  armRefreshTimer(refreshToken: string, timeoutDuration: number): void {
    const currentState = useAuthStore.getState();
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    console.log(`will attempt the refresh token in ${(Math.floor(timeoutDuration/1000))} seconds`);

    this.timeout = window.setTimeout(() => {
      this.refreshTokens(currentState?.pkce, refreshToken).then((newTokens) => {
        this.setTokenInfo(newTokens, false);
        console.log('tokan has refreshed on', new Date());
        if (newTokens.expires_in) {
          const now = new Date().getTime();
          const timeout = this.getExpiresAt(newTokens.expires_in) - now - this.refreshSlack*1000;
          if (timeout > 0) {
            this.armRefreshTimer(newTokens.refresh_token, timeout);
          } else {
            currentState.clear();
          }
        }
      }).catch((e) => {
        console.warn('Error refreshing tokens', e);
        currentState.clear();
        window.location.href = '/sessionExpired';
      });
    }, timeoutDuration);
  }

  isPending():boolean {
    return !isEmpty(useAuthStore.getState().pkce) && isEmpty(useAuthStore.getState().tokens)
  }

  isAuthenticated():boolean {
    return !isEmpty(useAuthStore.getState().tokens)
  }

  setTokenInfo(tokenResp: TokenResponse, updateAll: boolean = true): void {
    const { access_token, refresh_token, expires_in } = tokenResp;
    
    // Build the tokens object with the fields that are always present
    const tokens:Partial<Tokens> = {
      accessToken: access_token,
      refreshToken: refresh_token,
      expiresAt: this.getExpiresAt(expires_in),
      expiresIn:expires_in
    };
  
    if (updateAll && tokenResp.id_token) {
      // Include idToken only if it is present in the response and updateAll is true
      tokens.idToken = tokenResp.id_token;
    }
  
    // Update the tokens in the store
    useAuthStore.getState().setTokens(tokens, updateAll);
  }

  removeCodeFromLocation(): void {
    const [base, search] = window.location.href.split('?');
  
    if (!search) {
      return;
    }
  
    const newSearch = search
      .split('&')
      .map((param: string) => param.split('='))
      .filter(([key]: string[]) => key !== 'code' && key!=='state')
      .map((keyAndVal: string[]) => keyAndVal.join('='))
      .join('&');
  
    window.history.replaceState(
      window.history.state,
      'null',
      base + (newSearch.length ? "?" + newSearch : '')
    );
  }

  restoreUri(): void {
    const uri = window.localStorage.getItem('preAuthUri');
    window.localStorage.removeItem('preAuthUri');
  
    if (uri !== null) {
      window.location.replace(uri);
    }
    this.removeCodeFromLocation();
  }

}
