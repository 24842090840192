import React, { FC } from 'react'
import Box, {BoxProps} from '@mui/material/Box'

export interface OrionIconProps extends BoxProps
{
  icon: string
}

const OrionIcon: FC<OrionIconProps> = ({ icon, ...props }) => (
  <Box component='span' {...props}>
    <i className={`orion-icon orion-icon-${icon}`}></i>
  </Box>
)

export default OrionIcon
