import React, {FC} from 'react'
import PDFViewer from '../common/PDFViewer'
import Modal from '../common/CustomModal'

interface ViewPDFDialogProps
{
  isOpen: boolean;
  onClose: () => void;
  documentId:string|undefined;
  title:string|undefined;
  userRole?:string;
  packageId?:string;
  isTRMDocument?:boolean;
  fleetcode?:string
}

const ViewPDFModal: FC<ViewPDFDialogProps> = ({documentId, title, packageId,  isOpen, onClose, userRole, isTRMDocument, fleetcode }) =>
{

  const handleOpenPDF = () => {
    if(userRole?.toLowerCase() === 'admin'||location?.pathname?.toLowerCase()?.includes('admin')) {
      window.open(`/pdfViewer?documentId=${documentId}&title=${title}&userRole=${userRole?userRole:'admin'}`, '_blank', 'noopener noreferrer')
    }
    else if(packageId) {
      window.open(`/pdfViewer?documentId=${documentId}&title=${title}&packageId=${packageId}`, '_blank', 'noopener noreferrer')
    }
    else if(isTRMDocument) {
      window.open(`/pdfViewer?documentId=${documentId}&title=${title}&component=trm&fleetcode=${fleetcode}`, '_blank', 'noopener noreferrer')
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      name={"pdf-viewer"}
      title={title||''}
      isOpenPDFLink={true}
      openPDFinOtherTab={handleOpenPDF}

      >
          <PDFViewer documentId={documentId} title={title} onClose={onClose} userRole={userRole?userRole:'admin'} packageId={packageId} isTRMDocument={isTRMDocument} fleetcode={fleetcode}/>
      </Modal>
  )
}

export default ViewPDFModal
