import React, { FC, useState, useCallback, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { OrionButton, OrionLabel, OrionTextInput } from '@orion/orion-design-system-react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import useTrm from './useTrm'
import TRMService, { TRMFleet, FleetDetailsResp } from './TRMService'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { TRMFleetDetails } from './TRMFleetDetails'
import { styled } from '@mui/material'
import { CircularProgress } from '@mui/material'
import useMessaging from '../messaging/useMessaging'
import UnAuthorized from '../common/UnAuthorized'

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255,0.7)',
  zIndex: 1
})

export interface TRMProps {
  trmService: TRMService
}

const TRM: FC<TRMProps> = ({ trmService }) => {
  const { getTRMFleets, isFleetLoading, getTRMFleetDetails } = useTrm(trmService)
  const [fleetList, setFleetList] = useState<TRMFleet[] | undefined>()
  const [selectedFleet, setSelectedFleet] = useState<TRMFleet | null>(null)
  const [fleetSearchTerms, setFleetSearchTerms] = useState<string>('')
  const [selectedFleetDetailsData, setSelectedFleetDetailsData] = useState<FleetDetailsResp>()
  const [searchChapterTerms, setSearchChapterTerms] = useState('')
  const { fleetcode } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { sendP1ErrorMessage } = useMessaging()

  useEffect(() => {
    if (fleetcode) {
      // setFleetcode(fleetcode);
    }
  }, [fleetcode])

  useEffect(() => {
    (async () => {
      const trmFleets = await getTRMFleets()
      setFleetList(trmFleets)
      const reqFleet = trmFleets?.find(obj => obj.FleetCode?.toLowerCase() === fleetcode?.toLowerCase())
      if (fleetcode && reqFleet) {
        setSelectedFleet(reqFleet)
      } else if (location?.pathname.includes('fleetcode')) {
        sendP1ErrorMessage('Please pass a valid fleetcode param in the URL')
      }
    })()
  }, [])

  useEffect(() => {
    if (selectedFleet) {
      handleFleetSearch()
    }
  }, [selectedFleet])

  const handleFleetSearch = useCallback(async () => {
    if (selectedFleet && selectedFleet?.FleetCode) {
      const trmFleetDetails = await getTRMFleetDetails(selectedFleet?.FleetCode)
      setSelectedFleetDetailsData(trmFleetDetails)
      if (fleetcode && fleetcode.toLowerCase() !== selectedFleet?.FleetCode?.toLowerCase()) {
        navigate(`/trm/fleetcode/${encodeURIComponent(selectedFleet?.FleetCode)}`)
      }
    }
  }, [selectedFleet])

  return (
    <>
      <Box sx={{ m: 2, height: '80vh' }}>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {isFleetLoading && (
            <Overlay>
              <CircularProgress />
            </Overlay>
          )}
          {fleetList?.length === 0 ? (
            <UnAuthorized/>
          ) : (
            <>
              <Grid sx={{display:'flex'}}>
                <Grid item>
                  {selectedFleetDetailsData && (
                    <Box sx={{ display: 'flex' }}>
                      <Grid item>
                        <OrionLabel>Document Search</OrionLabel>
                      </Grid>
                      <Grid item>
                        <OrionTextInput
                          id='chapter-document-search'
                          type='text'
                          placeholder='Enter Keywords'
                          value={searchChapterTerms}
                          onValueChanged={event => {
                            setSearchChapterTerms(event.detail.value)
                          }}
                        />
                      </Grid>
                    </Box>
                  )}
                </Grid>
                {searchChapterTerms !== '' ? (
                  <Grid item>
                    <OrionButton variant='tertiary' onClick={() => setSearchChapterTerms('')}>
                      Clear
                    </OrionButton>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                <Autocomplete
                  disablePortal
                  id='vendor-copy-autocomplete'
                  renderInput={params => <TextField {...params} label='Fleet' size={'small'} />}
                  options={fleetList || []}
                  getOptionLabel={option => option.FleetDisplayName}
                  loading={isFleetLoading}
                  isOptionEqualToValue={(option, value) => option.FleetCode === value.FleetCode}
                  value={selectedFleet}
                  inputValue={fleetSearchTerms}
                  onChange={(_event, trmFleet: TRMFleet | null) => {
                    setSelectedFleet(trmFleet)
                  }}
                  onInputChange={(_event: unknown, value: string) => setFleetSearchTerms(value)}
                  noOptionsText='No Vendors'
                  fullWidth
                  sx={{ mr: 1, width: 500 }}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          {selectedFleetDetailsData && (
            <TRMFleetDetails
              data={selectedFleetDetailsData}
              searchTerm={searchChapterTerms}
              selectedFleet={selectedFleet}
            />
          )}
        </Grid>
      </Box>
    </>
  )
}
export default TRM
