import { useCallback, useEffect, useState } from 'react'
import logger from '../common/logger'
import TRMService, { TRMResponse } from './TRMService'
import useServiceAuth from '../common/useServiceAuth'
import useMessaging from '../messaging/useMessaging'

const useTrm=( 
    trmService:TRMService,
)=>{
    const log = logger.child({ hook: 'useTrm' })
    const [isFleetLoading,setIsFleetLoading]=useState(false)
    const { sendP1ErrorMessage } = useMessaging()
    const { waitingOnAuth } = useServiceAuth<TRMResponse[]>(trmService)


    const handleError = useCallback(() =>
      {
        setIsFleetLoading(false)
      }, [setIsFleetLoading])
    
      useEffect(() =>
      {
        trmService.addErrorHandler(handleError)
        return () =>
        {
          trmService.removeErrorHandler(handleError)
        }
      }, [])

    const getTRMFleets = async () => {
        if (waitingOnAuth) return
        setIsFleetLoading(true)
        const response=await trmService.getTRMFleetList()
        if (!response) {
            sendP1ErrorMessage("Fleets not found for this User")
            return
          }
        log.trace({ response }, 'TRM Data fetched')
        setIsFleetLoading(false)
        return response
    }  

    const getTRMFleetDetails = async (fleetCode:string) => {
      if (waitingOnAuth) return
      setIsFleetLoading(true)
      const response=await trmService.getTRMFleetDetails(fleetCode)
      if (!response) {
          sendP1ErrorMessage("Fleets not found for this User")
          return
        }
      log.trace({ response }, 'TRM Data fetched')
      setIsFleetLoading(false)
      return response
  }  

    return{ getTRMFleets, isFleetLoading, getTRMFleetDetails}
}
export default useTrm