import { Logger } from './logger'
import HttpClient, { AuthType, GetParams, RequestConfig } from './HttpClient'

type ErrorHandler = (err: unknown) => void

export default abstract class Service<DomainModel>
{
  protected readonly log: Logger
  private readonly errorHandlers: Set<ErrorHandler> = new Set<ErrorHandler>()

  protected constructor(
    logger: Logger,
    protected readonly client: HttpClient,
    readonly serviceName: string
  )
  {
    this.log = logger.child({ service: serviceName })
  }

  setAuthentication(token: string, type?: AuthType)
  {
    this.client.addAuth(token, type)
  }

  addErrorHandler(errorHandler: ErrorHandler)
  {
    if (this.errorHandlers.has(errorHandler)) return
    this.errorHandlers.add(errorHandler)
  }

  removeErrorHandler(errorHandler: ErrorHandler)
  {
    if (!this.errorHandlers.has(errorHandler)) return
    this.errorHandlers.delete(errorHandler)
  }

  protected async httpGet<ResponseModel = DomainModel>(url = '', params?: GetParams, config: RequestConfig = {})
  {
    this.log.trace({ url, params }, 'get')
    let response
    try {
      response = await this.client.get<ResponseModel>(url, {
        ...config,
        params
      })
    } catch (err: unknown) {
      this.handleError(err)
    }
    this.log.trace({ response }, 'get response')
    return response
  }

  protected async httpPut<RequestData, ResponseModel = DomainModel>(url = '', data: RequestData, config?: RequestConfig)
  {
    this.log.trace({ url, data }, 'put')
    let response
    try {
      response = await this.client.put<ResponseModel, RequestData>(url, data, config)
    } catch (err: unknown) {
      this.handleError(err)
    }
    this.log.trace({ response }, 'put response')
    return response
  }

  protected async httpPost<RequestData, ResponseModel = DomainModel>(
    url = '',
    data: RequestData,
    config?: RequestConfig
  )
  {
    this.log.trace({ url, data }, 'post')
    let response
    try {
      response = await this.client.post<ResponseModel, RequestData>(url, data, config)
    } catch (err: unknown) {
      this.handleError(err)
    }
    this.log.trace({ response }, 'post response')
    return response
  }

  protected async httpDelete<ResponseModel = DomainModel>(url: string, config?: RequestConfig)
  {
    this.log.trace({ url }, 'delete')
    try {
      await this.client.delete<ResponseModel>(url, config)
    } catch (err: unknown) {
      this.handleError(err)
    }
  }

  private handleError(err: unknown)
  {
    this.errorHandlers.forEach(handler =>
    {
      handler(err)
    })
  }
}
