import React from "react";
import CustomModal from "./CustomModal";
import { useError } from "../error/ErrorContext";

const GlobalErrorModal = () => {
  const { errorMessage, clearError } = useError();

  return (
    <CustomModal
      open={!!errorMessage}
      onClose={clearError}
      name="global-error-modal"
      title="Error"
      showOkButton={true}
      customHtml={errorMessage}
      customWidth={'800px'}
      customHeaderHeight={'50px'}
    >
        <></>
    </CustomModal>
  );
};

export default GlobalErrorModal;
