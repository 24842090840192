import moment from 'moment-timezone'
import { formatDate } from '../common/date'
import { PackageListItem } from './PackageService'
import config from '../config/config.json';
export const sortByLastModifiedDate = (data: PackageListItem[]) =>
{
  if (!Array.isArray(data) || data.length === 0) return []
  return data.sort((a, b) =>
  {
    const dateA = moment(a?.ModifiedTimestamp)
    const dateB = moment(b?.ModifiedTimestamp)
    if (dateA.isBefore(dateB)) {
      return 1
    } else if (dateA.isAfter(dateB)) {
      return -1
    } else {
      return 0
    }
  })
}

export const formatDates = (pkgs: PackageListItem[]) => pkgs.map(pkg => ({
  ...pkg,
  ModifiedTimestamp: formatDate(pkg.ModifiedTimestamp)
}))

export function getEnv () {
  const isLocal = window.location.hostname.match(/localhost/);
  const reqEnv = (window.location.hostname.match(/dev|qa|stg/)?.[0]);
  if(reqEnv) {
    return config[reqEnv as keyof typeof config];
  }
  if(isLocal) {
    return config['local'];
  }
  else {
    return config['prd'];
  }
  
}

export function getEnvName () {
  const isLocal = window.location.hostname.match(/localhost/);
  const reqEnv = (window.location.hostname.match(/dev|qa|stg/)?.[0]);
  if(reqEnv) {
    return reqEnv;
  }
  if(isLocal) {
    return 'local';
  }
  else {
    return 'prd';
  }
}

export const isProd = () => getEnvName() === 'prd';

export const isStage = () => getEnvName() === 'stg';

export const isQA = () => getEnvName() === 'qa';

export const isDev = () => getEnvName() === 'dev';

export const isLocal = () => getEnvName() === 'localhost';
