import React, { ReactElement, FC } from 'react';
import styles from './slideout.module.scss';

export interface SlideoutProps {
    children:ReactElement | ReactElement[];
}

const Slideout:FC<SlideoutProps> = ({children}) => {
    return <div className={styles.container}>
            <div className={styles.body}>
                {children}
            </div>
        </div>
}

export default Slideout;