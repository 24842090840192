import { Logger } from '../common/logger'
import { ApiConfig } from '../config/config'
import Service from '../common/Service'
import HttpClient from '../common/HttpClient'

export default class IFMAdminService extends Service<unknown>
{
    constructor(
        logger: Logger,
        httpClient: HttpClient,
        private readonly config: ApiConfig
      )
      {
        super(logger, httpClient, 'IFMAdminService')
      }
      async uploadFile(fileData: string,)
      {
        return super.httpPost(this.config.ifmAdminUpload,fileData,{
            headers: {
                'content-type': 'multipart/form-data',
              },
        } )
      }
}