import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import {Logger} from './logger'
import { getEnv } from '../package/utils';
export type AuthType = 'Bearer' | 'Basic'
export type RequestConfig = AxiosRequestConfig
export type GetParams = RequestConfig['params']

export default class HttpClient
{
  instance: AxiosInstance
  protected log: Logger
  private authInterceptor?: number


  constructor(logger: Logger, baseURL: string)
  {
    this.log = logger.child({service: 'HttpClient'})
    this.instance = axios.create({baseURL})
    this.instance.interceptors.response.use(this.handleResponse, this.handleError)
  }

  addAuth(authToken: string, authType: AuthType = 'Bearer')
  {
    this.authInterceptor = this.instance.interceptors.request.use(config =>
    {
      const currentEnv = getEnv();
      if(config.url === currentEnv?.auth?.tokenUrl) {
        config.headers.setAuthorization(`${authType} ${authToken}`);
      }
      else {
        config.headers['authorizationToken'] = authToken;
        const testUserId = localStorage?.getItem("test-userid")
        if(testUserId) {
            config.headers.set(`X-UDocs-Impersonate-UserId:${testUserId}`)
        }
      }
      return config
    })
  }

  removeAuth()
  {
    if (!this.authInterceptor) return
    this.instance.interceptors.request.eject(this.authInterceptor)
  }

  get<ResponseType>(url: string, config?: AxiosRequestConfig)
  {
    return this.instance.get<ResponseType, ResponseType>(url, config)
  }

  put<ResponseType, RequestData>(url: string, data: RequestData, config?: AxiosRequestConfig<RequestData>)
  {
    return this.instance.put<ResponseType, ResponseType, RequestData>(url,
      data,
      config)
  }

  post<ResponseType, RequestData>(url: string, data: RequestData, config?: AxiosRequestConfig<RequestData>)
  {
    return this.instance.post<ResponseType, ResponseType, RequestData>(url,
      data,
      config)
  }

  delete<ResponseType>(url: string, config?: AxiosRequestConfig)
  {
    return this.instance.delete<ResponseType>(url, config)
  }

  private handleResponse = ({data}: AxiosResponse) =>
  {
    this.log.trace({data}, 'response received')
    return data
  }

  private handleError = (err: unknown) =>
  {
    this.log.error({err})
    return Promise.reject(err)
  }
}
