import isEmpty from 'lodash/isEmpty';
import logger from '../common/logger';
import AuthService, { PKCE, TokenResponse, Tokens } from './AuthService';
import useAuthStore from './useAuthStore';
const log = logger.child({ hook: 'runAuthFlow()' });

interface AuthFlowResult {
    isAuthorizing: boolean;
}

export const runAuthFlow = async (authService: AuthService, searchParams: URLSearchParams, setSearchParams:(params:string)=>void): Promise<AuthFlowResult> => {
    const state = useAuthStore.getState();
    let pkce: PKCE  = state.pkce;
    let stateValue: string = state.stateValue;
    const tokens: Tokens = state.tokens;

    // Handle missing PKCE or state value
    if (isEmpty(pkce) || isEmpty(stateValue)) {
        log.info('Generating new PKCE and state value');
        pkce = await authService.generatePKCE();
        stateValue = AuthService.State;
        useAuthStore.getState().setPKCE(pkce);
        useAuthStore.getState().setStateValue(stateValue);
        window.localStorage.setItem('preAuthUri', window.location.href);
    }

    // Check if tokens are available
    if (isEmpty(tokens)) {
        log.info('No tokens found, attempting to retrieve authorization code and tokens');
        

        const code = searchParams.get('code') as string;
        const stateParam = searchParams.get('state') as string;

        if (!code || !stateParam) {
            // Redirect to authorization flow if code or state is missing
            useAuthStore.getState().setIsAuthorizing(true);
            await authService.getAuthorizationCode(pkce, stateValue);
            return { isAuthorizing: true };
        }

        if (!AuthService.ValidateState(stateValue, stateParam)) {
            log.error('Invalid state parameter');
            useAuthStore.getState().setIsAuthorizing(true);
            return { isAuthorizing: true };
        }

        // Retrieve tokens using the authorization code
        const tokenResp = await authService.getTokens(pkce, code) as unknown as TokenResponse;
        authService.restoreUri();
        authService.setTokenInfo(tokenResp);
        if(authService.autoRefresh) {
            authService.startTimer();
          }
        setSearchParams('');
        log.info('Tokens retrieved successfully');
    }

    useAuthStore.getState().setIsAuthorizing(false);
    useAuthStore.getState().setIsAwaitingAuthResponse(false);
    return { isAuthorizing: false };
};
