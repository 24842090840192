export default class CustomError extends Error
{
  [Symbol.toStringTag] = ''
  timestamp = new Date().toISOString()
  metadata: unknown

  constructor(message: string, errorName: string, metadata?: unknown)
  {
    super(message)
    this[Symbol.toStringTag] = errorName
    Error.captureStackTrace(this, this.constructor)
    if (metadata) this.metadata = metadata
  }
}
