import React, {FC} from 'react'
import PortalDialog from '../common/CustomDialog'
import Typography from '@mui/material/Typography'
import {OrionButton} from '@orion/orion-design-system-react'
import {styled} from '@mui/material/styles'

const Button = styled(OrionButton)(({theme}) => ({
  margin: theme.spacing(1)
}))

interface ConfirmDialogProps
{
  onOk: () => void
  isOpen: boolean
  onClose: () => void
}

const ConfirmDeleteModal: FC<ConfirmDialogProps> = ({isOpen, onOk, onClose}) =>
{
  const dialogActions = (
    <>
      <Button onClick={onClose}>
        No
      </Button>
      <Button variant="primary" onClick={onOk}>
        Yes
      </Button>
    </>
  )

  return (
    <PortalDialog
      open={isOpen}
      onClose={onClose}
      title="Are you sure?"
      content={
        <Typography gutterBottom>
          Are you sure you want to delete this package?
        </Typography>
      }
      actions={dialogActions}
      maxWidth='sm'
    />
  )
}

export default ConfirmDeleteModal
