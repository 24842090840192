import { useCallback, useEffect, useState } from 'react'
import PackageAssignmentService from './PackageAssignmentService'
import useServiceAuth from '../common/useServiceAuth'
import logger from '../common/logger'
import useServiceMessaging from '../common/useServiceMessaging'
import { isEmpty } from 'lodash'

const usePackageAssignment = (packageAssignmentService: PackageAssignmentService) => {
  const log = logger.child({ hook: 'usePackageAssignment' })
  const [isLoading, setIsLoading] = useState(false)
  const { waitingOnAuth } = useServiceAuth(packageAssignmentService)

  useServiceMessaging(packageAssignmentService, 'Package Assignment Error')

  const handleError = useCallback(() => {
    setIsLoading(false)
  }, [setIsLoading])

  useEffect(() => {
    packageAssignmentService.addErrorHandler(handleError)
    return () => {
      packageAssignmentService.removeErrorHandler(handleError)
    }
  }, [])

  const assign = useCallback(
    async (packageId: string, vendorId: string) => {
      if (waitingOnAuth) throw new Error('Authentication not ready')
      log.trace({ packageId, vendorId }, 'assign package and vendor')
      setIsLoading(true)
      await packageAssignmentService.addAssignment(packageId, vendorId)
      log.trace({ packageId, vendorId }, 'package and vendor assignment successful')
      setIsLoading(false)
    },
    [packageAssignmentService, setIsLoading, waitingOnAuth]
  )

  const unAssign = useCallback(
    async (packageId: string, vendorId: string) => {
      if (waitingOnAuth) throw new Error('Authentication not ready')
      log.trace({ packageId, vendorId }, 'un-assign')
      setIsLoading(true)
      await packageAssignmentService.removeAssignment(packageId, vendorId)
      log.trace({ packageId, vendorId }, 'package and vendor unassignment successful')
      setIsLoading(false)
    },
    [packageAssignmentService, waitingOnAuth, setIsLoading]
  )

  const vendorCopyPackages = useCallback(async (sourceVendorId: string, destVendorId: string) => {
    if (waitingOnAuth) throw new Error('Authentication not ready')
    log.trace({ sourceVendorId, destVendorId }, 'assign package and vendor')
    setIsLoading(true)
    if (isEmpty(sourceVendorId) || isEmpty(destVendorId)) return
    await packageAssignmentService.copyPackages(sourceVendorId, destVendorId)
    log.trace({ sourceVendorId, destVendorId }, 'package and vendor unassignment successful')
    setIsLoading(false)
  }, [])

  return { assign, unAssign, isLoading, vendorCopyPackages }
}

export default usePackageAssignment
