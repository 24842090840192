import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import CustomModal from "./CustomModal";
import AuthService from "../auth/AuthService";
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { isProd } from "../package/utils";

interface IdleTimeoutModalProps {
  authService: AuthService
}

const getIdleTimeout = ():number => {
    const defaultIdleTimeout =  isProd() ? 1000 * 60 * 50 : 1000 * 60* 60 * 2;
    const storedTimeout = window.localStorage?.getItem('idle_timeout');
    if(storedTimeout) {
        return parseInt(storedTimeout, 10);
    }
    else {
        return defaultIdleTimeout;
    }
}

const IdleTimeoutModal: React.FC<IdleTimeoutModalProps> = ({ authService }) => {
  const idleTimeout =  getIdleTimeout(); // Idle time out 50 min to prompt.
  const promptBeforeIdle = 1000 * 60 * 5; // Prompt timer 5 min.
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handlePrompt = () => {
    if (!timerInterval) {
      const intrvl = setInterval(() => getTime(), 1000);
      setTimerInterval(intrvl);
      setIsOpen(true);
    }
  };

  const logout = () => {
    if (timerInterval) clearInterval(timerInterval);
    authService.logout(true);
    setIsOpen(false);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: promptBeforeIdle,
    onPrompt: handlePrompt,
    onIdle: logout,
  });

  const getTime = () => {
    const remainingTime = Math.ceil(getRemainingTime() / 1000);
    if (remainingTime <= 0) {
      logout();
    }
    const min = Math.floor(remainingTime / 60);
    const sec = Math.floor(remainingTime - min * 60);
    setMinutes(min);
    setSeconds(sec);
  };

  const resetTimer = () => {
    const remainingTime = Math.ceil(promptBeforeIdle / 1000);
    const min = Math.floor(remainingTime / 60);
    const sec = Math.floor(remainingTime - min * 60);
    setMinutes(min);
    setSeconds(sec);
  };

  const onStayLoggedIn = () => {
    if (timerInterval) clearInterval(timerInterval);
    setTimerInterval(null);
    activate();
    resetTimer();
    setIsOpen(false);
  };

  useEffect(() => {
    resetTimer();
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, []);

  return timerInterval ? (

    <CustomModal open={isOpen}
        onClose={onStayLoggedIn}
        name="global-error-modal"
        title="Warning!"
        showOkButton={false}
        customWidth={'700px'}
        customHeaderHeight={'50px'}
    >
      <Box sx={{ textAlign: 'center', padding: 2 }}>
        {minutes >= 0 ? (
          <Box>
            <Typography variant="body1">Are you still here? Logging out in</Typography>
            <Typography variant="h4" sx={{ margin: '8px 0' }}>
              {`${minutes}:${seconds.toString().padStart(2, "0")}`}
            </Typography>
            <Button 
              onClick={onStayLoggedIn} 
              sx={{ 
                color: 'primary.main', 
                textDecoration: 'underline', 
                cursor: 'pointer', 
                marginTop: 2 
              }}
            >
              I’m still here
            </Button>
          </Box>
        ) : (
          <Typography variant="body1">Logging out...</Typography>
        )}
      </Box>
    </CustomModal>
  ) : null;
};

export default IdleTimeoutModal;
