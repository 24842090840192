import stableStringify from 'json-stable-stringify'
import { Logger } from '../common/logger'
import { ApiConfig } from '../config/config'
import Service from '../common/Service'
import HttpClient from '../common/HttpClient'

export enum PackageItemTypes
{
  section = 'section',
  documentLink = 'documentlink'
}

export interface Section
{
  Title: string
  Items: Item[]
  $type: string
  DocumentId?:string
}

export const sectionFactory = (Title: string = '', Items: Item[] = []) => ({
  Title,
  Items,
  $type: PackageItemTypes.section
})

export interface DocumentLink
{
  Title: string
  DocumentId: string
  $type: string
}

export const documentLinkFactory = (Title: string = '', DocumentId: string = Title) => ({
  Title,
  DocumentId,
  $type: PackageItemTypes.documentLink
})

export type Item = Section | DocumentLink

export interface Package
{
  Id: string
  Items: Item[]
  CreatedTimestamp?: string
  RevisionTimestamp?: string
  Title: string
}

export interface PackageListItem
{
  Id: string
  CreatedTimestamp: string
  ModifiedTimestamp: string
  NumFiles: number
  Title: string
}

export interface PackageListResponse
{
  Offset: number
  Limit: number
  HasNextPage: boolean
  EffectiveVendorName?: string
  Packages: PackageListItem[]
}

export interface PackageSearchItems {
    PackageId: string,
    DocumentId: string,
    DocumentTitle: string,
    DocumentDescription: string
  }
export interface PackageSearchResults {
    Results:PackageSearchItems[]
}

export default class PackageService extends Service<Package>
{

  constructor(logger: Logger, httpClient: HttpClient, private readonly config: ApiConfig)
  {
    super(logger, httpClient, 'PackageService')
  }

  get = (id: string) => super.httpGet(`${this.config.packagesUrl}/${id}`)

  list(offset?: number, limit?: number, titleFilter?: string, sortMethod?:string, forVendorId?: string)
  {
    return super.httpGet<PackageListResponse>(this.config.packagesUrl,
      { offset, limit, titleFilter, sortMethod, assignedToVendor: forVendorId })
  }
  
  viewerPackageList(offset?: number, limit?: number, titleFilter?: string, forVendorId?: string, sortMethod?:string)
  {
    return super.httpGet<PackageListResponse>(this.config.viewerPackagesUrl,
      { offset, limit, titleFilter, sortMethod },{headers:{'X-UDocs-Impersonate-SupplierId':forVendorId}})
  }
  searchViewerPackageList(titleFilter?: string, forVendorId?: string,)
  {
    return super.httpGet<PackageSearchResults>(this.config.searchViewerPackageURL,
      { titleFilter },{headers:{'X-UDocs-Impersonate-SupplierId':forVendorId}})
  }
  getViewerPackageDetails = (id: string, vendorId?:string|undefined) => {
    return super.httpGet(`${this.config.viewerPackagesUrl}/${id}`,{}, {headers:{'X-UDocs-Impersonate-SupplierId':vendorId}})
  }

  update(pkg: Partial<Package>)
  {
    return super.httpPut(`${this.config.packagesUrl}/${pkg.Id as string}`, pkg, {
      headers: {
        'Content-Type': 'application/json'
      },
      // @NOTE:  API REQUIRES this to have the $type property stable sorted first
      transformRequest: [data => stableStringify(data)]
    })
  }

  create = (pkg: Partial<Package>) => super.httpPost(this.config.packagesUrl, pkg)
  delete = (id: string) => super.httpDelete(`${this.config.packagesUrl}/${id}`)

}
