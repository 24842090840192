import React, {FC} from 'react';
import { Box, Container } from '@mui/system';
interface SessionExpiredProps {
    message?:string
}
const SessionExpired:FC<SessionExpiredProps> = ({message}) => {
    return (
        <Container>
            <Box sx={{
                display:'flex',
                height:'300px',
                marginTop:'10%',
                justifyContent:'center',
            }}>
                <h2> {message?message:'Your Session has ended.'} <a href={`${window.location.origin}`}>Login</a></h2>
            </Box>
        </Container>
    );
};

export default SessionExpired;