import React, { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'
import { OrionButton, OrionLabel, OrionTextInput } from '@orion/orion-design-system-react'

interface SearchBarProps extends React.HTMLProps<HTMLInputElement>
{
  handleSearch: (searchTerm: string) => void,
  userRole:string|undefined
}

const PackageSearchBar = ({ handleSearch, userRole }: SearchBarProps) =>
{
  const [searchTerms, setSearchTerms] = useState('')
  const navigate = useNavigate()
  const handleNewPackage = useCallback(() =>
  {
    navigate('/admin/package/new')
  }, [navigate])
  const handleClearSearchTerms = useCallback(() =>
  {
    setSearchTerms('')
    handleSearch('')
  }, [setSearchTerms])
  const handleSetSearchTerms = useCallback(
    (event: CustomEvent<{ value: string }>) =>
    {
      setSearchTerms(event.detail.value)
      handleSearch(event.detail.value)
    },
    [setSearchTerms]
  )
  return (
    <Box>
      <Grid container spacing={1} direction="row" justifyContent="space-between">
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <OrionLabel>Title search</OrionLabel>
            </Grid>
            <Grid item>
              <OrionTextInput
                id="package-search"
                type="text"
                placeholder="Enter keyword"
                value={searchTerms}
                onValueChanged={handleSetSearchTerms}
              />
            </Grid>
            <Grid item>
              <OrionButton variant="tertiary" onClick={handleClearSearchTerms}>
                Clear search
              </OrionButton>
            </Grid>
          </Grid>
        </Grid>
        {userRole?.toLowerCase() ==='admin' && <Grid item>
          <OrionButton variant="secondary" icon-name="edit" onClick={handleNewPackage}>
            Add New Package
          </OrionButton>
        </Grid>}
      </Grid>
    </Box>
  )
}

export default PackageSearchBar

