import camelCase from 'lodash/camelCase'
import { AuthConfig } from '../config/config'

const REACT_APP_AUTH = 'REACT_APP__AUTH__'

const createConfig = (config: AuthConfig): AuthConfig => {
  for (const key of Object.keys(process.env)) {
    if (key.includes(REACT_APP_AUTH)) {
      // override config values with environment variables
      const newKey = camelCase(key.replace(REACT_APP_AUTH, ''))
      config[newKey] = process.env[key] as string
    }
  }
  return config as AuthConfig
}

export default createConfig
