import { useCallback, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import logger from '../common/logger'
import PackageService, { Package, sectionFactory } from '../package/PackageService'
import useServiceAuth from '../common/useServiceAuth'
import useServiceMessaging from '../common/useServiceMessaging'

export const INITIAL_PACKAGE = {
  Id: '0',
  Title: 'Untitled',
  Items: [
    sectionFactory()
  ]
}

const useViewerPackage = (packageService: PackageService, packageId?: string) =>
{
  const log = logger.child({ hook: 'usePackage' })
  const [pkg, setPkg] = useState<Package>()
  const [isLoadingPkg, setIsLoadingPkg] = useState(false)
  const { waitingOnAuth } = useServiceAuth<Package>(packageService)

  useServiceMessaging<Package>(packageService, 'Package Error')

  const handleError = useCallback(() =>
  {
    setIsLoadingPkg(false)
  }, [setIsLoadingPkg])

  useEffect(() =>
  {
    packageService.addErrorHandler(handleError)
    return () =>
    {
      packageService.removeErrorHandler(handleError)
    }
  }, [])

  // handle fetching a package if packageId is provided
  useEffect(() =>
  {
    if (!isEmpty(pkg)) return
    if (waitingOnAuth) return
    if (isEmpty(packageId)) {
      log.trace('no packageId provided.')
      return
    }
    if (isLoadingPkg) return
    (async () =>
    {
      setIsLoadingPkg(true)
      const apiPkg = await getViewerPkg(packageId as string)
      setPkg(apiPkg)
      setIsLoadingPkg(false)
    })()
  }, [packageId, waitingOnAuth, isLoadingPkg])

  const getViewerPkg = useCallback(async (pkgId: string, selectedVendorId?:string|undefined ) =>
  {
    log.trace({ packageId }, 'fetching package')
    const apiPkg = await packageService.getViewerPackageDetails(pkgId, selectedVendorId)
    log.trace({ package: apiPkg }, 'package fetched')
    return apiPkg
  }, [packageService])

  return { pkg, isLoadingPkg, getViewerPkg }
}

export default useViewerPackage
