import { useCallback, useEffect, useState } from 'react'
import logger from '../common/logger'
import usePackageStore from '../package/usePackageStore'
import PackageService, { Package, PackageSearchResults } from '../package/PackageService'
import useServiceAuth from '../common/useServiceAuth'
import { debounce } from 'lodash'
import useServiceMessaging from '../common/useServiceMessaging'


const useViewerPackages = (
  packageService: PackageService,
  searchString?: string,
  vendorId?: string,
  offset?: number,
  limit?: number,
  sortMethod?:string
) =>
{
  const log = logger.child({ hook: 'useViewerPackages' })
  const packages = usePackageStore(state => state.packages)
  const setPackages = usePackageStore(state => state.setPackages)
  const [isLoadingPackages, setIsLoadingPackages] = useState(packages.length === 0)
  const [hasNextPage, setHasNextPage] = useState(false)
  const { waitingOnAuth } = useServiceAuth<Package>(packageService)
  const [viewerPackageSearchResults, setViewerPackageSearchResults] = useState<PackageSearchResults|undefined>()
  useServiceMessaging<Package>(packageService, 'Packages Error')

  const handleError = useCallback(() => {
    setIsLoadingPackages(false)
  }, [setIsLoadingPackages])

  useEffect(() =>
  {
    packageService.addErrorHandler(handleError)
    return () => {
      packageService.removeErrorHandler(handleError)
    }
  }, [])

  const search = useCallback(() =>
  {
    if (waitingOnAuth) return

    (async () =>
    {

      if(searchString && searchString?.trim()?.length>0) {
        setIsLoadingPackages(true);
        const viewerPackage = await packageService.searchViewerPackageList(searchString, vendorId)
        if (!viewerPackage?.Results) {
          log.trace({ viewerPackage }, 'no packages received')
          return
        }
        setViewerPackageSearchResults(viewerPackage);
        setIsLoadingPackages(false)
      }
      else {
        setIsLoadingPackages(true);
        log.trace({ searchString }, 'fetching packages')
        const response = await packageService.viewerPackageList(offset, limit, searchString, vendorId, sortMethod)
        if (!response) {
          log.trace({ response }, 'no packages received')
          return
        }
        log.trace({ response }, 'packages fetched')
        setPackages(response.Packages)
        setHasNextPage(response.HasNextPage)
        setIsLoadingPackages(false)
      }

      
    })()
  }, [waitingOnAuth, searchString, offset, limit, vendorId, sortMethod, packageService])


  const debouncedPackagesSearchHandler = useCallback(debounce(search, 200), [search])

  useEffect(() =>
  {
    debouncedPackagesSearchHandler()

  }, [searchString, offset, limit, vendorId, sortMethod, waitingOnAuth])

  return { packages, isLoadingPackages, hasNextPage, searchPackages: search, viewerPackageSearchResults }
}

export default useViewerPackages
