import { useCallback, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import logger from '../common/logger'
import VendorService, { Vendor } from './VendorService'
import useServiceAuth from '../common/useServiceAuth'
import useServiceMessaging from '../common/useServiceMessaging'

const useVendors = (
  vendorService: VendorService,
  searchString?: string,
  offset?: number,
  limit?: number,
  packageId?: string,
  reload = false
) =>
{
  const log = logger.child({ hook: 'useVendors' })
  const [vendors, setVendors] = useState<Vendor[]>([])
  const [isLoadingVendors, setIsLoadingVendors] = useState(vendors.length === 0)
  const [hasNextPage, setHasNextPage] = useState(false)
  const { waitingOnAuth } = useServiceAuth<Vendor[]>(vendorService)

  useServiceMessaging<Vendor[]>(vendorService, 'Vendors Error')

  const handleError = useCallback(() =>
  {
    setIsLoadingVendors(false)
  }, [setIsLoadingVendors])

  useEffect(() =>
  {
    vendorService.addErrorHandler(handleError)
    return () =>
    {
      vendorService.removeErrorHandler(handleError)
    }
  }, [])

  const handleSearchVendors = useCallback(async (nameFilter?: string, searchOffset?: number, searchLimit?: number, forPackageId?: string) =>
  {
    if (waitingOnAuth) return
    log.trace({ searchString }, 'fetching vendors')
    setIsLoadingVendors(true)
    const response = await vendorService.list({ offset: searchOffset, limit: searchLimit, nameFilter, forPackageId })
    if (!response) {
      log.trace({ response }, 'no vendors received')
      return
    }
    log.trace({ response }, 'vendors fetched')
    setVendors(response.Vendors)
    setHasNextPage(response.HasNextPage)
    setIsLoadingVendors(false)
    return response
  }, [waitingOnAuth, searchString, setIsLoadingVendors, vendorService, offset, limit, packageId])

  const debouncedVendorSearchHandler = useMemo(() => debounce(
      handleSearchVendors, 200
  ), [handleSearchVendors, offset, limit, searchString, packageId])

  useEffect(() =>
  {
    debouncedVendorSearchHandler(searchString, offset, limit, packageId)
  }, [searchString, offset, limit, packageId, reload])

  return { vendors, isLoadingVendors, hasNextPage, searchVendors: handleSearchVendors }
}

export default useVendors
