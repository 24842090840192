import React, { lazy, Suspense, useMemo, FC } from 'react';

import viewbox from './viewbox';
import './icon.scss';

export interface IconProps
{
    variant: string,
    className?: string|null,
    width: string,
    height: string,
    clickable: string|null,
}

const Icon:FC<IconProps> = (props) => {
    const { variant, className, clickable } = props;
    const determineWidthAndHeight = (type:string, passedValue:string) => {
        const [, , viewWidth, viewHeight] = viewbox[variant as keyof typeof viewbox ].split(' ');
        const ratio = +(Number(viewHeight) / Number(viewWidth)).toFixed(2);
        const matchResult = passedValue.match(
            /(\d+\.?\d+)(.+)?/
        );
        let usablePassedValue:string|undefined;
        let unit:string|undefined;
        if(matchResult!==null) {
            usablePassedValue = matchResult[1];
            unit = matchResult[2];
        }
        if (type === 'width') {
            const returnWidth = (Number(usablePassedValue) / ratio).toFixed(2);
            return `${returnWidth}${unit}`;
        }
        const returnHeight = (Number(usablePassedValue) * ratio).toFixed(2);
        return `${returnHeight}${unit}`;
    }
    const heightAndWidthHandler = (type:string) => {
        if (type in props) {
            return props[type as keyof typeof props];
        }
        switch (type) {
        // Fallthrough    
        case 'width':
            if ('height' in props) {
            return determineWidthAndHeight(type, props.height);
            }
        // Fallthrough
        case 'height':
            if ('width' in props) {
            return determineWidthAndHeight(type, props.width);
            }
        // Fallthrough
        default:
            return '20px';
        }
    }
    const config = {
        xmlns: 'https://www.w3.org/2000/svg',
        xmlnsXlink: 'https://www.w3.org/1999/xlink',
        viewBox: viewbox[variant as keyof typeof viewbox],
        width: heightAndWidthHandler('width'),
        height: heightAndWidthHandler('height'),
        ...(props as Omit<IconProps, 'width'|'height'>),
        className: `core-icon ${className || ''} ${
          clickable ? 'clickable-icon' : ''
        }`
    };
    const IconContent = useMemo(
        () => lazy(() => import(`../../assets/icons/${variant}.js`)),
        [variant]
    );

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <Suspense fallback={<svg height={config.height} width={config.width} {...config as Omit<IconProps, 'width'|'height'> } />}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
           {/* @ts-ignore */}
          <svg height={config.height} width={config.width} {...config}>
            <IconContent {...config} />
          </svg>
        </Suspense>
        //ts-ignore-end
        )
}


export default Icon;