/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState,FC , useEffect, useRef} from 'react'
import styles from "./ifmAdmin.module.scss"
import {
    OrionButton,
    OrionLabel,
    OrionError,
  } from '@orion/orion-design-system-react'
  import Grid from '@mui/material/Grid'
  import Box from '@mui/material/Box'
import useUploadFile from './useUploadFile'
import IFMAdminService from './ifmadminService'
import { styled } from '@mui/material'
import { CircularProgress } from '@mui/material'
const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255,0.7)',
  zIndex: 1
})

export interface IFMtADMIN {
  ifmadminService: IFMAdminService,
}
export interface FileData {
  lastModified?:string
  lastModifiedDate?:object
  name?:string
  size?:number
  type?:string
  webkitRelativePath?:string
}

const IFMAdmin: FC<IFMtADMIN>=({ifmadminService})=>{
    const [masterData,setMasterData]=useState<FileData| null|File>(null)
    const [metaData,setMetaData]=useState<FileData| null|File>(null)
    const [validator,setValidator]=useState(true)
    const {uploadFiles, uploadFilesResp, isLoading}=useUploadFile(ifmadminService)
  
    const masterInputRef = useRef<HTMLInputElement | null>(null);
    const metaInputRef = useRef<HTMLInputElement | null>(null);

    const masterFile=(e:React.ChangeEvent<HTMLInputElement>)=>{
      if(e.target.files != null){
            setMasterData(e.target.files[0])
      }
    }
    const metaFile=(e:React.ChangeEvent<HTMLInputElement>)=>{
     if(e.target.files != null){
        setMetaData(e.target.files[0])
     }
    }
    useEffect(()=>{
      if(masterData?.type==='text/csv'&& metaData?.type==="text/csv"){
        setValidator(false)
      }
      else{
        setValidator(true)
      }
    },[masterData,metaData])

    useEffect(()=>{
      if(uploadFilesResp) {
        resetUploadedFiles()
      }
    },[uploadFilesResp])

    const resetUploadedFiles = () => {
      setMasterData(null);
        setMetaData(null); 
        if(masterInputRef.current) {
          masterInputRef.current.value=''
        }
        if(metaInputRef.current) {
          metaInputRef.current.value=''
        }
    }

    const handleSubmit= ()=>{
      const formData:any= new FormData();
      formData.append('data', masterData);
      formData.append('metadata', metaData);
      uploadFiles(formData)
    }

return (
  <>
  <div className={styles.ifmAdmin}>
  {isLoading && (
        <Overlay>
          <CircularProgress />
        </Overlay>
      )}
    <Grid container spacing={1} my={2} mx={2} direction='row' >
      <OrionLabel for='orion-component-id'>Master Data CSV</OrionLabel>
        <input ref={masterInputRef} type="file" className={styles.input} onChange={(e)=>masterFile(e)} accept='.csv' required/>
    </Grid>
    <Grid container spacing={1} my={2} mx={2} direction='row'>
      <OrionLabel for='orion-component-id'>Metadata CSV</OrionLabel>
      <input ref={metaInputRef} type="file" className={styles.input}onChange={(e)=>metaFile(e)} accept='.csv' required />
    </Grid >
    <Box mx={2}>
    <OrionButton variant='primary' small onClick={handleSubmit} style={{marginLeft:"40px",padding:"5px 10px"}}  {...(validator ? { disabled: true } : {})} >
       Submit
      </OrionButton>
    </Box>
    {validator&& masterData!==null&&metaData!==null?<OrionError>Invalid file type</OrionError>:null}
    </div>
  </>
)
}
export default IFMAdmin