import { useCallback, useEffect, useState } from 'react'
import VendorService, {PresignedURLProps, ReportIdProps} from './VendorService'
import useServiceAuth from '../common/useServiceAuth'
import logger from '../common/logger'


const useAdminVendorReport = (vendorService: VendorService) =>
{
  const log = logger.child({ hook: 'usePackageAssignment' })
  const [isLoading, setIsLoading] = useState(false)
  const { waitingOnAuth } = useServiceAuth(vendorService)

  const handleError = useCallback(() =>
  {
    setIsLoading(false)
  }, [setIsLoading])

  useEffect(() =>
  {
    vendorService.addErrorHandler(handleError)
    return () =>
    {
      vendorService.removeErrorHandler(handleError)
    }
  }, [])

  const getVendorPackagesReportId = useCallback(async (vendorId:string) =>
  {
    if (waitingOnAuth) throw new Error('Authentication not ready')
    setIsLoading(true)
    const vendorPackagesReportId =  await vendorService.startVendorPackagesReport(vendorId) as ReportIdProps
    log.trace('fetched session details successful')
    return vendorPackagesReportId
  }, [vendorService, setIsLoading, waitingOnAuth])

  const downloadVendorPackagesReport = useCallback(async (reportId:string) =>
    {
      if (waitingOnAuth) throw new Error('Authentication not ready')
      const vendorPackagespreSignedURL =  await vendorService.downloadVendorReport(reportId) as PresignedURLProps
      log.trace('fetched session details successful')
      if(vendorPackagespreSignedURL?.PresignedURL) {
        setIsLoading(false)
      }
      return vendorPackagespreSignedURL
    }, [vendorService, setIsLoading, waitingOnAuth])

  return { getVendorPackagesReportId, downloadVendorPackagesReport,  isLoading }
}

export default useAdminVendorReport
