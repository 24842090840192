import React, { FC, PropsWithChildren } from 'react'
import Modal, { ModalProps } from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FlexContainer from './FlexContainer'
import OrionIcon from './OrionIcon'
import Icon from '../common/Icon/Icon'
import { styled } from '@mui/material/styles'
import { OrionButton } from '@orion/orion-design-system-react'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1500px',  // Default width
  bgcolor: 'background.paper',
  boxShadow: 24,
}

const Header = styled(Box)<{ height: string }>(({ height }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: height,  // Customizable height
  color: 'var(--orion-color-text-inverted, #fff)',
  backgroundColor: 'var(--orion-color-grayscale-black)',
}))

const Content = styled(Box)({
  bgcolor: 'var(--orion-color-background-surface-dimmer, #f2f2f2)',
})

const Footer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderTop: '1px solid #ccc',
})

const OkButton = styled(OrionButton)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: '1px',
  width:'80px'
}))

export interface CustomModalProps extends ModalProps {
  open: boolean
  onClose: () => void
  name: string
  title: string
  isOpenPDFLink?: boolean
  openPDFinOtherTab?: () => void
  customHtml?: string | null
  showOkButton?: boolean
  okButtonText?: string
  customWidth?: string
  customHeaderHeight?: string  // New prop for custom header height
  isDisplayContentOnly?:boolean
}

const CustomModal: FC<PropsWithChildren<CustomModalProps>> = ({
  open,
  onClose,
  name,
  title,
  isOpenPDFLink,
  openPDFinOtherTab,
  customHtml,
  showOkButton = false,
  okButtonText = 'Ok',
  customWidth = '1500px',  // Default width for the modal
  customHeaderHeight = '50px',  // Default header height
  isDisplayContentOnly = false,
  children,
  ...props
}) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={`${name}-modal-title`}
      aria-describedby={`${name}-modal-description`}
      {...props}
    >
      {
        isDisplayContentOnly ? <Box sx={{ ...style, width: customWidth }}>{children}</Box>:
      
      <Box sx={{ ...style, width: customWidth }}>
        <FlexContainer flexDirection="column">
          <Header height={customHeaderHeight} >
            <Typography id={`${name}-modal-title`} component="h2" variant="h6" sx={{marginLeft:'10px'}}>{title}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              {isOpenPDFLink &&
                <Box onClick={openPDFinOtherTab} sx={{ marginRight: '20px' }}>
                  <Icon variant='popout' width='30px' height='40px' clickable='true' />
                </Box>
              }
              <Button onClick={() => onClose()}>
                <OrionIcon icon="close" sx={{ color: 'var(--orion-color-text-inverted, #fff)' }} />
              </Button>
            </Box>
          </Header>
          <Content sx={{overflowWrap: 'break-word'}}>
          {customHtml 
            ? <div style={{color:'red', margin:'16px'}} dangerouslySetInnerHTML={{ __html: customHtml }} />
           : children}
          </Content>
          {showOkButton && (
            <Footer>
              <OkButton onClick={onClose} sx={{cursor:'pointer'}}>
                {okButtonText}
              </OkButton>
            </Footer>
          )}
        </FlexContainer>
      </Box>
      }
    </Modal>
  )
}

export default CustomModal
