import React from'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { applyPolyfills, defineCustomElements } from '@orion/orion-design-system/loader'
import { enablePatches } from 'immer'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles'
import logger from './common/logger'

import App from './App'
import Home from './home/Home'
import PackageManager from './package/PackageManager'
import VendorSection from './vendor/VendorSection'
import LightViewer from './lightviewer/LightViewer'
import Viewer from './viewer/Viewer'
import IFM from './ifm/IFM'
import AuthService from './auth/AuthService'
import './index.css'
import PackageService from './package/PackageService'
import DocumentService from './document/DocumentService'
import VendorService from './vendor/VendorService'
import PackageAssignmentService from './package/PackageAssignmentService'
import IFMService from './ifm/IFMService'

import AdminVendorsService from './viewer/AdminVendorsService'
import HttpClient from './common/HttpClient'
import AuditService from './audit/AuditService'
import { AuthProvider } from './auth/AuthContext'
import SessionInfoService from './auth/SessionInfoService'
import UnAuthorized from './common/UnAuthorized'
import { getEnv, getEnvName } from './package/utils'
import { datadogRum } from '@datadog/browser-rum';
import { ErrorProvider } from './error/ErrorContext'
import GlobalErrorModal from './common/GlobalErrorModal'
import TRMService from './trm/TRMService'

const appEnvironment = getEnvName();
if(appEnvironment?.toLocaleLowerCase()!=='local') {
  datadogRum.init({
    applicationId: 'cc14ea6f-494c-49b0-9c0c-e487b2e0e2ef',
    clientToken: 'pub0370ec983b1c2d0543be1faaaea0b089',
    site: 'datadoghq.com',
    service: 'support-data-udocs',
    env: getEnvName(),
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.setGlobalContext({
    buildTimestamp: process.env?.REACT_APP_BUILD_TIMESTAMP || '',
});
}


import IFMAdmin from './ifmadmin/IFMAdmin'
import IFMAdminService from './ifmadmin/ifmadminService'
import SessionExpired from './common/SessionExpired'
import TRM from './trm/TRM'
import TRMAdminService from './trmadmin/trmadminService'
import TRMAdmin from './trmadmin/TRMAdmin'
const theme = responsiveFontSizes(
  createTheme({
    typography: {
      h1: {
        fontSize: '2rem',
        fontWeight: 'bold'
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 'bold'
      }
    }
  })
)

const currentEnv = getEnv();

const apiHttpClient = new HttpClient(logger, currentEnv.api.baseUrl)
const authHttpClient = new HttpClient(logger, currentEnv.auth.baseUrl)
const authService = new AuthService(logger, authHttpClient, currentEnv.auth)
const auditService = new AuditService(logger, apiHttpClient, currentEnv.api)
const packageService = new PackageService(logger, apiHttpClient, currentEnv.api)
const packageAssignmentService = new PackageAssignmentService(logger, apiHttpClient, currentEnv.api)
const vendorService = new VendorService(logger, apiHttpClient, currentEnv.api)
const documentService = new DocumentService(logger, apiHttpClient, currentEnv.api)
const sessionInfoService = new SessionInfoService(logger, apiHttpClient,currentEnv.api)
const adminVendorsService = new AdminVendorsService(logger, apiHttpClient,currentEnv.api)
const ifmService=new IFMService(logger,apiHttpClient,currentEnv.api)
const trmService = new TRMService(logger,apiHttpClient,currentEnv.api)
const ifmadminService=new IFMAdminService(logger,apiHttpClient,currentEnv.api)
const trmadminService=new TRMAdminService(logger,apiHttpClient,currentEnv.api)

const router = createBrowserRouter([
  {
    path: '/admin',
    element: <App authService={authService} sessionInfoService={sessionInfoService} />,
    children: [
      {
        index: true,
        element: <Home {...{ auditService, packageService, packageAssignmentService, vendorService }} />
      },
      {
        path: 'package/:packageId',
        element: <PackageManager {...{ documentService, packageService, packageAssignmentService, vendorService }} />
      },
      {
        path: 'vendor/:vendorId',
        element: <VendorSection {...{vendorService, auditService, packageAssignmentService}} />
      }
    ]
  },
  {
    path: '/',
    element: <App authService={authService} sessionInfoService={sessionInfoService} />,
    children: [
      {
        index: true,
        element: <Viewer {...{ adminVendorsService, packageService }} />
      },
      {
        path: 'unauthorized',
        element: <UnAuthorized/>
      }
    ]
  },
  {
    path: '/xref',
    element: <App authService={authService} sessionInfoService={sessionInfoService} />,
    children: [
      {
        index: true,
        element: <IFM IfmService={ifmService}  />
      },
      {
        path: 'tailno/:tailno',
        element: <IFM IfmService={ifmService}  />
      },
    ]
  },
  {
    path: '/trm',
    element: <App authService={authService} sessionInfoService={sessionInfoService} />,
    children: [
      {
        index: true,
        element: <TRM trmService={trmService}  />
      },
      {
        path: 'fleetcode/:fleetcode',
        element: <TRM trmService={trmService}  />
      },
    ]
  },
  {
    path: '/xrefadmin',
    element: <App authService={authService} sessionInfoService={sessionInfoService} />,
    children: [
      {
        index: true,
        element: <IFMAdmin ifmadminService={ifmadminService}/>
      },
     
    ]
  },
  {
    path: '/trmadmin',
    element: <App authService={authService} sessionInfoService={sessionInfoService} />,
    children: [
      {
        index: true,
        element: <TRMAdmin trmadminService={trmadminService}/>
      },
     
    ]
  },
  {
    path:'/pdfViewer',
    element: <App authService={authService} sessionInfoService={sessionInfoService} />,
    children: [
      {
        index: true,
        element: < LightViewer/>
      },
     
    ]
  },
  {
    path: '/sessionExpired',
    element: <SessionExpired/>
  }

])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <AuthProvider>
    <ErrorProvider>
    <ThemeProvider theme={theme}>
      <GlobalErrorModal/>
      <RouterProvider router={router} />
    </ThemeProvider>
    </ErrorProvider>
  </AuthProvider>
)

enablePatches()

// Orion Polyfills
applyPolyfills().then(() =>
{
  defineCustomElements(window)
})
