import { useCallback, useEffect, useState } from 'react'
import AdminVendorsService from '../viewer/AdminVendorsService'
import useServiceAuth from '../common/useServiceAuth'
import logger from '../common/logger'

const useAdminVendors = (adminVendorsService: AdminVendorsService) =>
{
  const log = logger.child({ hook: 'usePackageAssignment' })
  const [isLoading, setIsLoading] = useState(false)
  const { waitingOnAuth } = useServiceAuth(adminVendorsService)

  const handleError = useCallback(() =>
  {
    setIsLoading(false)
  }, [setIsLoading])

  useEffect(() =>
  {
    adminVendorsService.addErrorHandler(handleError)
    return () =>
    {
        adminVendorsService.removeErrorHandler(handleError)
    }
  }, [])

  const adminVendorDetails = useCallback(async (searchVal:string) =>
  {
    if (waitingOnAuth) throw new Error('Authentication not ready')
    setIsLoading(true)
    const adminVendorList =  await adminVendorsService.getAdminVendorsList(searchVal)
    log.trace('fetched session details successful')
    setIsLoading(false)
    return adminVendorList
  }, [adminVendorsService, setIsLoading, waitingOnAuth])

  return { adminVendorDetails, isLoading }
}

export default useAdminVendors
