import Service from '../common/Service'
import { Logger } from '../common/logger'
import HttpClient from '../common/HttpClient'
import { ApiConfig } from '../config/config'

export enum AuditEventType
{
  PACKAGE_CREATED = 'PACKAGE_CREATED',
  PACKAGE_MODIFIED = 'PACKAGE_MODIFIED',
  PACKAGE_DELETED = 'PACKAGE_DELETED',
  PACKAGE_VENDOR_ASSIGNED = 'PACKAGE_VENDOR_ASSIGNED',
  PACKAGE_VENDOR_UNASSIGNED = 'PACKAGE_VENDOR_UNASSIGNED'
}

export interface AuditEvent
{
  EventId: string
  EventTimestamp: string
  EventType: AuditEventType
  UserId: string
  PackageId: string
  VendorId: string
  PackageTitle?: string
  PackageContent?: string
  VendorName: string
}

export interface AuditResponse
{
  HasNextPage: boolean
  PageId: string
  Events: AuditEvent[]
}

export default class AuditService extends Service<AuditResponse>
{
  constructor(logger: Logger, httpClient: HttpClient, private readonly config: ApiConfig)
  {
    super(logger, httpClient, 'AuditService')
  }

  get = (eventId: string) => super.httpGet<AuditEvent>(this.config.auditsUrl,
    { eventId },
    { transformResponse: data => data.Events[0] })

  list(limit?: number, previousPageId?: string, packageId?: string, vendorId?: string, offset?:number)
  {

    const params:{
      limit?:number,
      previousPageId?:string,
      packageId?:string,
      vendorId?:string,
      offset?:number
    } = {
      limit,
      previousPageId,
      offset
    }
    if(packageId) {
      params.packageId = packageId
    }
    if(vendorId) {
      params.vendorId = vendorId
    }

    return super.httpGet<AuditResponse>(this.config.auditsUrl, params)
  }
}
