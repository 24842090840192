import useMessagingStore from './useMessagingStore'

const useMessaging = () =>
{
  const sendMessage = useMessagingStore(state => state.sendMessage)

  const sendSuccessMessage = (message: string, heading?: string, ttl?: number) =>
  {
    sendMessage(message, 'success', heading, ttl)
  }
  const sendP1ErrorMessage = (message: string, heading?: string, ttl?: number) =>
  {
    sendMessage(message, 'high', heading, ttl)
  }
  const sendP2ErrorMessage = (message: string, heading?: string, ttl?: number) =>
  {
    sendMessage(message, 'medium', heading, ttl)
  }
  const sendP3ErrorMessage = (message: string, heading?: string, ttl?: number) =>
  {
    sendMessage(message, 'low', heading, ttl)
  }

  return { sendSuccessMessage, sendP1ErrorMessage, sendP2ErrorMessage, sendP3ErrorMessage }
}

export default useMessaging
