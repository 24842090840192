import React, { FC, PropsWithChildren, useCallback } from 'react'
import { DndContext, DragEndEvent, PointerSensor, TouchSensor, useSensor, useSensors, rectIntersection } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { observer } from 'mobx-react-lite'
import logger from '../common/logger'
import PackageTree, { PackageTreeItem } from './PackageTree'
import { Item } from './PackageService'
import { fromPackageItem } from './PackageSerializers'
import useMessaging from '../messaging/useMessaging'
import CustomError from '../common/CustomError'

export interface PackageStructureDNDProps
{
  packageTree: PackageTree
}

const PackageStructureDND: FC<PropsWithChildren<PackageStructureDNDProps>> = observer(({ children, packageTree }) =>
{
  const log = logger.child({ component: 'PackageStructureDND' })
  const { sendSuccessMessage, sendP3ErrorMessage } = useMessaging()

  const handleDrop = useCallback(
    (event: DragEndEvent) =>
    {
      log.trace({ event }, 'handleDrop()')
      const { active, over, delta } = event
      if(!over || !((over?.id as string)?.startsWith('package-structure'))) {
        return false;
      }
      if(over?.data?.current) {
        const dropLevel = packageTree?.getItemLevel(over?.data?.current?.id);
        if(active?.data?.current?.type==='section' && dropLevel!==undefined && +dropLevel>=2) {
          sendP3ErrorMessage("Accepts only 2 levels!")
          return false;
        }
      }
      const distanceDragged = Math.abs(delta.x);
      const dropLeftPosition = Math.abs(over.rect.left);
      if(over?.id==='package-structure' && Array.isArray(active.data?.current) && distanceDragged < dropLeftPosition*3/4) {return false;}

      // @TODO:  this must default to null to prevent just adding items to the tree
      let parentId = packageTree.id
      if (over?.data?.current && over.data.current instanceof PackageTreeItem && over.data.current.type === 'section') {
        parentId = over.data.current.id
      }

      // drop document from DocumentSearch
      if (active.data.current && Array.isArray(active.data.current)) {
        for (const doc of active.data.current as Item[]) {
          try {
            const docId:string = doc?.DocumentId? doc?.DocumentId : "";
            if(packageTree.getItemByDocId(docId)) {
              sendP3ErrorMessage(`Already added this -> ${doc?.Title}`)
              return false;
            } 

            packageTree.addItemInto(parentId, fromPackageItem(doc, parentId))
            sendSuccessMessage(
              'Save to publish edits made to package',
              `${active.data.current.length} documents successfully attached`
            )
          } catch (err: unknown) {
            sendP3ErrorMessage((err as CustomError).message, 'Error adding Document')
          }
        }
        
      }

      // drop document or section from PackageStructure
      if (active.data.current && active.data.current instanceof PackageTreeItem) {
        if (active.data.current.id === over?.data?.current?.id) return
        log.trace({ drop: active.data.current, over: over?.data?.current, parentId, packageTree }, 'dropping from package structure')
        try {
          packageTree.removeItem(active.data.current.id)
          packageTree.addItemInto(parentId, active.data.current)
        } catch (err: unknown) {
          sendP3ErrorMessage((err as CustomError).message, 'Error moving Section or Document')
        }
      }

    },
    [packageTree]
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // make sure click events are forwarded
      activationConstraint: {
        distance: 8
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint:{
        delay:250,
        tolerance:5
      }
    })
  )

  return (
    <DndContext collisionDetection={rectIntersection} sensors={sensors} modifiers={[restrictToWindowEdges]} onDragEnd={handleDrop}>
      {children}
    </DndContext>
  )
})

export default PackageStructureDND
