import { v4 as uuidv4 } from 'uuid'
import { Logger } from '../common/logger'
import { ApiConfig } from '../config/config'
import Service from '../common/Service'
import HttpClient from '../common/HttpClient'
import { formatDate } from '../common/date'

export enum DocumentType {
  "UDOCS" = "UDOCS",
  "EA" = "EA",
  "ECRA" = "ECRA",
  "FC" = "FC"
}

export interface Document
{
  Id: string
  Title: string
  DocumentId: string
  ManualName: string
  SubTitle: string
  RevisionTimestamp: string
}

export interface DocumentSearchResponse
{
  Offset: number
  Limit: number
  HasNextPage: boolean
  Documents: Document[]
}

export interface adminPDFDocumentResponse {
  PresignedURL?: string
  Message?:string
  Title?:string
  DocumentId?:string
  error_message?:string
}

export default class DocumentService extends Service<Document>
{
  constructor(
    logger: Logger,
    httpClient: HttpClient,
    readonly config: ApiConfig
  )
  {
    super(logger, httpClient, 'DocumentService')
  }

  
  async search(title: string, limit = 25, offset = 0, type: DocumentType = DocumentType.UDOCS)
  {
    this.log.trace({ type, title, limit }, 'search()')
    const response = await super.httpGet<DocumentSearchResponse>(this.config.documentSearchUrl,
      { type, title, limit, offset })
    const searchResponse = response as unknown as DocumentSearchResponse
    return {
      ...searchResponse,
      Documents: searchResponse?.Documents?.map(document => ({
        ...document,
        Id: uuidv4(),
        RevisionTimestamp: formatDate(document.RevisionTimestamp),
        Title: `${document.Title}`
      }))
    }
  }
  async getPDFDocument(DocumentId:string, userRole?:string, packageId?:string)
  {
    this.log.trace({ DocumentId }, 'getPDFDocument()')


    const viewerPdfURL = `${this.config.viewerPDFDocumentUrl}${packageId}/get_content`;
    const getPDFUrl = userRole?.toLowerCase() === 'admin' ? this.config.adminPDFDocumentUrl: viewerPdfURL;
    const response = await super.httpPost<adminPDFDocumentResponse>(getPDFUrl,
      { DocumentId })
    const pdfDocumentResponse = response as unknown as adminPDFDocumentResponse
    return pdfDocumentResponse;
  }
  async getIfmDocument (DocumentId:string, userRole?:string,noseNumber?:string)
  {
    this.log.trace({DocumentId},'getIfmDocument')
     const ifmPdfURL=`${this.config.ifmViewDocument}${noseNumber}/get_content`
     const response= await super.httpPost<adminPDFDocumentResponse>(ifmPdfURL,{DocumentId})
     const ifmDocumentResponse = response as unknown as adminPDFDocumentResponse
    return ifmDocumentResponse;
  }
  async getTRMDocument (DocumentId:string, fleetcode:string)
  {
    this.log.trace({DocumentId},'getIfmDocument')
     const trmPdfURL=`${this.config.trmFleetURL}/${encodeURIComponent(fleetcode)}/get_content`
     const response= await super.httpPost<adminPDFDocumentResponse>(trmPdfURL,{DocumentId})
     const ifmDocumentResponse = response as unknown as adminPDFDocumentResponse
    return ifmDocumentResponse;
  }
}
