import {v4 as uuidv4} from 'uuid'
import isEmpty from 'lodash/isEmpty'
import {DocumentLink, Item, Package, PackageItemTypes, Section} from './PackageService'
import PackageTree, {PackageTreeItem} from './PackageTree'

export const fromPackageItem = (item: Item, parentId: string): PackageTreeItem =>
{
  const id = uuidv4()
  let type
  if (!isEmpty(item.$type)) {
    type = item.$type
  } else {
    type = Array.isArray((item as Section).Items)
      ? PackageItemTypes.section
      : PackageItemTypes.documentLink
  }
  let items
  if (type === PackageItemTypes.section) {
    items = [...((item as Section).Items)].map(i => fromPackageItem(i, id))
  }
  let documentId
  if (!isEmpty((item as DocumentLink).DocumentId)) {
    documentId = (item as DocumentLink).DocumentId
  }
  return new PackageTreeItem(id, item.Title, type as PackageItemTypes, documentId, items, parentId)
}

export const fromPackage = (pkg: Package): PackageTree =>
{
  return new PackageTree(pkg.Id, pkg.Title, pkg.Items?.map(i => fromPackageItem(i, pkg.Id)), pkg.RevisionTimestamp)
}

export const fromPackageTreeItem = (pkgTreeItem: PackageTreeItem): Item =>
{
  const item: Partial<Item> = {
    Title: pkgTreeItem.title,
    $type: pkgTreeItem.type
  }
  if (pkgTreeItem.isSection()) {
    (item as Section).Items = []
    if (pkgTreeItem.hasItems()) {
      for (const childItem of (pkgTreeItem.items as PackageTreeItem[])) {
        (item as Section).Items.push(fromPackageTreeItem(childItem))
      }
    }
  }
  if (pkgTreeItem.isDocumentLink()) {
    (item as DocumentLink).DocumentId = pkgTreeItem.documentId as string
  }
  return item as Item
}

export const fromPackageTree = (pkgTree: PackageTree): Package =>
  ({

    Id: pkgTree.id,
    Title: pkgTree.title,
    Items: pkgTree.items.map(fromPackageTreeItem),
    RevisionTimestamp: pkgTree.revisionTimestamp
  })
