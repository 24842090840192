import React, {FC, useCallback, useMemo, useState} from 'react';
import Grid from '@mui/material/Grid'
import {DataGrid, GridColDef, GridRowParams} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography'
import { OrionButton } from '@orion/orion-design-system-react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Modal from '../common/CustomModal'
import useVendors from './useVendors'
import VendorService, { Vendor } from './VendorService'
import PackageAssignmentService from '../package/PackageAssignmentService'
import usePackageAssignment from '../package/usePackageAssignment'
import logger from '../common/logger'
import Box from '@mui/material/Box'
import Loading from '../common/Loading'
import { useSessionAuth } from '../auth/AuthContext';

const TableLoading = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <Loading size="2em" />
  </Box>
)

const NoResults = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <Typography>No vendors assigned</Typography>
  </Box>
)

export interface VendorAssignmentModalProps
{
  vendorService: VendorService
  packageAssignmentService: PackageAssignmentService
  packageId: string
  open: boolean
  onClose: () => void
}

const VendorAssignmentModal: FC<VendorAssignmentModalProps> = ({
  open,
  onClose,
  packageId,
  packageAssignmentService,
  vendorService
}) =>
{
  const log = logger.child({ component: 'VendorAssignmentModal' })
  const { assign, unAssign } = usePackageAssignment(packageAssignmentService)
  const { authSessionInfo } = useSessionAuth()

  // Vendor Search
  const [vendorSearchTerms, setVendorSearchTerms] = useState('')
  const { vendors: vendorSearchResults, isLoadingVendors } = useVendors(vendorService, vendorSearchTerms)
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null)

  // Get Vendors assigned to a Package
  const [reloadAssignedVendors, setReloadAssignedVendors] = useState(false)
  const { vendors: assignedVendors } = useVendors(
    vendorService,
    undefined,
    undefined,
    undefined,
    packageId,
    reloadAssignedVendors
  )

  // Package and Vendor assignment
  const handleVendorAssigned = useCallback(() =>
  {
    if (!selectedVendor) {
      log.trace({ selectedVendor }, 'no selected vendor for assignment')
      return
    }
    log.trace({ packageId, vendorId: selectedVendor.Id }, 'assigning vendor and package')
    assign(packageId, selectedVendor.Id)
      .then(() =>
      {
        setReloadAssignedVendors(val => !val)
      })
  }, [assign, selectedVendor, assignedVendors, vendorSearchResults])
  
  const handleVendorUnassigned = useCallback(({id}: GridRowParams) => {
    log.trace({ packageId, vendorId: id }, 'unassigning vendor from package')
    unAssign(packageId, id as string)
      .then(() => {
        setReloadAssignedVendors(val => !val)
      })
  }, [packageId, unAssign])
  
  const assignedVendorColumns: GridColDef[] = useMemo(() => [
    { field: 'Id' },
    { field: 'Name', sortable: true, flex: 2 },
    {
      field: 'actions',
      type: 'actions',
      getActions: params => [
        <OrionButton key='unassign-vendor' variant='tertiary' icon-name='close' onClick={() => handleVendorUnassigned(params)} />
      ]
    }
  ], [packageId, assign, unAssign])
  
  return (
    <Modal
      open={open}
      onClose={onClose}
      name="vendor-assignment"
      title="Edit vendor assignment"
    >
      <Grid container sx={{ p: 2 }}>
        <Grid item>
          <Typography component="h3" variant="body1" sx={{ pb: 1 }}>
            Assign new vendor
          </Typography>
        </Grid>
        <Grid container alignItems="center">
          <Grid width="75%" item>
            <Autocomplete
              disablePortal
              id="vendor-assignment-autocomplete"
              renderInput={(params) => <TextField {...params} label="Enter vendor name" size="small" />}
              options={vendorSearchResults}
              getOptionLabel={option => option.Name}
              loading={isLoadingVendors}
              isOptionEqualToValue={(option: Vendor, value: Vendor) => option.Id === value.Id}
              value={selectedVendor}
              inputValue={vendorSearchTerms}
              onChange={(_event, vendor: Vendor | null) =>
              {
                if (vendor) setSelectedVendor(vendor)
              }}
              onInputChange={(_event: unknown, value: string) =>
              {
                setVendorSearchTerms(value)
              }}
              noOptionsText="No Vendors"
            />
          </Grid>
         {authSessionInfo && authSessionInfo?.userRole?.toLocaleLowerCase()==='admin' && <Grid width="25%" item sx={{ pl: 2 }}>
            <OrionButton
              variant="primary"
              onClick={handleVendorAssigned}
              {...(!selectedVendor?{disabled:true}:{})}
            >
              Assign
            </OrionButton>
          </Grid>
          }
        </Grid>
        <Grid item>
          <Typography component="h3" variant="body1" sx={{ pb: 1 }}>
            Assigned vendors
          </Typography>
        </Grid>
        <Grid container sx={{ pb: 2, maxHeight:'40vh', overflowY:'auto' }}>
          <DataGrid
            columns={assignedVendorColumns}
            rows={assignedVendors}
            loading={isLoadingVendors}
            getRowId={row => row.Id}
            autoHeight={true}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  Id: false
                }
              }
            }}
            slots={{
              loadingOverlay: TableLoading,
              noRowsOverlay: NoResults
            }}
            disableColumnSelector
            disableRowSelectionOnClick
            hideFooter
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

export default VendorAssignmentModal
