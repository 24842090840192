import {create} from 'zustand'
import {devtools } from 'zustand/middleware'
import {immer} from 'zustand/middleware/immer'
import {Document} from './DocumentService'

export interface DocumentState
{
  documents: Record<string, Document>
}

export interface DocumentActions
{
  setDocuments: (docs: Document[]) => void
}

const useDocumentStore = create<DocumentState & DocumentActions>()(
  devtools(
    immer(set => ({
      documents: {},
      setDocuments: payload =>
        set(state =>
        { 
          if(payload) {
            for (const doc of payload) {
              if (!state.documents[doc.Id]) {
                state.documents[doc.Id] = doc
              }
            }
          }
          else {
            console.log("no data");
          }
        })
    }))
  )
)

export default useDocumentStore
