import React, { useEffect, useState, useRef } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress
} from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import Menu from './Menu'
import { styled } from '@mui/material'
import { OrionButton } from '@orion/orion-design-system-react'
import Typography from '@mui/material/Typography'

const Button = styled(OrionButton)(({ theme }) => ({
  paddingLeft: theme.spacing(1)
}))

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255,0.7)',
  zIndex: 1
})

/* eslint-disable @typescript-eslint/no-explicit-any */
interface DataTableProps {
  data: any[]
  columns: { id: string; label: string; isSort: boolean; width?: string; isClickableCellLink?: boolean }[]
  rowsPerPageOptions?: number[] | null
  menuItems?: { label: string; onClick: (rowData: any) => void }[]
  onRowClick?: (rowValues: any) => void
  hasNextPage?: boolean
  setOffset?: (value: number) => void
  offset?: number
  setRowsPerPage?: (value: number) => void
  rowsPerPage?: number
  isLoading: boolean
  setSortMethod?: (value: string) => void
  isFrom?: string
  isClickableRowLink?: boolean
  disablePrevBtn?: boolean
}

const DataTable: React.FC<DataTableProps> = ({
  data,
  columns,
  rowsPerPageOptions,
  menuItems,
  onRowClick,
  hasNextPage,
  setOffset,
  offset,
  setRowsPerPage,
  rowsPerPage,
  isLoading,
  setSortMethod,
  isFrom,
  isClickableRowLink,
  disablePrevBtn = false
}) => {
  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const handleSort = (columnId: string) => {
    const column = columns.find(col => col.id === columnId)
    if (column && column?.isSort) {
      const isAsc = orderBy === columnId && order === 'asc'
      const reqSort = isAsc ? 'desc' : 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(columnId)
      const reqColumnId = columnId?.toLocaleLowerCase() === 'modifiedtimestamp' ? 'modified' : columnId
      setSortMethod && setSortMethod(`${reqColumnId?.toUpperCase()}_${reqSort?.toUpperCase()}`)
    }
  }

  // const sortedData = (reqData:any[]) => [...reqData].sort((a, b) =>
  // {
  //   if (orderBy) {
  //     const column = columns.find((col) => col.id === orderBy)
  //     if (column && column?.isSort) {
  //       const comparison = a[orderBy] > b[orderBy] ? 1 : -1
  //       return order === 'asc' ? comparison : -comparison
  //     }
  //   }
  //   return 0
  // })

  const NoResults = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100px',
        border: '0px'
      }}
    >
      {isFrom === 'viewer' ? (
        <Typography>
          There are currently no packages assigned to you, if you are expecting to see packages then please reach out to
          technicaldatamangement@united.com regarding Support Data missing packages.
        </Typography>
      ) : (
        <Typography>No data found!</Typography>
      )}
    </Box>
  )

  // const getProcessedData = (respData:any[]) => {
  //   const page =  Number((offset&&rowsPerPage)?(offset/rowsPerPage):0);
  //   return rowsPerPage? sortedData(respData)?.slice(page * rowsPerPage,  page* rowsPerPage + rowsPerPage) : sortedData(respData);
  // }

  const headerCellStyle: React.CSSProperties = {
    position: 'sticky',
    top: 0,
    background: '#f0f0f0',
    zIndex: 1,
    cursor: 'pointer'
  }

  const calculateDynamicColumnStyle = (width: string, isSort: boolean) => ({
    ...headerCellStyle,
    width,
    cursor: isSort ? 'pointer' : 'default'
  })

  const handlePreviousPage = () => {
    if (offset && offset > 0 && setOffset) {
      setOffset(Math.max(offset - (rowsPerPage ? rowsPerPage : 0), 0))
    }
  }

  const handleNextPage = () => {
    if (hasNextPage && setOffset && offset !== undefined && offset !== null) {
      setOffset(offset + (rowsPerPage ? rowsPerPage : 0))
    }
  }
  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage && setRowsPerPage(event.target.value as number)
    setOffset && setOffset(0) // Reset offset when rows per page change
  }

  const [totalHeight, setTotalHeight] = useState<string>('auto')
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([])

  useEffect(() => {
    const handleResize = () => {
      const heights = rowRefs.current
        .map(ref => (ref ? ref.getBoundingClientRect()?.height : 0))
        .filter(height => height)
      const totalHeight = heights.reduce((acc, height) => acc + height, 0)

      const headerHeight = document.getElementById('orionHeader')?.offsetHeight || 0

      const subHeaderHeight = isFrom === 'viewer' ? 150 : 250

      const viewportHeight = window.innerHeight - headerHeight - subHeaderHeight

      if (totalHeight > viewportHeight || data?.length > 0) {
        setTotalHeight(`${viewportHeight}px`)
      } else {
        setTotalHeight('auto')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [data.length, rowsPerPage])

  return (
    <Box position={'relative'}>
      {isLoading && (
        <Overlay>
          <CircularProgress />
        </Overlay>
      )}
      <TableContainer
        component={Paper}
        sx={{ height: totalHeight, maxHeight: '790px', overflow: 'auto', marginTop: '10px' }}
      >
        <Table size='medium'>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  onClick={() => handleSort(column.id)}
                  style={column?.width ? calculateDynamicColumnStyle(column?.width, column?.isSort) : headerCellStyle}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {column.label}
                    {orderBy === column.id && column?.isSort && (order === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />)}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data.map((row, index) => (
                <TableRow
                  key={index}
                  ref={el => (rowRefs.current[index] = el)}
                  onClick={() => (isClickableRowLink ? onRowClick && onRowClick(row) : null)}
                  sx={{ ...(isClickableRowLink && { cursor: 'pointer' }) }}
                >
                  {columns.map((column, index) => (
                    <TableCell
                      key={`${column.id}-${index}`}
                      onClick={() => (column?.isClickableCellLink ? onRowClick && onRowClick(row) : null)}
                      sx={{
                        ...(column?.isClickableCellLink && {
                          color: '#6244BB',
                          cursor: 'pointer'
                        })
                      }}
                    >
                      {column.id === 'menu' && menuItems ? (
                        <Menu icon='more_menu_horizontal' items={menuItems} data={row} />
                      ) : (
                        row[column.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={3} align='center' style={{ border: 'none', height: '300px', textAlign: 'center' }}>
                {NoResults()}
              </TableCell>
            )}
          </TableBody>
        </Table>

        {/*       
      {rowsPerPage && rowsPerPageOptions &&  <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) =>
        {
          setRowsPerPage(parseInt(event.target.value, 10))
          setPage(0)
        }}
        style={{
          position: 'sticky',
          bottom: 0,
          background: '#f0f0f0',
          zIndex: 1
        }}
      />} */}
      </TableContainer>

      {rowsPerPage && !(offset === 0 && !hasNextPage && data?.length <= rowsPerPage) && (
        <Box display='flex' justifyContent='flex-end' gap='10px' mt={2} id='paginationId'>
          {!disablePrevBtn && (
            <>
              {' '}
              <Button variant='secondary' onClick={handlePreviousPage} {...(offset === 0 ? { disabled: true } : {})}>
                Previous
              </Button>
              <FormControl variant='outlined' style={{ minWidth: 120, marginLeft: '8px' }}>
                <InputLabel id='rows-per-page-label'>Rows per page</InputLabel>
                <Select
                  labelId='rows-per-page-label'
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  label='Rows per page'
                  style={{ height: '40px', paddingRight: '8px' }}
                >
                  {rowsPerPageOptions?.map(size => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <Button variant='secondary' onClick={handleNextPage} {...(!hasNextPage ? { disabled: true } : {})}>
            Next
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default DataTable
