import { Logger } from '../common/logger'
import { ApiConfig } from '../config/config'
import HttpClient from '../common/HttpClient'
import Service from '../common/Service'

export interface ReportIdProps {
  ReportId:string
}
export interface PresignedURLProps {
  PresignedURL:string
}

export interface Vendor
{
  Id: string
  Name: string
  SyncTimestamp: string
  NumAssignedPackages: number
}

export interface ListVendorsResponse
{
  Offset: number
  Limit: number
  HasNextPage: boolean
  Vendors: Vendor[]
}

export default class VendorService extends Service<Vendor|ReportIdProps|PresignedURLProps>
{
  constructor(
    logger: Logger,
    httpClient: HttpClient,
    private readonly config: ApiConfig
  )
  {
    super(logger, httpClient, 'VendorService')
  }

  async list({ offset, limit=100, nameFilter, forUserId, forPackageId, vendorId }: {
    offset?: number
    limit?: number
    nameFilter?: string
    forUserId?: string
    forPackageId?: string
    vendorId?: string
  })
  {
    return super.httpGet<ListVendorsResponse>(this.config.vendorsUrl, {
      id: vendorId,
      offset,
      limit,
      nameFilter,
      assignedToUser: forUserId,
      assignedToPackage: forPackageId
    })
  }

  async startVendorPackagesReport(vendorId:string)
  {
    return super.httpPost(`${this.config.startVendorPackagesReportURL}?vendorId=${vendorId}`,{})
  }

  async downloadVendorReport(reportId:string)
  {
    return super.httpPost(`${this.config.downloadVendorReportURL}/${reportId}`,{})
  }

}
