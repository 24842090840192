import React, {FC, useState, useRef} from 'react'
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Box from '@mui/material/Box'
import Loading from '../common/Loading'


interface VendorPDFViewerProps
{
  preSignedURL:string;
  title:string|undefined;
}

const Spinner = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <Loading size="3em" />
  </Box>
)

const VendorPDFViewer:FC<VendorPDFViewerProps> = ({preSignedURL, title}) => {

  const [pdfWorkerLoading, setPdfWorkerLoading] = useState<boolean>(false);

  const viewerRef = useRef<HTMLDivElement|null>(null)

  const customDownload = () => {
    if (viewerRef.current && !pdfWorkerLoading) {
        const downloadButton = viewerRef.current.querySelector('.rpv-toolbar__right [aria-label="Download"]');
        
        if (downloadButton) {
            
            const newButton = downloadButton.cloneNode(true) as HTMLElement;
            downloadButton.replaceWith(newButton);
            newButton.addEventListener('click', (event:Event) => {
                event.preventDefault(); // Prevent the default download behavior
                console.log('Download button clicked');
  
                if (preSignedURL) {
                    fetch(preSignedURL)
                        .then((response) => response.blob())
                        .then((blob) => {
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = `${title}.pdf`; // Custom filename
                            link.click();
                            document.body.removeChild(link);
                            window.URL.revokeObjectURL(link.href);
                        })
                        .catch((error) => console.error('Error downloading the PDF:', error));
                }
            });
        } 
    }
  };
  



  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleDocumentLoad = () => {
        const { activateTab } = defaultLayoutPluginInstance;
        setPdfWorkerLoading(false);
        activateTab(1);
        customDownload();
  };


    return <>
      {
            pdfWorkerLoading?
              <Spinner/>
            :null
          }
      
   { preSignedURL && (
// This must be the same version as the package installed
<Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js">
<div
    ref={viewerRef}
    style={{
        border: '1px solid rgba(0, 0, 0, 0.3)',
        display: 'flex',
        height: '650px',  
        overflow:'auto',
        width:'100%'
      }}>
        
<div  style={{ flex: 1 }}>
<Viewer fileUrl={preSignedURL} plugins={[defaultLayoutPluginInstance]} onDocumentLoad={handleDocumentLoad}  defaultScale={SpecialZoomLevel.PageWidth} />
</div>
</div>
</Worker>
)
    }
</>
};

export default VendorPDFViewer;