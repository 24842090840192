import React, { FC } from "react";
import Box from "@mui/material/Box";
import { OrionHeader, OrionNavButton } from "@orion/orion-design-system-react";
import { Location } from "react-router-dom";
import { User } from "../auth/AuthService";

interface HeaderProps {
  headingText: string;
  refreshed: string;
  user: User | undefined;
  isAuthorizing: boolean;
  location: Location;
  handleLogout:()=>void;
}

const AppHeader: FC<HeaderProps> = ({
  headingText,
  refreshed,
  user,
  isAuthorizing,
  location,
  handleLogout
}) => {


  const excludePaths = ["/trm", "/trmadmin", "/xref", "/xrefadmin"];


  return (
    <Box
      sx={
        location?.pathname === "/pdfViewer"
          ? { height: "0px" }
          : { height: "80px" }
      }
      id={"orionHeader"}
    >
      {location?.pathname === "/pdfViewer" ? null : (
        <OrionHeader heading={headingText} {...(!excludePaths?.includes(location.pathname) && {logoHref:'/'})}>
          <span slot="orion-header-right" style={{ pointerEvents: "none" }}>
            <OrionNavButton icon-name="refresh" label-position="left">
              Last refreshed <br />
              {refreshed}
            </OrionNavButton>
            {!isAuthorizing && (
              <OrionNavButton icon-name="traveler" label-position="right">
                {user?.firstName || 'UNKOWN'}
                <br />
                {user?.lastName || 'UNKOWN'}
              </OrionNavButton>
            )}
          </span>
          <span slot="orion-header-right" style={{ cursor: 'pointer',marginLeft:'10px', marginRight:'10px'}}>
          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
          <div onClick={handleLogout} style={{cursor:'pointer', fontWeight:500}}>
                Logout
          </div>
        </span> 
        </OrionHeader>
      )}
    </Box>
  );
};

export default AppHeader;
