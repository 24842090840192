import React, {useEffect, useState} from 'react'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Box from '@mui/material/Box'
import Loading from '../common/Loading'
import CustomError from '../common/CustomError'
import useMessaging from '../messaging/useMessaging'
import DocumentService, {Document} from '../document/DocumentService'
import logger from '../common/logger'
import HttpClient from '../common/HttpClient'
import useServiceAuth from '../common/useServiceAuth'
import { getEnv } from '../package/utils';
import { useLocation } from 'react-router-dom';
import PDFWorker from '../common/PDFWorker';


const Spinner = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <Loading size="3em" />
  </Box>
)
const apiHttpClient = new HttpClient(logger, getEnv()?.api.baseUrl)
const documentService = new DocumentService(logger, apiHttpClient, getEnv()?.api)

const LightViewer = () => {

  const [pdfData, setPdfData] = useState<string|null|undefined>(null);
  const [pdfWorkerLoading, setPdfWorkerLoading] = useState<boolean>(false);
  const { sendP1ErrorMessage } = useMessaging();
  const { waitingOnAuth } = useServiceAuth<Document>(documentService);
  const params=new URLSearchParams(useLocation().search)
  const documentId=params.get('documentId')
  const title=params.get('title')
  const userRole=params.get('userRole') || undefined
  const packageId=params.get('packageId')||undefined
  const noseNumber=params.get('noseNumber')||undefined
  const page=params.get('component')||undefined
  const fleetcode=params.get('fleetcode')||undefined

  
  useEffect(() => {
    if(page?.toLowerCase() === "ifm"){

      if (documentId && title) {
        if(waitingOnAuth) return;
        setPdfWorkerLoading(true);
        setPdfData(null);
        (async () => {
          try{
            if(userRole || noseNumber) {
              const response = await documentService.getIfmDocument(
                documentId,
                userRole,
                noseNumber
              );
              if(response?.Message || response?.error_message)
              {
                const errorMessage = response?.Message || response?.error_message || '';
                sendP1ErrorMessage(errorMessage, 'Error Fecthing PDF Document');
              }
              setPdfData(response?.PresignedURL);
              setPdfWorkerLoading(false);
            }
          }
          catch(error) {
            sendP1ErrorMessage((error as CustomError).message, 'Error Fecthing PDF Document');
            setPdfWorkerLoading(false);
            // onClose && onClose();
          }

        })()
        
      }
    }
    if(page?.toLowerCase() === "trm"){
      if (documentId && fleetcode) {
        if(waitingOnAuth) return;
        setPdfWorkerLoading(true);
        setPdfData(null);
        (async () => {
          try{
              const response = await documentService.getTRMDocument(
                documentId,
                fleetcode
              );
              if(response?.Message || response?.error_message)
              {
                const errorMessage = response?.Message || response?.error_message || '';
                sendP1ErrorMessage(errorMessage, 'Error Fecthing PDF Document');
              }
              setPdfData(response?.PresignedURL);
              setPdfWorkerLoading(false);
            }
          catch(error) {
            sendP1ErrorMessage((error as CustomError).message, 'Error Fecthing PDF Document');
            setPdfWorkerLoading(false);
            // onClose && onClose();
          }

        })()
        
      }
    }
    else if((packageId && documentId) || (userRole?.toLowerCase()==='admin' && documentId)) {
      if (documentId && title) {
        if(waitingOnAuth) return;
        setPdfWorkerLoading(true);
        setPdfData(null);
        (async () => {
          try{
            if(userRole || packageId) {
              const response = await documentService.getPDFDocument(
                documentId,
                userRole,
                packageId
              );
              if(response?.Message || response?.error_message)
              {
                const errorMessage = response?.Message || response?.error_message || '';
                sendP1ErrorMessage(errorMessage, 'Error Fecthing PDF Document');
              }
              setPdfData(response?.PresignedURL);
              setPdfWorkerLoading(false);
            }
          }
          catch(error) {
            sendP1ErrorMessage((error as CustomError).message, 'Error Fecthing PDF Document');
            setPdfWorkerLoading(false);
            // onClose && onClose();
          }

        })()
        
      }
    }
    }, [title, documentId])

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleDocumentLoad = () => {
      const { activateTab } = defaultLayoutPluginInstance;
      setPdfWorkerLoading(false);
          activateTab(1);
  };


    return <>
       {
            pdfWorkerLoading?
              <Spinner/>
            :null
          }
   { pdfData && <PDFWorker isFromLightViewer={true}  pdfData={pdfData} handleDocumentLoad={handleDocumentLoad}/>} 
</>
};

export default LightViewer;