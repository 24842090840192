import React, { FC, useMemo } from 'react'

import AutoCompletePackageSelection from './AutoCompletePackageSelection'

interface vendorsListType {
    Id: string
    Name: string
    SyncTimestamp: string
    NumAssignedPackages: number
}

export interface VendorViewAdminProps
{
  vendorsList: vendorsListType[]
  handleChange:(selectedVal:vendorsListType|undefined) => void
  setVendorSearchVal:(newVal:string)=>void
  selectedVendorId:string|undefined
}

const VendorViewAdmin:FC<VendorViewAdminProps> = ({vendorsList, handleChange, setVendorSearchVal, selectedVendorId}) => {

    const memoizedVendorViewAdmin = useMemo(() => {
        return <> 
        <AutoCompletePackageSelection selectedVendorId={selectedVendorId} vendors={vendorsList} handleChange={handleChange} setVendorSearchVal={setVendorSearchVal}/>
    </>
    },[vendorsList]);

    return memoizedVendorViewAdmin;
    
}

export default VendorViewAdmin