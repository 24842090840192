import React, { FC, useCallback, useState } from 'react'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'
import flow from 'lodash/flow'
import DataTable from '../common/DataTable'
import PackageSearchBar from './SearchBar'
import PackageService, { Package, PackageListItem } from '../package/PackageService'
import usePackages from './usePackages'
import usePackage from './usePackage'
import ConfirmModal from './ConfirmModal'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import logger from '../common/logger'
import { formatDates } from './utils'
import ReviewHistoryModal from './ReviewHistoryModal'
import AuditService from '../audit/AuditService'
import VendorAssignmentModal from '../vendor/VendorAssignmentModal'
import VendorService from '../vendor/VendorService'
import PackageAssignmentService from './PackageAssignmentService'
import useVendors from "../vendor/useVendors";
import useMessaging from '../messaging/useMessaging'
import { useSessionAuth } from '../auth/AuthContext'

const sortAndFormatDates = flow([formatDates])

const columns = [
  { id: 'Title', label: 'Title', isSort: true, width: '70%' },
  { id: 'ModifiedTimestamp', label: 'Last Updated', isSort: true, width: '20%' },
  { id: 'menu', label: '', isSort: false, width: '10%' }
]

export interface PackageProps
{
  vendorService: VendorService
  auditService: AuditService
  packageService: PackageService
  packageAssignmentService: PackageAssignmentService
}

const PackageSection: FC<PackageProps> = ({
  auditService,
  packageService,
  packageAssignmentService,
  vendorService
}) =>
{
  const rowsPerPageOptions = [10, 20, 50];
  const log = logger.child({ component: 'PackageSection' })
  const navigate = useNavigate()
  const [searchVal, setSearchVal] = useState('')
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
  const [isReviewHistoryModalOpen, setIsReviewHistoryModalOpen] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState<PackageListItem | undefined>(undefined)
  const { sendSuccessMessage, sendP3ErrorMessage } = useMessaging()
  const [offset, setOffset] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
  const [sortMethod, setSortMethod] = useState('TITLE_ASC');
  const { packages, searchPackages, isLoadingPackages, hasNextPage } = usePackages(packageService, searchVal, offset, rowsPerPage, sortMethod)
  const { authSessionInfo } = useSessionAuth()
  
  const {
    createPkg,
    updatePkg,
    getPkg,
    deletePkg
  } = usePackage(packageService)

  const { searchVendors } = useVendors(vendorService)

  const handleDuplicateCreatePackage = (pkg: PackageListItem) =>
  {
    setIsConfirmModalOpen(true)
    setSelectedRowData(pkg)
  }

  const handleDeletePackage = (pkg: PackageListItem) =>
    {
      setIsConfirmDeleteModalOpen(true)
      setSelectedRowData(pkg)
    }

  const handleOkBtn = useCallback(() =>
  {
    setIsConfirmModalOpen(false)
    if (!selectedRowData?.Title || !selectedRowData?.Id) return
    (async () =>
    {
      const newPkg = await createPkg(selectedRowData.Title)
      const target = await getPkg(selectedRowData.Id)
      if (!newPkg?.Id || !target) {
        log.error({ selectedRowData }, 'error duplicating Package')
        return
      }
      await updatePkg({
        ...target,
        Title: `${target.Title} (duplicated)`,
        Id: newPkg.Id
      } as Package)
      searchPackages()
    })()
  }, [selectedRowData, createPkg, getPkg, searchPackages, updatePkg])

  const [isVendorAssignmentModalOpen, setIsVendorAssignmentModalOpen] = useState(false)
  const [packageId, setPackageId] = useState<string | null>(null)
  const handleEditVendorAssignments = useCallback((data: PackageListItem) =>
  {
    log.trace('handleEditVendorAssignments()')
    setIsVendorAssignmentModalOpen(true)
    setPackageId(data.Id)
  }, [])

  const handleCloseBtn = () =>
  {
    setIsConfirmModalOpen(false)
    setSelectedRowData(undefined)
    setIsConfirmDeleteModalOpen(false)
  }
  const handleReviewHistoryClose = () =>
  {
    setIsReviewHistoryModalOpen(false)
    setSelectedRowData(undefined)
  }

  const handleEditProperties = (data: PackageListItem) =>
  {
    navigate(`/admin/package/${data.Id}`)
  }

  const handleRevisionHistory = (pkg: PackageListItem) =>
  {
    setSelectedRowData(pkg)
    setIsReviewHistoryModalOpen(true)
  }

  const handleConfirmDelete = useCallback( () => {
    setIsConfirmDeleteModalOpen(false);
    log.trace({ selectedRowData }, 'delete package')
    if (!selectedRowData?.Id) return
    (async () =>
      {
        const response = await searchVendors(undefined, undefined, undefined, selectedRowData?.Id)
        if (!response) return
        const vendors = response.Vendors
        log.trace({ vendors }, 'fetched vendors to check if assigned')
        if (vendors && vendors.length > 0) {
          log.trace({ vendors }, 'vendors assigned, cancelling delete')
          sendP3ErrorMessage(
              'There are still vendors assigned to this package, please remove all vendors before deleting.',
              undefined,
              20000
          )
          return
        }
        log.trace('no vendors assigned, deleting package')
        if(selectedRowData){
          await deletePkg(selectedRowData?.Id)
          searchPackages()
          sendSuccessMessage(`Package successfully deleted`)
        }
      })()
  }, [selectedRowData, getPkg, searchPackages, searchVendors])

  let menuItems = [
    { label: 'Revision History', onClick: (data: PackageListItem) => handleRevisionHistory(data) }
  ]

  if(authSessionInfo && authSessionInfo?.userRole?.toLocaleLowerCase()==='auditor') { 
    menuItems = [
      { label: 'Vendor assignments', onClick: (data: PackageListItem) => handleEditVendorAssignments(data) },
      { label: 'Revision History', onClick: (data: PackageListItem) => handleRevisionHistory(data) }
    ]
  }

  else if(authSessionInfo && authSessionInfo?.userRole?.toLocaleLowerCase()==='admin') {
    menuItems = [
    { label: 'Duplicate and create new', onClick: (data: PackageListItem) => handleDuplicateCreatePackage(data) },
    { label: 'Manage package', onClick: (data: PackageListItem) => handleEditProperties(data) },
    { label: 'Vendor assignments', onClick: (data: PackageListItem) => handleEditVendorAssignments(data) }, 
    { label: 'Revision History', onClick: (data: PackageListItem) => handleRevisionHistory(data) },
    { label: 'Delete', onClick: (data: PackageListItem) =>  handleDeletePackage(data)}
  ]
  } 

  return (
    <>
    {
      <Grid item>
        <PackageSearchBar handleSearch={setSearchVal} userRole={authSessionInfo?.userRole} />
        <DataTable
          data={sortAndFormatDates([...packages])}
          columns={columns}
          rowsPerPageOptions={rowsPerPageOptions}
          menuItems={menuItems}
          hasNextPage={hasNextPage}
          setOffset={setOffset}
          offset={offset}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          isLoading={isLoadingPackages}
          setSortMethod={setSortMethod}
        />
      </Grid>
}
      {
        isConfirmModalOpen && (
          <ConfirmModal isOpen={isConfirmModalOpen} onOk={handleOkBtn} onClose={handleCloseBtn} />
        )
      }
      {
        isConfirmDeleteModalOpen && selectedRowData && (
          <ConfirmDeleteModal isOpen={isConfirmDeleteModalOpen} onOk={handleConfirmDelete} onClose={handleCloseBtn} />
        )
      }
      {
        isReviewHistoryModalOpen && selectedRowData && (
          <ReviewHistoryModal
            auditService={auditService}
            pkgId={selectedRowData.Id}
            pkgTitle={selectedRowData.Title}
            pkgLastUpdated={selectedRowData.ModifiedTimestamp}
            isOpen={isReviewHistoryModalOpen}
            onClose={handleReviewHistoryClose}
          />
        )
      }
      {typeof packageId === 'string' && packageId !== '' && packageId !== 'new' && (
        <VendorAssignmentModal
          open={isVendorAssignmentModalOpen}
          onClose={() => setIsVendorAssignmentModalOpen(false)}
          vendorService={vendorService}
          packageAssignmentService={packageAssignmentService}
          packageId={packageId}
        />
      )}
    </>
  )
}

export default PackageSection
