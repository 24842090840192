import React, { FC, useCallback, useState } from 'react'
import Grid from '@mui/material/Grid'
import { OrionButton, OrionLabel, OrionTextInput } from '@orion/orion-design-system-react'

interface ViewerPackageSearchBarProps
{
  handleSearch: (searchTerm: string) => void
}

const ViewerPackageSearchBar: FC<ViewerPackageSearchBarProps> = ({ handleSearch }) =>
{
  const [searchTerms, setSearchTerms] = useState('')
  const handleClearSearchTerms = useCallback(() =>
  {
    setSearchTerms('')
    handleSearch('')
  }, [setSearchTerms])
  const handleSetSearchTerms = useCallback((event: CustomEvent<{ value: string }>) =>
  {
    setSearchTerms(event.detail.value)
    if(event.detail.value?.trim()?.length===0) {
      handleSearch(event?.detail?.value)
    }
  }, [setSearchTerms])

  const handleEnterPress = useCallback((event:React.KeyboardEvent<HTMLDivElement>) =>
    {
      if (event && event.key?.toLowerCase() === 'enter'){
        handleSearch(searchTerms)
      }
    }, [searchTerms])

  return (
      <Grid container spacing={1} direction="row" justifyContent="space-between">
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <OrionLabel>
                Package search
              </OrionLabel>
            </Grid>
            <Grid item onKeyUp={handleEnterPress}>
              <OrionTextInput
                id="vendor-search"
                type="text"
                placeholder="Enter Keywords"
                value={searchTerms}
                onValueChanged={handleSetSearchTerms}
              />
            </Grid>
             <Grid item>
                <OrionButton
                  variant="primary"
                  onClick={()=>{handleSearch(searchTerms)}}
                  {...(searchTerms?.trim()?.length === 0?{disabled:true}:{})}
                >
                  Search
                </OrionButton>
              </Grid>
            {
            searchTerms?.trim()?.length >0 && <Grid item>
              <OrionButton variant="tertiary" onClick={handleClearSearchTerms}>
                Clear search
              </OrionButton>
            </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
  )
}

export default ViewerPackageSearchBar
