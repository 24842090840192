import React, {
    createContext,
    useContext,
    useState,
    FC,
    ReactNode,
  } from "react";
  
  // Define the shape of the context
  interface ErrorContextType {
    errorMessage: string | null;
    showError: (message: string) => void;
    clearError:()=>void;
  }
  
  // Create the context
  const ErrorContext = createContext<ErrorContextType | undefined >(undefined);
  
  // Provider component
  export const ErrorProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState < string | null>(null);
  
    const showError = (message:string) => {
        setErrorMessage(message);
    }

    const clearError = () => {
        setErrorMessage('');
    }

    return (
      <ErrorContext.Provider value={{ errorMessage, showError, clearError }}>
        {children}
      </ErrorContext.Provider>
    );
  };
  
  // Custom hook to use the ErrorContext
  export const useError = (): ErrorContextType => {
    const context = useContext(ErrorContext);
    if (!context) {
      throw new Error("useError must be used within an ErrorProvider");
    }
    return context;
  };
  