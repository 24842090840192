/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, FC } from 'react';
import styles from './TableContents.module.scss';
const innerHeight = '400px';
import cx from 'classnames';
import Icon from '../../../common/Icon/Icon';
import { Box } from '@mui/system';
export interface TableContentsProps
{
  respData:any;
  close:()=>void;
  isLoading:boolean;
  selectedTrailCallback:any;
  setSelectedDocument:any;
  setSelectedTrail:any;
  isInModal:boolean;
}

const TableContents:FC<TableContentsProps> = ({respData, close, setSelectedDocument, setSelectedTrail, selectedTrailCallback, isLoading, isInModal}) => {
    const [scrollPosition, setScrollPosition]=useState();
    const [viewerLoading, setViewerLoading] = useState(true);
    const [currentSelectedSubSections, setCurrentSelectedSubSections] = useState<any>([]);
    const [currentSelectedSections, setCurrentSelectedSections] = useState<any>([]);
    const [selectedSections, setSelectedSections] = useState<any>([]);
    const [selectedSubSections, setSelectedSubSections] = useState<any>([]);
    const [pdfWorkerLoading, setPdfWorkerLoading] = useState(true);

    const handleSectionClick = (section:any) => {
        const sections:any = [...selectedSections];
        if (sections.findIndex((s:any) => s === section) === -1) sections.push(section);
        else sections.pop(section);
         setSelectedSections([...sections]);
        setCurrentSelectedSections([...sections]);
    }
    const handleSubSectionClick = (section:any, subsection:any) => {
        const entry = section+subsection;
        const subsections:any = [...selectedSubSections];
        if (subsections.findIndex((ss:any) => ss === entry) === -1) subsections.push(entry);
        else subsections.pop(entry);
        setSelectedSubSections([...subsections]);
        setCurrentSelectedSubSections([...subsections]);
    }
    //save y positon when tale of content div scroll
    const tableofcontent = React.useRef<any>(null);
    const handleWindowScroll = () => {
        setScrollPosition(tableofcontent?.current?.scrollTop);
    }

    //set y scroll positon when load table of content if same manual
    useEffect(()=> {
        if ( respData) {
            tableofcontent.current.scrollTop=scrollPosition;
          }
          console.log(viewerLoading, pdfWorkerLoading);
    }, [respData]);

    const handleSelectedTrail = (trailSection:any, trailSubsection:any, trailDetail:any, selDetails:any)=>{
        const newTrail = [];
        if(trailSection) newTrail.push(trailSection.Title);
        if(trailSubsection) newTrail.push(trailSubsection.Title);
        if(trailDetail) newTrail.push(trailDetail.Title);

        setSelectedTrail([...newTrail]);
        selectedTrailCallback(selDetails);
    }

    const openPDFinOtherTab = (documentId:string, title:string) => {
        window.open(`/pdfViewer?documentId=${documentId}&title=${title}&packageId=${respData?.Id}`, '_blank', 'noopener noreferrer')
    }

    return (
        <>
            { respData ?
                <div className={styles.tablecontents} style={isInModal?{maxHeight: innerHeight}:{}} ref={tableofcontent} onScroll={()=>{handleWindowScroll();}}>
                    <ul style={{marginTop:"0px"}}>
                        {/* <input id='manual-toggle' className={styles['manual-toggle']} type='checkbox' />  */}
                        <li className={styles['manual-title-page']}>{respData.Title}</li>
                        <li className={styles['manual-body']}>
                        {
                            respData.Items?.length>0 ? respData.Items.map((s:any, index:number) => {
                                return (
                                    s.$type === 'documentlink'?
                                        <ul key={`${s.Title}_${index}`}>
                                            <li className={`${styles['doc-info']} ${styles['doc-first-item']}`}>
                                            {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                                            {/* eslint-disable jsx-a11y/click-events-have-key-events */} 
                                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                                <div className={styles['doc-title']} onClick={() => {setViewerLoading(true); setSelectedDocument(s); handleSelectedTrail(s, null, null, s); setPdfWorkerLoading(true); close();}}>
                                                    <div>{s.Title} - {s.Description}</div>
                                                </div>
                                                <Box onClick={()=>openPDFinOtherTab(s.DocumentId, s.Title)}>
                                                    <Icon variant='popout' width='30px' height='40px' clickable='true' />
                                                </Box>
                                            </Box>    
                                            </li>
                                        </ul>
                                    :
                                    <ul key={`${s.Title}_${index}`}>
                                        {/* eslint-disable  jsx-a11y/no-noninteractive-element-interactions */}
                                        {/* eslint-disable jsx-a11y/click-events-have-key-events */} 
                                        <li className={styles['manual-sec-title']} onClick={() => handleSectionClick(s.Title)}>
                                            <div>{s.Title}</div><div className={selectedSections.findIndex((sel:any) => sel === s.Title) === -1 && currentSelectedSections.findIndex((sel:any)=>sel ===s.Title) === -1 ? styles['right-arrow'] : styles.arrow}></div>
                                        </li>
                                        <li className={cx(styles['manual-sec-body'], {[styles['hide-section']]: selectedSections.findIndex((sel:any) => sel === s.Title) === -1 && currentSelectedSections.findIndex((sel:any)=>sel ===s.Title) === -1})}>
                                        {
                                            s.Items && s.Items.map((ss:any, ssi:any) => {
                                                return (
                                                    ss.$type === 'documentlink' ?
                                                        <ul key={`${s.Title}_${ssi}`}>
                                                            <li className={`${styles['doc-info']} ${styles['doc-first-item']}`}>
                                                                {/* eslint-disable jsx-a11y/no-static-element-interactions */}  
                                                                {/* eslint-disable jsx-a11y/click-events-have-key-events */} 
                                                            <Box sx={{display:'flex', justifyContent:'space-between'}}>

                                                                <div className={styles['doc-title']} onClick={() => {setViewerLoading(true); setSelectedDocument(ss); handleSelectedTrail(s, ss, null, ss); setPdfWorkerLoading(true); close();}}>
                                                                    <div>{ss.Title} - {ss.Description}</div>
                                                                </div>
                                                                <Box onClick={()=>openPDFinOtherTab(ss.DocumentId, ss.Title)}>
                                                                    <Icon variant='popout' width='30px' height='40px' clickable='true' />
                                                                </Box>
                                                            </Box>    
                                                            </li>
                                                        </ul>
                                                    :
                                                    <ul key={`${s.Title}_${ssi}`}>
                                                        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                                                        {/* eslint-disable jsx-a11y/click-events-have-key-events */} 
                                                        <li className={styles['manual-sub-sec-title']} onClick={() => handleSubSectionClick(s.Title, ss.Title)}>
                                                            <div className={styles.Title}>{ss.Title}</div><div className={selectedSubSections.findIndex((sel:any) => sel === s.Title+ss.Title) === -1 && currentSelectedSubSections.findIndex((sel:any)=> sel===s.Title+ss.Title) === -1 ? styles['right-arrow'] : styles.arrow}></div>
                                                        </li>
                                                        {
                                                            ss.Items ? ss.Items.map((d:any, i:number) => {
                                                                return(
                                                                    d.$type === 'documentlink' ?
                                                                        <li key={`${s.Title}_${ssi}_${i}`} className={cx(styles['doc-info'], {[styles['hide-section']]: selectedSubSections.findIndex((sel:any) => sel === s.Title+ss.Title) === -1 && currentSelectedSubSections.findIndex((sel:any)=> sel===s.Title+ss.Title) === -1})}>
                                                                            {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                                                                            {/* eslint-disable jsx-a11y/click-events-have-key-events */} 
                                                                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                                                            <div className={styles['doc-title']} onClick={() => {setViewerLoading(true); setSelectedDocument(d); handleSelectedTrail(s, ss, d, d); setPdfWorkerLoading(true); close();}}>
                                                                                <div>{d.Title} - {d.Description}</div>
                                                                            </div>
                                                                                <Box onClick={()=>openPDFinOtherTab(d.DocumentId, d.Title)}>
                                                                                    <Icon variant='popout' width='30px' height='40px' clickable='true' />
                                                                                </Box>
                                                                            </Box>
                                                                        </li>
                                                                    :
                                                                        null
                                                                )
                                                            }) :
                                                            null
                                                        }
                                                    </ul>
                                                )
                                            })
                                        }
                                        </li>
                                    </ul>
                                )
                            })
                        : <div style={{textAlign:'center', marginTop:'20px'}}>No data found!</div>
                        }

                        </li>
                    </ul>
                </div>
            : isLoading?
                <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                    <div className={styles.spinner}></div>
                </div>
                : <div style={{height: innerHeight}} className={`${styles.tablecontents} ${styles.nodatafound}`}>
                    No data found!
                </div>
            }
        </>
    )
}

export default TableContents;
