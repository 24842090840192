import { Logger } from "../common/logger";
import { ApiConfig } from '../config/config'
import HttpClient from '../common/HttpClient'
import Service from '../common/Service'



export interface TitleParts
{
    Label:string,
    Value:string
}
export interface Document
{
    Id:string,
    Text:string,
    Description:string
}
export interface BuildStandard
{
    Id:string,
    Text:string,
    Description:string
}
export interface Items
{
    BuildStandard:BuildStandard
    Name:string,
    Document:Document  
   
}
export interface Sections
{
    Name:string,
    Items:Items[]
}

export interface IFMResponse
{
    TitleParts:TitleParts[],
    Sections:Sections[],
    error_message?:string

}

export default class IFMService extends Service<IFMResponse>
{
    constructor(
        loger:Logger,
        httpClient: HttpClient,
        private readonly config: ApiConfig,
    )
    {
        super(loger,httpClient,'IFMService')
    }
    async ifmDataList(noseNumber:string){
        return   super.httpGet<IFMResponse>(`${this.config.ifmUserUrl}${noseNumber}`)
         
    }
}