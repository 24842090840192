import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState
} from 'react'
import Box from '@mui/material/Box'
import {
  DataGrid,
  GridColDef,
  GridRow,
  GridRowProps,
  GridRowSelectionModel
} from '@mui/x-data-grid'
import { OrionButton, OrionTextInput } from '@orion/orion-design-system-react'
import { useDraggable } from '@dnd-kit/core'
import { formatDate } from '../common/date'
import useDocument from './useDocument'
import DocumentService, { DocumentType } from './DocumentService'
import logger from '../common/logger'
import Loading from '../common/Loading'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import {styled, InputLabel,Select,MenuItem} from "@mui/material"

const Button = styled(OrionButton)(({ theme }) => ({
  paddingLeft: theme.spacing(1)
}))

const rowsPerPageOptions = [10, 20, 50];

const documentSearchColumns: GridColDef[] = [
  { field: 'Id' },
  { field: 'Title', sortable: false, flex: 2 },
  {
    field: 'RevisionTimestamp',
    headerName: 'Rev Date',
    type: 'string',
    flex: 0.5,
    valueGetter: ({ value }) => formatDate(value),
    align: 'left',
    sortable: false
  }
]
const DraggableDocumentRow: FC<PropsWithChildren<GridRowProps>> = ({
  children,
  selectedRows,
  documentService,
  ...props
}) =>
{
  const { documents } = useDocument(documentService)
  const hasRows = selectedRows.includes(props.rowId)
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    // handle multi-select
    id: hasRows ? `document-rows-${selectedRows.join(',')}` : `document-row-${props.rowId}}`,
    data: hasRows ? selectedRows.map((id: string) => documents[id]) : [props.row]
  })
  const style = useMemo(
    () =>
      transform
        ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`
        }
        : undefined,
    [transform]
  )

  // noinspection TypeScriptValidateTypes
  return (
    <GridRow {...props} ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </GridRow>
  )
}

const TableLoading = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <Loading size="2em" />
  </Box>
)

const NoResults = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <Typography>No documents found!</Typography>
  </Box>
)

export interface DocumentSearchProps
{
  documentService: DocumentService
}

const DocumentSearch: FC<DocumentSearchProps> = ({ documentService }) =>
{
  const log = logger.child({ component: 'DocumentSearch' })
  // Search
  const [searchTerms, setSearchTerms] = useState('')
  const handleSetSearchTerms = useCallback(
    (event: CustomEvent<{ value: string }>) =>
    {
      setSearchTerms(event.detail.value)
    },
    [setSearchTerms]
  )

  const [type, setType] = useState(DocumentType.UDOCS)
  
  const {
    offset,
    setOffset,
    rowsPerPage,
    setRowsPerPage,
    handleSearch,
    hasNextPage,
    documentSearchResults,
    isSearching
  } = useDocument(documentService, searchTerms, type)

  // // Search Pagination
  // const [pageModel, setPageModel] = useState({
  //   rowsPerPage,
  //   page
  // })
  // console.log(pageModel, setPageModel, rowsPerPage);
  // const handlePageModelChange = useCallback(
  //   (model: GridPaginationModel) =>
  //   {
  //     if (hasNextPage) setPageModel(model)
  //     else setPageModel({
  //       pageSize:pageSize,
  //       page:page==0?page:page-1
  //     })
  //   },
  //   [hasNextPage, setPageModel]
  // )
  // useEffect(() =>
  // {
  //   setPage(pageModel.page)
  //   setPageSize(pageModel.pageSize)
  // }, [pageModel])
  // Selection
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const handleDocumentSelection = useCallback(
    (rowSelectionModel: GridRowSelectionModel) =>
    {
      setSelectedRows(rowSelectionModel as string[])
    },
    [setSelectedRows]
  )
  const handleEnterPress = useCallback((event: KeyboardEvent<HTMLDivElement>) =>
  {
    if (event && event.key?.toLowerCase() === 'enter'){
      setOffset(0);
      handleSearch()
    }
  }, [handleSearch])

  const handleTypeChange=  useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setType(event.target.value as DocumentType)
  }, [setType])


  const handlePreviousPage = () => {
    if (offset && offset > 0 && setOffset) {
      setOffset(Math.max(offset - (rowsPerPage?rowsPerPage:0), 0));
    }
  };

  const handleNextPage = () => {
    if (hasNextPage && setOffset && offset!==undefined && offset!==null) {
      setOffset(offset + (rowsPerPage?rowsPerPage:0));
    }
  };
/* eslint-disable @typescript-eslint/no-explicit-any */
  const handleRowsPerPageChange = (
    event: any
  ) => {
    setRowsPerPage && setRowsPerPage(event.target.value as number);
    setOffset && setOffset(0); // Reset offset when rows per page change
  };


  // noinspection TypeScriptValidateTypes
  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ py: 1 }}>
        <FormControl>
          <FormLabel id='document-type'>Type</FormLabel>
          <RadioGroup row name='document-type' value={type} onChange={handleTypeChange}>
            {
              Object.values(DocumentType).map(docType => (
                <FormControlLabel key={docType} control={<Radio />} value={docType} label={docType==='FC'?'FCD':docType} />
              ))
            }
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }} onKeyUp={handleEnterPress}>
        <Box sx={{ width: '100%' }}>
          <OrionTextInput
            id="document-search"
            type="text"
            placeholder="Enter text here"
            value={searchTerms}
            onValueChanged={handleSetSearchTerms}
          />
        </Box>
        <Box sx={{ pl: 2 }}>
          <OrionButton variant="secondary" onClick={()=>{setOffset(0);handleSearch()}} {...(isSearching?{disabled:true}:{})}>
            Search
          </OrionButton>
        </Box>
      </Box>
      <Box sx={{ pt: 2 }} id={'documentSearchPage'}>
        <DataGrid
          rows={documentSearchResults}
          checkboxSelection
          columns={documentSearchColumns}
          getRowId={row => row.Id}
          initialState={{
            columns: {
              columnVisibilityModel: {
                Id: false
              }
            }
          }}
          logger={log}
          loading={isSearching}
          onRowSelectionModelChange={handleDocumentSelection}
          slotProps={{
            row: {
              selectedRows,
              documentService
            }
          }}
          slots={{
            row: DraggableDocumentRow,
            loadingOverlay: TableLoading,
            noRowsOverlay: NoResults
          }}
          hideFooter={true}
        />
        { rowsPerPage && !(offset===0 && documentSearchResults?.length < rowsPerPage)  && 
      <Box
    display="flex"
    justifyContent="flex-end"
    gap="10px"
    mt={2}
  >
    <Button
      variant="secondary"
      onClick={handlePreviousPage}
      {...(offset === 0?{disabled:true}:{})}
    >
      Previous
    </Button>
    <FormControl variant="outlined" style={{ minWidth: 120, marginLeft:'8px' }}>
      <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
      <Select
        labelId="rows-per-page-label"
        value={rowsPerPage}
        onChange={handleRowsPerPageChange}
        label="Rows per page"
        style={{height:'40px', paddingRight:'8px'}}
      >
        {rowsPerPageOptions?.map((size) => (
          <MenuItem key={size} value={size}>
            {size}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <Button
      variant="secondary"
      onClick={handleNextPage}
      {...(!hasNextPage?{disabled:true}:{})}
    >
      Next
    </Button>
  </Box>
    }
      </Box>
    </Box>
  )
}
export default DocumentSearch
