import React, {FC} from 'react'
import Box from '@mui/material/Box'
import CircularProgress, {CircularProgressProps} from '@mui/material/CircularProgress'

const Loading: FC<CircularProgressProps> = props => (
  <Box sx={{display: 'flex', p: 1}}>
    <CircularProgress {...props} />
  </Box>
)

export default Loading
