import { useCallback, useEffect, useState } from 'react'
import logger from '../common/logger'
import usePackageStore from './usePackageStore'
import PackageService, { Package } from './PackageService'
import useServiceAuth from '../common/useServiceAuth'
import { debounce } from 'lodash'
import useServiceMessaging from '../common/useServiceMessaging'

const usePackages = (
  packageService: PackageService,
  searchString?: string,
  offset?: number,
  limit?: number,
  sortMethod?:string,
  vendorId?: string
) =>
{
  const log = logger.child({ hook: 'usePackages' })
  const packages = usePackageStore(state => state.packages)
  const setPackages = usePackageStore(state => state.setPackages)
  const [isLoadingPackages, setIsLoadingPackages] = useState(packages.length === 0)
  const [hasNextPage, setHasNextPage] = useState(false)
  const { waitingOnAuth } = useServiceAuth<Package>(packageService)

  useServiceMessaging<Package>(packageService, 'Packages Error')

  const handleError = useCallback(() => {
    setIsLoadingPackages(false)
  }, [setIsLoadingPackages])

  useEffect(() =>
  {
    packageService.addErrorHandler(handleError)
    return () => {
      packageService.removeErrorHandler(handleError)
    }
  }, [])

  const search = useCallback(() =>
  {
    if (waitingOnAuth) return

    (async () =>
    {

      log.trace({ searchString }, 'fetching packages')
      setIsLoadingPackages(true)
      const response = await packageService.list(offset, limit, searchString, sortMethod, vendorId)
      if (!response) {
        log.trace({ response }, 'no packages received')
        return
      }
      log.trace({ response }, 'packages fetched')
      setPackages(response.Packages)
      setHasNextPage(response.HasNextPage)
      setIsLoadingPackages(false)
    })()
  }, [waitingOnAuth, searchString, offset, limit, vendorId, packageService, sortMethod])


  const debouncedPackagesSearchHandler = useCallback(debounce(search, 200), [search])

  useEffect(() =>
  {
    debouncedPackagesSearchHandler()

  }, [searchString, offset, limit, sortMethod, vendorId, waitingOnAuth])

  return { packages, isLoadingPackages, hasNextPage, searchPackages: search }
}

export default usePackages
