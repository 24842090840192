import React, { FC, useState, useEffect, useMemo } from 'react';
import { useNavigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ErrorBoundary } from 'react-error-boundary';
import { formatDate } from './common/date';
import AuthService from './auth/AuthService';
import SessionInfoService from './auth/SessionInfoService';
import { runAuthFlow } from './auth/runAuthFlow';
import useSessionInfo from './auth/useSessionInfo';
import Messaging from './messaging/Messaging';
import { useSessionAuth } from './auth/AuthContext';
import useAuthStore from './auth/useAuthStore';
import AppHeader from './header/Header';
import { isEmpty } from 'lodash';
import SessionExpired from './common/SessionExpired';
import Loading from './common/Loading';
import IdleTimeoutModal from './common/IdleTimeoutModal';
import { datadogRum } from '@datadog/browser-rum';

const Spinner = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <Loading size="3em" />
  </Box>
)
interface SessionInfoType {
  Roles: string[];
  UserId: string;
  SupplierName: string | null | undefined;
}

export interface AppProps {
  authService: AuthService;
  sessionInfoService: SessionInfoService;
}

const App: FC<AppProps> = ({ authService, sessionInfoService }) => {
  const { sessionDetails } = useSessionInfo(sessionInfoService);
  const [refreshed] = useState(formatDate());
  const location = useLocation();
  const { setUserSessionInfo, authSessionInfo } = useSessionAuth();
  const navigate = useNavigate();
  const isAuthorizing = useAuthStore((state) => state.isAuthorizing);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loginError, setLoginError] = useState<string|null>(null);
  const serializedParam = useMemo(() => {
    return searchParams.toString();
  }, [searchParams]);


  const getUserRoles = () =>{
    setLoginError(null);
    const userDetais = authService?.getUserDetails();
    if (!isEmpty(userDetais)) {
      (async () => {
        try {
          const sessionInfo = (await sessionDetails()) as SessionInfoType;
          const isAdmin = sessionInfo?.Roles?.includes('Admin');
          const isAuditor = sessionInfo?.Roles?.includes('Auditor');
          const roleToDisplay = isAdmin
            ? 'Admin'
            : isAuditor
            ? 'Auditor'
            : sessionInfo?.Roles?.find((role) => role !== 'Admin') || 'User';
          setUserSessionInfo({
            userName: `${userDetais?.firstName}, ${userDetais?.lastName}`,
            userId: userDetais?.id,
            firstName:userDetais.firstName,
            lastName:userDetais.lastName,
            userRole: roleToDisplay,
            supplierName: sessionInfo?.SupplierName,
          });

          datadogRum.setUser({
            id:userDetais?.id
          })
          
          if (sessionInfo) {
            if (
              (location?.pathname?.includes('admin') && !isAdmin && !isAuditor) ||
              (!isAdmin && !isAuditor && !sessionInfo?.SupplierName)
            ) {
              navigate('/unauthorized', {
                state: { userId: authSessionInfo?.userId, supplierName: sessionInfo?.SupplierName },
              });
            } else {
              redirectToExistingPath();
            }
          }
        } catch (error) {
          console.log('Error while getting Session Info');
          setLoginError('Invalid token specified.');
          useAuthStore.getState().clear();
        }
      })();
    }
    if (searchParams.get('error_description') || searchParams.get('error')) {
      useAuthStore?.getState().clear();
      sessionStorage.clear();
      setLoginError(searchParams.get('error_description'));
    }
  };


  useEffect(() => {
    const initializeAuth = async () => {
      await runAuthFlow(authService, searchParams, setSearchParams);
    };

    if(authService && !authService?.isAuthenticated()) {
      initializeAuth();
    }
    else if(authService?.isAuthenticated()) {
      getUserRoles();
    }

  }, [authService, serializedParam]);

  const redirectToExistingPath = () => {
    const exisitngPath = sessionStorage.getItem('exist_path');
    if (exisitngPath) {
      navigate(exisitngPath);
      sessionStorage.removeItem('exist_path');
    }
  };

  const headingText =
    location.pathname === '/admin'
      ? 'Vendor Support Data Admin'
      : location?.pathname?.startsWith('/xref') && !location?.pathname?.startsWith('/xrefadmin')
      ? 'Interior Cross Reference'
      : location?.pathname === '/xrefadmin'
      ? 'Interior Cross Reference Admin'
      : location?.pathname?.startsWith('/trm') && !location?.pathname?.startsWith('/trmadmin')
      ? 'Typical Repair Manual'
      : location?.pathname === '/trmadmin'
      ? 'Typical Repair Manual Admin'
      : 'Vendor Support Data';

  if(isAuthorizing) {
    return <Spinner/>
  }
  
  const handleLogout = () => {
    authService?.logout(true);
  }



  return (
    <>
      <CssBaseline />
      <AppHeader
        headingText={headingText}
        refreshed={refreshed}
        user={{id:authSessionInfo?.userId, firstName:authSessionInfo?.firstName, lastName:authSessionInfo?.lastName}}
        isAuthorizing={isAuthorizing}
        location={location}
        handleLogout={handleLogout}
      />
      {
         loginError && <SessionExpired message={'Invalid token specified.'}/>
      }
      
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <>
            <Messaging
              message={error.message}
              heading="Error"
              variant="high"
              onClose={resetErrorBoundary}
              ttl={0}
            />
          </>
        )}
      >
        <Box sx={{ flexGrow: 1 }}>
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
              <>
                <Messaging
                  message={error.message}
                  heading="Application Error"
                  variant="high"
                  onClose={resetErrorBoundary}
                  ttl={0}
                />
                {
                  loginError && <SessionExpired message={'Invalid token specified.'} />
                }
              </>
            )}
          >
          {
            !authService?.isAuthenticated() && (isAuthorizing || authService?.isPending()) &&<Spinner/> 
          }
          {
            authService?.isAuthenticated() && authSessionInfo && authSessionInfo?.userRole && authSessionInfo?.userRole?.length>0 && <Outlet />
          } 
          {authService && <IdleTimeoutModal authService={authService} />} 
          </ErrorBoundary>
        </Box>
      </ErrorBoundary>
      <Messaging />
    </>
  );
};

export default App;
