import React, {FC, useEffect, useState} from 'react'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import Box from '@mui/material/Box'
import Loading from '../common/Loading'
import CustomError from '../common/CustomError'
import useMessaging from '../messaging/useMessaging'
import DocumentService, {Document} from '../document/DocumentService'
import logger from '../common/logger'
import HttpClient from '../common/HttpClient'
import useServiceAuth from '../common/useServiceAuth'
import { getEnv } from '../package/utils';
import styles from './PDFViewer.module.scss';
import PDFWorker from './PDFWorker';


interface PDFViewerProps
{
  documentId:string|undefined;
  title:string|undefined;
  onClose?:()=>void;
  userRole:string|undefined;
  bannerDetail?:string|undefined;
  bannerSubTitle?:string|undefined;
  packageId?:string;
  isTRMDocument?:boolean;
  fleetcode?:string;
}

const Spinner = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <Loading size="3em" />
  </Box>
)
const apiHttpClient = new HttpClient(logger, getEnv()?.api.baseUrl)
const documentService = new DocumentService(logger, apiHttpClient, getEnv()?.api)

const PDFViewer:FC<PDFViewerProps> = ({documentId, title, onClose, userRole, bannerDetail , bannerSubTitle, packageId, isTRMDocument, fleetcode}) => {

  const [pdfData, setPdfData] = useState<string|null|undefined>(null);
  const [pdfWorkerLoading, setPdfWorkerLoading] = useState<boolean>(false);
  const { sendP1ErrorMessage } = useMessaging();
  const { waitingOnAuth } = useServiceAuth<Document>(documentService);

  
    useEffect(() => {
        if ((documentId && packageId) || (documentId && userRole?.toLowerCase()==='admin') || (documentId && fleetcode)) {
          if(waitingOnAuth) return;
          setPdfWorkerLoading(true);
          setPdfData(null);
          (async () => {
            try{
                const response = (isTRMDocument && fleetcode) ?await documentService.getTRMDocument(
                  documentId,
                  fleetcode
                ):await documentService.getPDFDocument(
                  documentId,
                  userRole,
                  packageId
                );
                if(response?.Message || response?.error_message)
                {
                  const errorMessage = response?.Message || response?.error_message || '';
                  sendP1ErrorMessage(errorMessage, 'Error Fecthing PDF Document');
                }
                if(response) {
                  setPdfData(response?.PresignedURL);
                  setPdfWorkerLoading(false);
                }
                else {
                  setPdfWorkerLoading(false);
                  onClose && onClose();
                }
            }
            catch(error) {
              sendP1ErrorMessage((error as CustomError).message, 'Error Fecthing PDF Document');
              setPdfWorkerLoading(false);
              onClose && onClose();
            }

          })()
          
        }
      }, [title, documentId, fleetcode, isTRMDocument])

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleDocumentLoad = () => {
      const { activateTab } = defaultLayoutPluginInstance;
      setPdfWorkerLoading(false);
          activateTab(1);
  };


    return <>
      {
            pdfWorkerLoading?
              <Spinner/>
            :null
          }
      {
        (bannerDetail && !pdfWorkerLoading) &&
          <div className={styles.infobar}>
          <div className={styles.titlebanner}>
            <div className={styles.title}>
              {bannerSubTitle}
                <div className={styles.subjecttext}>{bannerDetail}</div>
            </div>
            <div className={styles.titlebannericon}>
            </div>

          </div>
        </div>
      }    
   { pdfData && <PDFWorker pdfData={pdfData}  handleDocumentLoad={handleDocumentLoad} />}
</>
};

export default PDFViewer;