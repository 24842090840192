import React, { FC, ReactNode, useCallback, useState } from 'react'
import { OrionTab, OrionTabs } from '@orion/orion-design-system-react'
import { OrionTabsCustomEvent } from '@orion/orion-design-system'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import VendorSection from '../vendor/VendorSection'
import PackageSection from '../package/PackageSection'
import PackageService from '../package/PackageService'
import VendorService from '../vendor/VendorService'
import AuditService from '../audit/AuditService'
import PackageAssignmentService from '../package/PackageAssignmentService'

interface TabPanelProps
{
  children?: ReactNode
  index: number
  value: number
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`support-data-tabpanel-${index}`}
    aria-labelledby={`support-data-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
)

const a11yProps = (index: number) => ({
  id: `support-data-tab-${index}`,
  'aria-controls': `support-data-tabpanel-${index}`
})

export interface HomeProps
{
  auditService: AuditService
  packageService: PackageService
  packageAssignmentService: PackageAssignmentService
  vendorService: VendorService
}

const Home: FC<HomeProps> = ({ auditService, packageService, packageAssignmentService, vendorService }) =>
{
  const [activeTab, setActiveTab] = useState(0)
  const handleTabChange = useCallback(
    (event: OrionTabsCustomEvent<{ tabIndex: number }>) =>
    {
      setActiveTab(event.detail.tabIndex)
    },
    [setActiveTab]
  )
  return (
    <Box sx={{ m: 3 }}>
      <Grid container>
        <Grid item xs>
          <Typography variant="h1">Vendor Support Data</Typography>
        </Grid>
        <Grid container>
          <Box sx={{ width: '100%', height:'76vh' }}>
            <OrionTabs active-tab={activeTab} onOrionTabSelected={handleTabChange}
                       aria-label="Support data">
              <OrionTab {...a11yProps(0)}>Vendor Packages</OrionTab>
              <OrionTab {...a11yProps(1)}>Vendors</OrionTab>
            </OrionTabs>
            <TabPanel index={0} value={activeTab}>
              <Grid item xs>
                <PackageSection {...{ auditService, packageService, packageAssignmentService, vendorService }} />
              </Grid>
            </TabPanel>
            <TabPanel index={1} value={activeTab}>
              <Grid item xs>
                <VendorSection {...{vendorService, auditService, packageAssignmentService}} />
              </Grid>
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Home
