
import React,{ FC, useState, useCallback, useEffect,  KeyboardEvent } from 'react'
import styles from './IFM.module.scss'
import {
  OrionButton,
  OrionLabel,
  OrionTextInput,
  OrionError
} from '@orion/orion-design-system-react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import useIfm from './useIfm'
import IFMService,{IFMResponse} from './IFMService'
import IFMSection from './IFMSection'
import Loading from '../common/Loading'
import { useParams, useNavigate } from 'react-router-dom'

export interface IFM {
  IfmService: IFMService,
}

const IFM: FC<IFM> = ({ IfmService }) => {
  const [noseNumber, setnoseNumber] = useState<string>('')
  const { isLodingData, titlepart, section, handleSearchIFM } = useIfm(IfmService)
  const [ifm, setIfm] = useState<IFMResponse|undefined|null>(null)
  const [inputError, setInputError] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>()
  const { tailno } = useParams()
  const navigate = useNavigate()

  useEffect(()=>{
    if(tailno) {
      setnoseNumber(tailno);
      loadIfmHandler(tailno);
    }
  },[tailno]);

  useEffect(()=>{
    if(!/^[0-9\b]+$/.test(noseNumber)){
      setInputError(true)
    }
    else {
      setInputError(false)
    }
    
  },[noseNumber])
  useEffect(()=>{
    if(selected!==""){
    setSelected("")
    }
  },[selected])
 
  const inputHandler = (event:CustomEvent<{ value: string }>) => {
        setnoseNumber(event.detail.value as string)
        setSelected('')
  }
  const resetHandler = () => {
    setnoseNumber('')
    setInputError(false)
    setSelected('')
  }

  const Spinner = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        marginTop: '250px'
      }}
    >
      <Loading size='3em' />
    </Box>
  )

  const loadIfmHandler = useCallback((noseNumber:string) => {
    (async () => {
      const ifmData = await handleSearchIFM(noseNumber.padStart(4,'0'))
      setIfm(ifmData)
    })()
  }, [handleSearchIFM, noseNumber])

  const handleEnterPress = useCallback((event: KeyboardEvent<HTMLDivElement>) =>
    {
      if (event && event.key?.toLowerCase() === 'enter'){
        navigate(`/xref/tailno/${noseNumber}`)
      }
    }, [handleSearchIFM, noseNumber])

  return (
    <>
      <Grid container spacing={1} direction='row' justifyContent='space-between'>
        <Grid item my={2} mx={2}>
          <Grid container spacing={1} >
            <Grid item>
              <OrionLabel>Aircraft</OrionLabel>
            </Grid>
            <Grid item onKeyUp={handleEnterPress} >
              <OrionTextInput
                type='text'
                placeholder='Enter aircraft number'
                value={noseNumber}
                onValueChanged={inputHandler}
              />
            </Grid>
            <Grid item>
              <OrionButton
                variant='primary'
                {...(noseNumber === '' ? { disabled: true } : {})}
                onClick={()=>navigate(`/xref/tailno/${noseNumber}`)}
              >
                Search
              </OrionButton>
            </Grid>
            {noseNumber !== '' ? (
              <Grid item>
                <OrionButton variant='tertiary' onClick={resetHandler}>
                  Clear and reset
                </OrionButton>
              </Grid>
            ) : null}
          </Grid>
          {inputError && noseNumber !== '' ? (
            <Box sx={{ marginLeft: '80px' }}>
              <OrionError>Invalid Aircraft Number</OrionError>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <Grid item my={1} mx={2} sx={{ border: '1px solid #000', width: '98%', minHeight: '70vh' }}>
        {isLodingData ? (
          <Spinner />
        ) : ifm && ifm !== null ? (
          <>
            <Box bgcolor={'#f2f2f2'} sx={{ width: '100%', height: '25px', display: 'flex' }}>
              {titlepart.map((part,index) => (
                <Box sx={{ display: 'flex', fontSize: '14px' }} mx={1}  key={index}>
                  <Box>{part.Label}:  </Box>
                  {""}
                  <Box>
                    <b>{part.Value}</b>
                  </Box>
                </Box>
                
              ))}
            </Box>
            <Box mx={2}>
              <div>
                <b>Top Links</b>
              </div>
              {/* eslint-disable jsx-a11y/click-events-have-key-events */}
              {/* eslint-disable jsx-a11y/no-static-element-interactions */}
              {section.map((title,index) => (
                <div
                 role="button"
                 key={index}
                 tabIndex={0}
                  onClick={() => {
                    setSelected(title.Name)
                  }}
                  className={styles.sectionLinks}
                >
                  {title.Name}
                </div>
              ))}
            </Box>
            <Box my={2}>
              {section.map((docValue, index) => (
                <IFMSection
                  key={index}
                  title={docValue.Name}
                  document={docValue.Items}
                  selectedValue={docValue.Name === selected}
                  noseNumber={noseNumber}
                />
              ))}
            </Box>
          </>
        ) : <Box bgcolor={'#f2f2f2'} sx={{ width: '100%', height: '25px', display: 'flex', paddingLeft: '10px' }}>
        {' '}
        Enter a valid aircraft number to load Aircraft directory
      </Box>}
      </Grid>
    </>
  )
}
export default IFM
