import React, { FC, useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { OrionButton, OrionLabel, OrionTextInput } from '@orion/orion-design-system-react'

interface VendorSearchBarProps
{
  handleSearch: (searchTerm: string) => void
}

const VendorSearchBar: FC<VendorSearchBarProps> = ({ handleSearch }) =>
{
  const [searchTerms, setSearchTerms] = useState('')
  const handleClearSearchTerms = useCallback(() =>
  {
    setSearchTerms('')
    handleSearch('')
  }, [setSearchTerms])
  const handleSetSearchTerms = useCallback((event: CustomEvent<{ value: string }>) =>
  {
    setSearchTerms(event.detail.value)
    handleSearch(event.detail.value)
  }, [setSearchTerms])

  return (
    <Box>
      <Grid container spacing={1} direction="row" justifyContent="space-between">
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <OrionLabel>
                Vendor search
              </OrionLabel>
            </Grid>
            <Grid item>
              <OrionTextInput
                id="vendor-search"
                type="text"
                placeholder="Enter vendor name"
                value={searchTerms}
                onValueChanged={handleSetSearchTerms}
              />
            </Grid>
            <Grid item>
              <OrionButton variant="tertiary" onClick={handleClearSearchTerms}>
                Clear search
              </OrionButton>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Box>
  )
}

export default VendorSearchBar
