import React, { FC, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PortalDialog from '../common/CustomDialog'
import DataTable from '../common/DataTable'
import useAudits from '../audit/useAudits'
import AuditService from '../audit/AuditService'
import moment from 'moment'
import { Link } from 'react-router-dom'
export interface ReviewHistoryModalProps
{
  auditService: AuditService
  isOpen: boolean
  onClose: () => void
  vendorId: string
  vendorName: string
  
}

const columns = [
  { id: 'EventType', label: 'Item changed', isSort: false, width: '74%' },
  { id: 'UserId', label: 'User', isSort: false, width: '8%' },
  { id: 'EventTimestamp', label: 'Date of event', isSort: false, width: '18%' }

]
const rowsPerPageOptions = [10, 20, 50];

const ReviewVendorHistoryModal: FC<ReviewHistoryModalProps> = ({
  auditService,
  vendorId,
  vendorName,
  isOpen,
  onClose
}) =>
{
  const [offset, setOffset] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
  const [auditPageId, setAuditPageId] = useState<string | undefined>(undefined);
  const { audits, isLoadingAudits, hasNextPage, pageId } = useAudits(auditService,'', vendorId, offset, rowsPerPage, auditPageId)

  useEffect(()=>{
    setAuditPageId(pageId);
  },[offset])

  const modifiedData = audits?.map(obj => ({
    ...obj,
    EventTimestamp: moment(obj?.EventTimestamp).format('YYYY-MM-DD HH:mm'),
    EventType: (
      <>
        {obj?.EventType}
        {obj?.PackageId && (
          <>
            {", "}
            <Link to={`/admin/package/${obj?.PackageId}`}>
              {obj?.PackageId} {obj?.PackageTitle ? `(${obj?.PackageTitle})` :''}
            </Link>
          </>
        )}
      </>
    )
  })) 


  return (
    <PortalDialog
      open={isOpen}
      onClose={onClose}
      title="Revision history"
      content={
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <span><Typography component="span" sx={{ fontWeight: 'bold' }}>Vendor:</Typography> {vendorName}</span>
          </Box>
          {
            audits ? <DataTable
              data={modifiedData}
              columns={columns}
              isLoading={isLoadingAudits}
              hasNextPage={hasNextPage}
              setOffset={setOffset}
              offset={offset}
              rowsPerPageOptions={rowsPerPageOptions}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              disablePrevBtn={true}
            /> : 'No events found for this Vendor'
          }
        </>
      }
      maxWidth={false}
      customMaxWidth={'900px'}
    />
  )
}

export default ReviewVendorHistoryModal;
