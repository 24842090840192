import React, { FC, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import flow from 'lodash/flow'
import DataTable from '../common/DataTable'
import PackageService, { PackageListItem, PackageSearchItems } from '../package/PackageService'
import useViewerPackages from './useViewerPackages'
import { formatDates } from '../package/utils'
import ViewerPackageSearchBar from './ViewerPackageSearchBar'
import VendorViewAdmin from './VendorViewAdmin'
import useAdminVendors from '../vendor/useAdminVendors'
import AdminVendorsService from './AdminVendorsService'
import Loading from '../common/Loading'
import { useSessionAuth } from '../auth/AuthContext'
import { OrionButton } from '@orion/orion-design-system-react'
import { useNavigate } from 'react-router-dom'
import ViewPDFModal from '../package/ViewPDFModal'

const sortAndFormatDates = flow([formatDates])

const columns = [
  { id: 'Title', label: 'Title', isSort: true, width: '60%' },
  { id: 'NumFiles', label: 'Document Count', isSort: false, width: '20%' },
  { id: 'ModifiedTimestamp', label: 'Last Updated', isSort: true, width: '20%' },
]

const SearchResultsColumns = [
  { id: 'DocumentTitle', label: 'Title', isSort: false, width: '50%', isClickableCellLink:true },
  { id: 'DocumentDescription', label: 'Description', isSort: false, width: '50%', isClickableCellLink:false },

]

const rowsPerPageOptions = [1000];

const Spinner = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <Loading size="3em" />
  </Box>
)

export interface ViewerPackageProps
{
  packageService: PackageService
  handleRowClick:(selectedRow:PackageListItem)=>void
  adminVendorsService:AdminVendorsService
  setSelectedVendorId:(newVal:string|undefined)=>void
  selectedVendorId:string | undefined
}

interface VendorsType {
  Id : string
  Name : string
  NumAssignedPackages : number
  SyncTimestamp : string
}
interface vendorsListType {
  Id: string
  Name: string
  SyncTimestamp: string
  NumAssignedPackages: number
}


interface AdminVendorDetailsType {
  Vendors:VendorsType[];
  Offset:number
  Limit:number;
  HasNextPage:boolean,
}

interface DataTableComponentProps {
  packagesList:PackageListItem[];
  handleRowClick:(selectedRow:PackageListItem)=>void
  hasNextPage:boolean
  offset:number;
  setOffset:(value:number)=>void;
  setRowsPerPage:(value:number)=>void;
  rowsPerPage:number;
  isLoading:boolean;
  setSortMethod:(value:string)=>void;
  supplierName?:string|undefined|null;
  userId?:string;
  userRole?:string;
}

interface VendorViewAdminComponentProps {
  vendorsList: vendorsListType[]
  handleChange:(selectedVal:vendorsListType|undefined) => void
  setVendorSearchVal:(newVal:string)=>void
  selectedVendorId:string|undefined
}


const DataTableComponent:FC<DataTableComponentProps> = ({packagesList, handleRowClick, hasNextPage, setOffset, offset,setRowsPerPage, rowsPerPage, isLoading, setSortMethod, supplierName, userId, userRole }) => {

  const navigate = useNavigate();

  if((userRole!=='Admin' && userRole!=='Auditor') && supplierName && packagesList?.length===0) {
    navigate('/unauthorized', {state:{userId: userId , supplierName: supplierName }});
  }

  return <DataTable
  data={sortAndFormatDates([...packagesList])}
  columns={columns}
  rowsPerPageOptions={rowsPerPageOptions}
  onRowClick={handleRowClick}
  hasNextPage={hasNextPage}
  setOffset={setOffset}
  offset={offset}
  setRowsPerPage={setRowsPerPage}
  rowsPerPage={rowsPerPage}
  isLoading={isLoading}
  setSortMethod={setSortMethod}
  isFrom={'viewer'}
  isClickableRowLink={true}
/>
}

const VendorViewAdminComponent:FC<VendorViewAdminComponentProps> = ({vendorsList,handleChange, setVendorSearchVal, selectedVendorId}) => {
  return <VendorViewAdmin selectedVendorId={selectedVendorId} vendorsList={vendorsList} handleChange={handleChange} setVendorSearchVal={setVendorSearchVal}/>
}

const ViewerPackageSection: FC<ViewerPackageProps> = ({
  packageService,
  handleRowClick,
  adminVendorsService,
  setSelectedVendorId,
  selectedVendorId
}) =>
{
  const [searchVal, setSearchVal] = useState('')
  const [vendorSearchVal, setVendorSearchVal] = useState('')
  const { authSessionInfo } = useSessionAuth()
  const {adminVendorDetails} = useAdminVendors(adminVendorsService)
  const [adminVendorsList, setAdminVendorsList] = useState<VendorsType[]>([])
  // const [selectedVendorId, setSelectedVendorId] = useState<string|undefined>(undefined)
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);
  const [supplierName, setSupplierName] = useState<string|null|undefined>(null);
  const [offset, setOffset] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
  const [sortMethod, setSortMethod] = useState('TITLE_ASC');
  const [isViewPDFModalOpen, setIsViewPDFModalOpen ] = useState(false);
  const [selSearchDocument, setSelSearchDocument ] = useState<PackageSearchItems>();

  const handleClose = () => {
    setIsViewPDFModalOpen(false);
  }

  const navigate = useNavigate();

  useEffect(()=>{
    setIsAdmin(authSessionInfo?.userRole === 'Admin');
    setIsAuditor(authSessionInfo?.userRole === 'Auditor')
    setSupplierName(authSessionInfo?.supplierName)
  },[authSessionInfo])

  useEffect(()=>{
    if(isAdmin || isAuditor) {
      (async ()=>{
        const adminVendorList = await adminVendorDetails(vendorSearchVal) as AdminVendorDetailsType;
        setAdminVendorsList(adminVendorList?.Vendors)
      })()
    }
  },[isAdmin,isAuditor, vendorSearchVal])

  const handleVendorSelectionChange = (selectedVal:VendorsType|undefined) => {
    setSelectedVendorId(selectedVal?.Id)
    setSearchVal('')
  }

  const { packages, isLoadingPackages, hasNextPage, viewerPackageSearchResults } = useViewerPackages(packageService, searchVal, selectedVendorId, offset, rowsPerPage, sortMethod)

  const handleSearchString = (searchStr:string) => {
    setSearchVal(searchStr);
    setOffset(0);
  }

  const handleSearchRowClick = (row:PackageSearchItems) => {
    setSelSearchDocument(row);
    setIsViewPDFModalOpen(true);
  }


  return (
    <>
    { (isLoadingPackages && !searchVal && !selectedVendorId) ? <Grid sx={{display:'flex', justifyContent:'center'}}>
      <Spinner/>
    </Grid>:
    <>
    <Grid item>
      <Box sx={{display:'flex'}}> 
        <ViewerPackageSearchBar handleSearch={handleSearchString} />
        <>
        {
        (authSessionInfo?.userRole === 'Admin' || authSessionInfo?.userRole === 'Auditor') ? <div style={{
            display: "flex",
            columnGap: "10px",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <OrionButton
              variant="primary"
              onClick={()=>{navigate('/admin')}}
            >
              Navigate to Admin
            </OrionButton>
            <VendorViewAdminComponent selectedVendorId={selectedVendorId} vendorsList={adminVendorsList} handleChange={handleVendorSelectionChange} setVendorSearchVal={setVendorSearchVal}/>
          </div>
          :
          <div>Supplier Name: {supplierName}</div>   
        }
        </>
      </Box>
    </Grid>
    { 
      searchVal?.trim()?.length==0  && <DataTableComponent 
        packagesList={[...packages]} 
        handleRowClick={handleRowClick} 
        hasNextPage={hasNextPage} 
        setOffset={setOffset}
        offset={offset}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        isLoading={isLoadingPackages}
        setSortMethod={setSortMethod}
        supplierName={supplierName}
        userId={authSessionInfo?.userId}
        userRole={authSessionInfo?.userRole}
        />
    }
    {
      searchVal?.trim()?.length>0 &&  <DataTable
      data={viewerPackageSearchResults?.Results ||[]}
      columns={SearchResultsColumns}
      onRowClick={handleSearchRowClick}
      isLoading={isLoadingPackages}
      isClickableRowLink={false}
    />
    }
    {
    isViewPDFModalOpen && <ViewPDFModal userRole={'viewer'} documentId={selSearchDocument?.DocumentId} title={selSearchDocument?.DocumentTitle} packageId={selSearchDocument?.PackageId} isOpen={isViewPDFModalOpen} onClose={handleClose}/>
    }

  </>
    }
     
    </>
  )
}

export default ViewerPackageSection
