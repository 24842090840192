import { useCallback, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import DocumentService, { Document, DocumentType } from './DocumentService'
import useDocumentStore from './useDocumentStore'
import logger from '../common/logger'
import useServiceAuth from '../common/useServiceAuth'
import useServiceMessaging from '../common/useServiceMessaging'

const useDocument = (documentService: DocumentService, searchTerms?: string, type?: DocumentType) =>
{
  const log = logger.child({ hook: 'useDocument' })
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState(false)
  // const [prevSearchTerms, setPrevSearchTerms] = useState<string[]>([])
  const documents = useDocumentStore(state => state.documents)
  const setDocuments = useDocumentStore(state => state.setDocuments)
  const [documentSearchResults, setDocumentSearchResults] = useState<Document[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const { waitingOnAuth } = useServiceAuth<Document>(documentService)

  useServiceMessaging<Document>(documentService, 'Server Error')

  const handleError = useCallback(() =>
  {
    setIsSearching(false)
  }, [setIsSearching])

  useEffect(() =>
  {
    documentService.addErrorHandler(handleError)
    return () => {
      documentService.removeErrorHandler(handleError)
    }
  }, [])

  // useEffect(() =>
  // {
  //   if (searchTerms && prevSearchTerms.length > 0) {
  //     if (!prevSearchTerms.includes(searchTerms)) {
  //       setRowCount(0)
  //     }
  //   }
  // }, [searchTerms, prevSearchTerms])

  const handleSearch = () =>
  {
    if (waitingOnAuth) return
    (async () =>
    {
      log.trace({ searchTerms }, 'handleSearch()')
      if (isEmpty(searchTerms)) return  
      setIsSearching(true)

      const response = await documentService
        .search(
          searchTerms as string,
          rowsPerPage,
          offset,
          type
        )

      // setPrevSearchTerms(state => [
      //   searchTerms as string,
      //   ...state
      // ])
      setHasNextPage(response.HasNextPage)
      // setRowCount(state =>
      // {
      //   if (response?.HasNextPage) return response?.Documents?.length + (offset==0?rowsPerPage:(offset*rowsPerPage)+rowsPerPage)
      //   return state;
      // })
      setDocumentSearchResults(response?.Documents || [])
      setDocuments(response?.Documents || [])
      setIsSearching(false)
    })()
  }

  // re-trigger search if pagination options are changed
  useEffect(() =>
  {
    // if(offset === 0) {
    //   setRowCount(rowsPerPage);
    // }
    handleSearch()
  }, [rowsPerPage, offset])

  return {
    hasNextPage,
    handleSearch,
    rowsPerPage,
    setRowsPerPage,
    offset,
    setOffset,
    documents,
    documentSearchResults,
    isSearching
  }
}
export default useDocument
