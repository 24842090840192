import { Logger } from '../common/logger'
import { ApiConfig } from '../config/config'
import Service from '../common/Service'
import HttpClient from '../common/HttpClient'

export default class PackageAssignmentService extends Service<unknown>
{

  constructor(
    logger: Logger,
    httpClient: HttpClient,
    private readonly config: ApiConfig
  )
  {
    super(logger, httpClient, 'PackageAssignmentService')
  }

  async addAssignment(packageId: string, vendorId: string)
  {
    return super.httpPost(this.config.addPackageAssignmentUrl, { PackageId: packageId, VendorId: vendorId })
  }

  async removeAssignment(packageId: string, vendorId: string)
  {
    return super.httpPost(this.config.removePackageAssignmentUrl, { PackageId: packageId, VendorId: vendorId })
  }

  async copyPackages(sourceVendorId: string, destVendorId: string)
  {
    return super.httpPost(`${this.config.copyPackagesUrl}?src_vendor_id=${sourceVendorId}&dst_vendor_id=${destVendorId}`,{})
  }

}
