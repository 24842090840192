import React, { FC, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import { OrionMessage } from '@orion/orion-design-system-react'
import isEmpty from 'lodash/isEmpty'
import { styled } from '@mui/material/styles'
import useMessagingStore, { MessagingVariants } from './useMessagingStore'

const Message = styled(OrionMessage)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  width: '30%',
  zIndex: 10000
}))

export interface MessagingProps
{
  message?: string
  variant?: MessagingVariants
  heading?: string
  isOpen?: boolean
  onClose?: () => void
  ttl?: number
}

const Messaging: FC<MessagingProps> = props =>
{
  const message = useMessagingStore(state => state.message)
  const variant = useMessagingStore(state => state.variant)
  const heading = useMessagingStore(state => state.heading)
  const ttl = useMessagingStore(state => state.ttl)
  const clearMessage = useMessagingStore(state => state.clearMessage)

  const isOpen = useMemo(() => !isEmpty(message) && !isEmpty(variant), [message, variant])

  if(isEmpty(message) && isEmpty(variant)) {
    return null;
  }


  return (
    <Message
      variant={props.variant ?? variant}
      heading={props.heading ?? heading}
      hasIcon
      dismissible
      isOpen={props.isOpen ?? isOpen}
      onMessageClose={props.onClose ?? clearMessage}
      timeToDisappear={props.ttl ?? ttl}
    >
      <Typography fontSize="1rem" sx={{ p: 1 }}>{props.message ?? message}</Typography>
    </Message>
  )
}
export default Messaging
