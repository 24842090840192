import { Logger } from '../common/logger'
import { ApiConfig } from '../config/config'
import Service from '../common/Service'
import HttpClient from '../common/HttpClient'

export default class SessionInfoService extends Service<unknown>
{

  constructor(
    logger: Logger,
    httpClient: HttpClient,
    private readonly config: ApiConfig
  )
  {
    super(logger, httpClient, 'PackageAssignmentService')
  }

  async getSessionInfo()
  {
    return super.httpGet(this.config.sessionInfoUrl)
  }
}
