import React, { createContext, useContext, useState, ReactNode, useCallback } from "react";

export interface AuthSessionInfo {
  userName: string | undefined;
  firstName?: string;
  lastName?: string;
  userRole: string | undefined;
  userId:string | undefined;
  supplierName:string| null| undefined;
}

interface AuthContextType {
  authSessionInfo: AuthSessionInfo | undefined;
  setUserSessionInfo: (info: AuthSessionInfo) => void;
  clearUserSessionInfo: () => void;
}

interface AuthProviderProps {
    children: ReactNode; 
  }
  
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authSessionInfo, setAuthSessionInfo] = useState<AuthSessionInfo | undefined>(undefined);

  const setUserSessionInfo = useCallback((info: AuthSessionInfo) => {
    setAuthSessionInfo(info);
  },[]);

  const clearUserSessionInfo = useCallback(() => {
    setAuthSessionInfo(undefined);
  },[]);

  return (
    <AuthContext.Provider value={{ authSessionInfo, setUserSessionInfo, clearUserSessionInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useSessionAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
