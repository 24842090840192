

import React, {FC} from 'react'
import Modal from '../common/CustomModal'
import VendorPDFViewer from './VendorPDFViewer';

interface ViewVendorPDFDialogProps
{
    preSignedURL: string;
    title:string|undefined;
    isOpen: boolean;
    onClose: () => void;
}

const ViewVendorPDFModal: FC<ViewVendorPDFDialogProps> = ({preSignedURL, title, isOpen, onClose}) =>
{

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      name={"vendor-pdf-viewer"}
      title={title||''}
      isOpenPDFLink={false}
      >
          <VendorPDFViewer preSignedURL={preSignedURL} title={title}/>
      </Modal>
  )
}

export default ViewVendorPDFModal
