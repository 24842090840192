import {useState,useCallback,useEffect} from "react"
import IFMAdminService from "./ifmadminService"
import useServiceAuth from '../common/useServiceAuth'
import logger from '../common/logger'
import useServiceMessaging from '../common/useServiceMessaging'
import useMessaging from '../messaging/useMessaging'

const useUploadFile=(ifmadminService:IFMAdminService)=>{
    const log = logger.child({ hook: 'useUploadFile' })
    const [isLoading, setIsLoading] = useState(false)
    const { waitingOnAuth } = useServiceAuth(ifmadminService)
    const { sendSuccessMessage } = useMessaging()
    const [uploadFilesResp, setUploadFilesResp] = useState<null|string|undefined|unknown>(null);
  
    useServiceMessaging(ifmadminService, '')
  
    const handleError = useCallback(() =>
    {
      setIsLoading(false)
    }, [setIsLoading])
  
    useEffect(() =>
    {
      ifmadminService.addErrorHandler(handleError)
      return () =>
      {
        ifmadminService.removeErrorHandler(handleError)
      }
    }, [])

    const uploadFiles=useCallback(async(fileData:string)=>{
        if (waitingOnAuth) throw new Error('Authentication not ready') 
            log.trace(fileData, 'ADD Files')
            setIsLoading(true)
       const response= await ifmadminService.uploadFile(fileData)
          if(response!==undefined){
            sendSuccessMessage("Files are added Successful")
            setUploadFilesResp(`${response}-${new Date()}`);
          }
       log.trace( fileData,'Files are added  successful')
            setIsLoading(false)
    },[ifmadminService,waitingOnAuth,isLoading])
    return{uploadFiles, uploadFilesResp, isLoading}

}
export default useUploadFile