import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { PackageListItem } from './PackageService'

export interface PackageState
{
  packages: PackageListItem[]
}

export interface PackageActions
{
  setPackages: (pkgs: PackageListItem[]) => void
}

const usePackageStore = create<PackageState & PackageActions>()(
  devtools(
    immer(set => ({
      packages: [],
      setPackages: payload =>
        set(state =>
        {
          state.packages = payload
        })
    })),
    {
      name: 'udocs-support-data--packages'
      // storage - defaults to local storage
    }
  )
)

export default usePackageStore
