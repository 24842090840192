import React, { FC } from 'react'

import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

interface PDFWorkerProps {
  pdfData: string | Uint8Array
  handleDocumentLoad: () => void
  isFromLightViewer?:boolean
}

const PDFWorker: FC<PDFWorkerProps> = ({ isFromLightViewer, pdfData, handleDocumentLoad }) => {
  return (
    <>
      {pdfData && (
        <Worker workerUrl='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js'>
          <div
            style={{
              border: '1px solid rgba(0, 0, 0, 0.3)',
              display: 'flex',
              height: isFromLightViewer?'98vh':'650px',
              overflow: 'auto',
              width: '100%'
            }}
          >
            <div style={{ flex: 1 }}>
              <Viewer
                fileUrl={pdfData}
                plugins={[defaultLayoutPlugin()]}
                onDocumentLoad={handleDocumentLoad}
                defaultScale={SpecialZoomLevel.PageWidth}
              />
            </div>
          </div>
        </Worker>
      )}
    </>
  )
}

export default PDFWorker
