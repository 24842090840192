export default {
    'united-logo': '0 0 161 29',
    'favorite': '0 0 24 24',
    'recent': '0 0 33 33', 
    'home': '0 0 33 31', 
    'search': '0 0 31 33',
    'refresh': '0 0 33 33', 
    'filter': '0 0 60 60', 
    'document': '0 0 61 45',
    'unfavorite': '0 0 455 455',
    'bookmark': '0 0 17 19',
    'popout': '0 0 28 27', 
    'popout-gray': '0 0 35 35', 
    'pinned': '0 0 24 23', 
    'unpinned': '0 0 24 23',
    'legend': '0 0 1060 488',
    'alert': '0 0 25 23',
    'check': '0 0 25 25',
    'adminpanel': '0 0 32 32',
    'toggle-on': '0 0 36 20',
    'toggle-off': '0 0 36 20',
    'print': '0 0 34 30'
}