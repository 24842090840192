import React, { FC, useCallback, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { OrionButton } from '@orion/orion-design-system-react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Modal from '../common/CustomModal'
import useVendors from './useVendors'
import VendorService, { Vendor } from './VendorService'
import PackageAssignmentService from '../package/PackageAssignmentService'
import usePackageAssignment from '../package/usePackageAssignment'
import logger from '../common/logger'
import useMessaging from '../messaging/useMessaging'
// import { useSessionAuth } from '../auth/AuthContext';

export interface VendorAssignmentModalProps {
  vendorService: VendorService
  packageAssignmentService: PackageAssignmentService
  vendorId?: string
  vendorName?: string
  open: boolean
  onClose: () => void
}

const VendorPackageCopyModal: FC<VendorAssignmentModalProps> = ({
  open,
  onClose,
  vendorId,
  vendorName,
  vendorService,
  packageAssignmentService
}) => {
  const log = logger.child({ component: 'VendorAssignmentModal' })
  const { vendorCopyPackages } = usePackageAssignment(packageAssignmentService)
  // const { authSessionInfo } = useSessionAuth()

  // Vendor Search
  const [vendorSearchTerms, setVendorSearchTerms] = useState('')
  const { vendors: vendorSearchResults, isLoadingVendors } = useVendors(vendorService, vendorSearchTerms)
  const [selectedNewVendor, setSelectedNewVendor] = useState<Vendor | null>(null)
  const [isCopyClicked, setIsCopyClicked] = useState(false)
  const { sendSuccessMessage } = useMessaging()

  console.log('vendor id::', vendorId)

  const handleOnCopyPackages = useCallback(() => {
    log.trace('handleOnCopyPackages::')
    console.log('handleOnCopyPackages:', vendorId, selectedNewVendor)
    if(vendorId && selectedNewVendor) {
      vendorCopyPackages(vendorId, selectedNewVendor?.Id).then((resp)=>{
       if(resp===undefined) {
        sendSuccessMessage('Packages are copied Successfully')
       }
      })
      setIsCopyClicked(true);
    }
  },[vendorCopyPackages, selectedNewVendor])

  return (
    <Modal open={open} onClose={onClose} name='vendor-copy-packages' title='Copy package assignments'>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={6}>
          <Typography component='h3' variant='body1' sx={{ pb: 1 }}>
            Source Vendor
          </Typography>
          <TextField
            label='Source Vendor'
            value={vendorName || ''}
            disabled
            fullWidth
            size='small' // Ensure small size consistency
            InputProps={{
              style: {
                height: '40px', // Consistent height with Autocomplete
                paddingRight: '8px'
              }
            }}
           
          />
        </Grid>
        <Grid container item xs={6}>
          <Typography component='h3' variant='body1' sx={{ pb: 1 }}>
            Destination Vendor
          </Typography>
          <Autocomplete
            disablePortal
            id='vendor-copy-autocomplete'
            renderInput={params => <TextField {...params} label='Enter Vendor Name' size='small' />}
            options={vendorSearchResults?.filter(vendor=>vendor.Id!==vendorId)}
            getOptionLabel={option => option.Name}
            loading={isLoadingVendors}
            isOptionEqualToValue={(option, value) => option.Id === value.Id}
            value={selectedNewVendor}
            inputValue={vendorSearchTerms}
            onChange={(_event, vendor: Vendor | null) => {setSelectedNewVendor(vendor); setIsCopyClicked(false)}}
            onInputChange={(_event: unknown, value: string) => setVendorSearchTerms(value)}
            noOptionsText='No Vendors'
            fullWidth
          />
        </Grid>
        <Grid container justifyContent='flex-end' sx={{ mt: 4 }}>
          <OrionButton
            variant='primary'
            onClick={handleOnCopyPackages}
            {...((!selectedNewVendor || isCopyClicked)?{disabled:true}:{})}
          >
            Copy
          </OrionButton>
          <OrionButton variant='tertiary' onClick={onClose}>
            Cancel
          </OrionButton>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default VendorPackageCopyModal
