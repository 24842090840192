import React, { useState } from 'react'
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material'
import { OrionButton } from '@orion/orion-design-system-react'

interface MenuItem<Data>
{
  label: string
  onClick: (data?: Data) => void
}

interface MenuProps<Data>
{
  icon: string
  items: MenuItem<Data>[]
  data?: Data
}

export default function Menu<Data>({ icon, items, data }: MenuProps<Data>)
{
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
  {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () =>
  {
    setAnchorEl(null)
  }

  return (
    <>
      <OrionButton variant="tertiary" style={{padding:'0px !important'}} icon-name={icon} onClick={handleClick} />
      <MuiMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, index) => (
          <MuiMenuItem
            key={index}
            onClick={() =>
            {
              item.onClick(data)
              handleClose()
            }}
          >
            {item.label}
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </>
  )
}
