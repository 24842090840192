import React, { FC }  from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface VendorProps {
  Id: string
  Name: string
  SyncTimestamp: string
  NumAssignedPackages: number
}

interface AutoCompleteSelectionProps {
  vendors: VendorProps[]
  handleChange:(selectedVal:VendorProps|undefined) => void
  setVendorSearchVal:(newVal:string)=>void
  selectedVendorId:string|undefined
}

const AutoCompletePackageSelection:FC<AutoCompleteSelectionProps> = ({vendors, handleChange, setVendorSearchVal, selectedVendorId}) => {

  const selectedValue = React.useMemo(()=>vendors?.find(obj=>obj?.Id === selectedVendorId),[vendors])
  return (
    <Autocomplete
      id="vendor-selection"
      sx={{ width: 600 }}
      options={vendors}
      autoHighlight
      getOptionLabel={(option) => option?.Name}
      onChange={(_event, selectedVendor: VendorProps | null) =>
        {
          if (selectedVendor) {
              handleChange(selectedVendor)
        }}
      }
      onInputChange = {
        (_event, newInputVal:string, reason:string ) => {
          if(reason === 'clear') {
            setVendorSearchVal("");
          }
          else if(reason === 'input') {
            setVendorSearchVal(newInputVal);
          }
        }
      }
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {option?.Name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a vendor"
          inputProps={{
            ...params.inputProps
          }}
        />
      )}
      isOptionEqualToValue={(option,value)=>option?.Id === value?.Id}
      value={selectedValue}
    />
  );
}

export default AutoCompletePackageSelection