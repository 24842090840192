/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import PackageService, { PackageListItem } from '../package/PackageService'
import AdminVendorsService from './AdminVendorsService'
// import AuditService from '../audit/AuditService'
import ViewerPackageSection from './ViewerPackageSection'
import PDFViewer from '../common/PDFViewer'
import useViewerPackage from './useViewerPackage'
import logger from '../common/logger'
import { useSessionAuth } from '../auth/AuthContext'
import Slideout from './Slideout/Slideout'
import TableContents from './Slideout/TableContents/TableContents'
import cx from 'classnames'
import styles from './viewer.module.scss'
import Loading from '../common/Loading'
import Icon from '../common/Icon/Icon'
export interface ViewerProps {
  adminVendorsService: AdminVendorsService
  packageService: PackageService
}

const Spinner = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <Loading size="3em" />
    </Box>
  )
  

const Viewer: FC<ViewerProps> = ({ packageService, adminVendorsService }) => {
  const { authSessionInfo } = useSessionAuth()
  const [selectedVendorId, setSelectedVendorId] = useState<string | undefined>(undefined)
  const [selectedPackageDetails, setSelectedPackageDetails] = useState<any | null>(null)
  const [selectedDocument, setSelectedDocument] = useState<any>(null)
  const [selectedTrail, setSelectedTrail] = useState<any>([])
  const [isContentSelected, setContentSelected] = useState<any>(true)
  const [selPDFDetails, setSelPDFDetails] = useState<any>(null)
  const [bannerDetail, setBannerDetail]=useState('');
  const [bannerSubTitle, setBannerSubTitle]=useState('');
  const [isViewerLoading, setIsViewerLoading]=useState(false);
  const log = logger.child({ component: 'Viewer' })
  const [isSelectedPackage, setIsSelectedPackage] = useState(false)
  const [selectedBreadCrubPage, setSelectedBreadCrubPage] = useState<string | null>(null);
  const [isBreadCrumbDisplay, setIsBreadCrumbDisplay] = useState(false); 
  const { getViewerPkg } = useViewerPackage(packageService)

  const handleRowClick = async (selectedRow: PackageListItem) => {
    setIsSelectedPackage(true)
    setIsViewerLoading(true)
    setIsBreadCrumbDisplay(true);
    const targetResp = await getViewerPkg(selectedRow.Id, selectedVendorId)
    setSelectedPackageDetails(targetResp)
    setIsViewerLoading(false)
    console.log('testResp:::', targetResp, selectedVendorId)
    if (!targetResp) {
        setIsViewerLoading(false)
      log.error({ selectedRow }, 'error while fetching viewer Package')
      return
    }
  }

  const selectedTrailCallback = (selDetails: any) => {
    setSelPDFDetails(selDetails);
    setIsBreadCrumbDisplay(true);
    console.log('filename:::', selDetails)
  }

  useEffect(() => {
    if (selectedDocument && selectedPackageDetails) {
        if(selectedTrail)
        {
            let tbannerDetail='';
            let tbannerSubTitle='';

            if(selectedTrail.length>1)
            {
                for(let i=0; i<selectedTrail.length-1; i++)
                {
                    tbannerSubTitle=tbannerSubTitle + selectedTrail[i] + '/ ';
                }
                tbannerDetail=selectedTrail[selectedTrail.length-1];
            }
            else
                tbannerDetail=selectedTrail[0];

            setBannerDetail(tbannerDetail);
            setBannerSubTitle(tbannerSubTitle);
        }
    }
}, [selectedDocument,selectedTrail])


useEffect(()=>{
    console.log(bannerSubTitle,"ssss:", bannerDetail,"::::bannerss");
},[bannerSubTitle, bannerDetail])

const breadCrumbClick = (selectedPage:string) => {
  setSelectedBreadCrubPage(selectedPage);
  setIsBreadCrumbDisplay(false);
  setIsSelectedPackage(false);
  setSelPDFDetails(null);
  setSelectedPackageDetails(null);
  console.log("selectedVendorId:::", selectedVendorId);
}

const breadCrubDisplay = (selPage:string, currentPage:string) => {
  return <Box>
     {/* eslint-disable jsx-a11y/click-events-have-key-events */}
    {/* eslint-disable jsx-a11y/no-static-element-interactions */}
    <div className={styles.wrapperbreadcrumb}>
      <span className={styles.mainbreadcrumb} onClick={()=>breadCrumbClick(selPage)}>Vendor Support Data  </span><span>{'   >'} {currentPage}</span>
    </div>
    </Box>
  }

  return (
    <>
    {
        isViewerLoading && !selectedPackageDetails && <Spinner/>
    }

    <Box sx={{ m: 2 }}>
      <Grid container>
        
        <Box sx={{ width: '100%' }}>
          {((!isSelectedPackage && !isViewerLoading) || (!isBreadCrumbDisplay && selectedBreadCrubPage && selectedBreadCrubPage === 'vsd')) && (
            <ViewerPackageSection
              packageService={packageService}
              adminVendorsService={adminVendorsService}
              handleRowClick={handleRowClick}
              selectedVendorId={selectedVendorId}
              setSelectedVendorId={setSelectedVendorId}
            />
          )}
        </Box>
        
        <Box>
          {
            isBreadCrumbDisplay && isSelectedPackage && selPDFDetails && breadCrubDisplay('vsd', 'Pdf Viewer') 
          }
          {(isBreadCrumbDisplay && isSelectedPackage && selPDFDetails) && (
            <>
              <div className={cx(styles.container, styles.containerHTML)}>
                <div className={styles.actions} style={{cursor:'pointer'}}>
                  <div className={styles.buttons}>
                     {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                    {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                     <div className={cx(styles.button, {[styles.active]: isContentSelected })} onClick={() => {setContentSelected(!isContentSelected);}}>
                        <div className={`${styles.icon} ${styles.contents}`}>
                          <Icon variant='document' width='30px' height='40px' clickable='false' />
                        </div>
                        <div className={styles.text}>Table of contents</div>
                      </div>
                  </div>
                </div>
                <div className={cx(styles['slideout'], { [styles['slideout-view']]: isContentSelected })}>
                  {isContentSelected && (
                    <Slideout>
                      <TableContents
                        close={() => setContentSelected(false)}
                        respData={selectedPackageDetails}
                        selectedTrailCallback={selectedTrailCallback}
                        isLoading={isViewerLoading}
                        setSelectedDocument={setSelectedDocument}
                        setSelectedTrail={setSelectedTrail}
                        isInModal={true}
                      />
                    </Slideout>
                  )}
                </div>
              </div>
            </>
          )}
        </Box>
        <Box sx={{ width: '100%' }}>
        {isBreadCrumbDisplay && selectedPackageDetails && !(isSelectedPackage && selPDFDetails) && breadCrubDisplay('vsd', 'Table of Contents') }
        {isBreadCrumbDisplay && selectedPackageDetails && !(isSelectedPackage && selPDFDetails) && ( <div>
          <TableContents
                        close={() => setContentSelected(false)}
                        respData={selectedPackageDetails}
                        selectedTrailCallback={selectedTrailCallback}
                        isLoading={isViewerLoading}
                        setSelectedDocument={setSelectedDocument}
                        setSelectedTrail={setSelectedTrail}
                        isInModal={false}
                      />
        </div>)}
        </Box>
        <Box sx={{ width: '100%' }}> 
         
          {(isBreadCrumbDisplay && isSelectedPackage && selPDFDetails) && (
            <>
            <PDFViewer
              userRole={authSessionInfo?.userRole}
              documentId={selPDFDetails?.DocumentId}
              title={selPDFDetails?.Title}
              bannerDetail={bannerDetail}
              bannerSubTitle={bannerSubTitle}
              packageId={selectedPackageDetails?.Id}
            />
            </>
          )}
        </Box>
      </Grid>
    </Box>
    </>
  )
}

export default Viewer
