import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { PKCE, Tokens } from './AuthService'

export interface AuthState
{
  tokens: Tokens
  pkce: PKCE
  stateValue: string
  isAuthorizing: boolean
  isAwaitingAuthResponse: boolean
}

export interface AuthActions
{
  setTokens: (tokens: Partial<Tokens>, updateAll?: boolean) => void
  setPKCE: (pkce: PKCE) => void
  setStateValue: (stateValue: string) => void
  setIsAuthorizing: (isAuthorizing: boolean) => void
  setIsAwaitingAuthResponse: (isAwaitingCodeResponse: boolean) => void
  clear: () => void
}

const useAuthStore = create<AuthState & AuthActions>()(
  devtools(
    persist(
      immer(set => (
        {
          tokens: {},
          pkce: {},
          stateValue: '',
          isAuthorizing: false,
          isAwaitingAuthResponse: false,
          setTokens: (payload, updateAll = true) => set(state => {
            if (updateAll) {
              state.tokens = {
                ...state.tokens,
                ...payload,
              };
            } else {
              state.tokens = {
                ...state.tokens,
                accessToken: payload.accessToken,
                refreshToken: payload.refreshToken,
                expiresAt: payload.expiresAt,
                expiresIn: payload.expiresIn,
                idToken: payload.idToken ?? state.tokens.idToken,
              };
            }
          }),
          setPKCE: payload => set(state =>
          {
            state.pkce = payload
          }),
          setStateValue: payload => set(state =>
          {
            state.stateValue = payload
          }),
          setIsAuthorizing: payload => set(state =>
          {
            state.isAuthorizing = payload
          }),
          setIsAwaitingAuthResponse: payload => set(state =>
          {
            state.isAwaitingAuthResponse = payload
          }),
          clear: () => set(state =>
          {
            state.tokens = {}
            state.pkce = {}
            state.stateValue = ''
            state.isAuthorizing = false
            state.isAwaitingAuthResponse = false
          })
        }
      )),
      {
        name: 'udocs-support-data--auth2'
      }
    )
  )
)

export default useAuthStore
